import { useState } from "react";
import { useAuth } from "../../../scripts/App/auth";
import { Checkbox, FormControlLabel } from "@mui/material";
import toast from "react-hot-toast";
import { request } from "../../../plugins/axios";
import { endpoints } from "../../../../utils/networks";
import schema from "../conmponents/schema";
/**
 * (Custom Hook)
 * this custom hook handles handles all operations of the Admin User Modal {EDIT || ADD}
 * param {{data, setData, updateUser, addNewUser, props}}
 * returns {{      roles_permissions,
    errors,
    error,
    INPUT_FIELDS_LABLE,
    loading,
    allPermissions,
    ALL_PERMISSIONS_OF_ROLES,
    submitHandler,
    submitEditAdmin,}}
 * author Khaled Mofeed
 */
const ERROR_INPUTS = {
  first_name: false,
  last_name: false,
  email: false,
  mobile: false,
  roles: false,
  permissions: false,
};
export const useAdminModal = (
  data,
  setData,
  updateUser,
  handleClose,
  onHide
) => {
  /**
   * this data is returned from context to use it by functions
   * author Khaled Mofeed
   */
  const { permissions, roles_permissions = [] } = useAuth();
  const [errors, setErrors] = useState(ERROR_INPUTS);
  /**
   * this constant is return All Permissions of roles
   * author Khaled Mofeed
   */
  const allPermissions = permissions.reduce((acc, curr) => {
    acc = [...acc, ...curr.permissions];
    return acc;
  }, []);
  /**
   * this constant is return All Permissions of roles by checkbox element to view for admins to make changes to it
   * author Khaled Mofeed
   */
  const ALL_PERMISSIONS_OF_ROLES = permissions.map((role, index) => {
    if (role.name === "administrator") return <></>;

    let checkIfSomePermisioIsFromRole = false;
    if (
      role.permissions
        .map((element) => {
          return (
            data.data.permissions.filter(
              (permission) => permission.name === element.name
            )[0] || {}
          );
        })
        .some((element) => "is_custom" in element && !element.is_custom)
    ) {
      checkIfSomePermisioIsFromRole = true;
    } else {
      checkIfSomePermisioIsFromRole = false;
    }

    return (
      <div className="col-lg-4 col-md-4 mt-4" key={role.name + " " + index}>
        <div className="select-choose-box">
          <FormControlLabel
            className="choose-box perParent"
            label={role.name}
            id="ID_FormControlLabel2"
            control={
              <Checkbox
                id="ID_checkbox"
                type="checkbox"
                className="checkbox"
                checked={role.permissions.every((element) => {
                  return data.data.permissions
                    .map((permission) => permission.name)
                    .includes(element.name);
                })}
                disabled={checkIfSomePermisioIsFromRole}
                indeterminate={
                  role.permissions.some((r) =>
                    data.data.permissions
                      .map((permission) => permission.name)
                      .includes(r.name)
                  ) &&
                  !role.permissions.every((element) => {
                    return data.data.permissions
                      .map((permission) => permission.name)
                      .includes(element.name);
                  })
                }
                onChange={(e) => {
                  const newPermissions =
                    e.target.checked === true
                      ? [
                          ...data.data.permissions,
                          ...(role.permissions.map((r) => r) || []),
                        ]
                      : data.data.permissions.filter(
                          (permission) =>
                            !role.permissions
                              .map((r) => r.name)
                              .includes(permission.name) ||
                            permission.name === role.name
                        );

                  setData({
                    permissions: newPermissions,
                  });
                }}
              />
            }
          />
          <div className="perChild" id="ID_perChild">
            {role.permissions.map((child, index) => {
              const currentPermission =
                data.data.permissions.find(
                  (permission) => permission.name === child.name
                ) || {};
              const myPermissionsName = data.data.permissions.map(
                (permission) => permission.name
              );
              let selectedPermissionsArray = [];
              role.permissions.forEach((per) => {
                myPermissionsName.includes(per.name) &&
                  selectedPermissionsArray.push(per);
              });
              const length =
                role.permissions.filter((rr) => rr.name.includes("view"))
                  .length > 1
                  ? 2
                  : 1;
              const checkChildStartWithView =
                role.permissions.find((r) => {
                  return myPermissionsName.includes(r.name);
                }) &&
                child.name.includes("view") &&
                selectedPermissionsArray.length > length;
              const disabledCheckbox =
                ("is_custom" in currentPermission &&
                  !currentPermission.is_custom) ||
                checkChildStartWithView;

              return (
                <FormControlLabel
                  className="choose-box"
                  key={child.name + " " + index}
                  label={child.name}
                  sx={{
                    width: "fit-content",
                  }}
                  control={
                    <Checkbox
                      type="checkbox"
                      className="checkbox"
                      checked={
                        data.data.permissions
                          .map((permission) => permission.name)
                          .includes(child.name) ||
                        (!data.data.permissions.find(
                          (permission) => permission.name === child.name
                        )?.is_custom &&
                          data.data.permissions
                            .map((permission) => permission.name)
                            .includes(child.name))
                      }
                      disabled={disabledCheckbox}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setData({
                            permissions: [
                              ...data.data.permissions,
                              ...(data.data.permissions
                                .map((permission) => permission.name)
                                .includes(
                                  role.permissions.find((rr) =>
                                    rr.name.includes("view")
                                  )
                                )
                                ? []
                                : [
                                    ...role.permissions.filter((rr) =>
                                      rr.name.includes("view")
                                    ),
                                  ]),
                              child,
                            ],
                          });
                        } else {
                          setData({
                            permissions: data.data.permissions.filter(
                              (permission) =>
                                permission.name !== child.name &&
                                permission.name !== role.name
                            ),
                          });
                        }
                      }}
                    />
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  });
  /**
   * this state used for handel the loading when run function
   * author Khaled Mofeed
   */
  const [loading, setLoading] = useState(false);
  /**
   * this object input fields
   * author Khaled Mofeed
   */
  const INPUT_FIELDS = [
    { key: "first_name", label: "First Name", type: "text", disabled: false },
    { key: "last_name", label: "Last Name", type: "text", disabled: false },
    { key: "email", label: "Email", type: "email", disabled: data.edit },
    {
      key: "mobile",
      label: "Mobile",
      type: "tel",
      disabled: false,
      pattern: "[0-9]{3}-[0-9]{2}-[0-9]{3}",
    },
  ];

  const [error, setError] = useState(false);
  /**
   * this initial data
   * author Khaled Mofeed
   */
  const initialFormState = {
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    roles: [],
    status: false,
    permissions: [],
  };
  /**
   * this function is used to handle Change input form
   * for all form's elements
   * param attribute
   * param value
   * author Khaled Mofeed
   */
  const handleChange = (event) => {
    setData({
      [event.target.name]: event.target.value,
    });
    setError({
      ...error,
      [event.target.name]: false,
    });
  };
  /**
   * this function is used to handle submit form
   * add new admin
   * author Khaled Mofeed
   */
  const submitHandler = (event) => {
    event.preventDefault();
    setErrors(ERROR_INPUTS);

    schema
      .validate(data.data, { abortEarly: false })

      .then(() => {
        setLoading(true);
        request(
          endpoints.AdminUsers.CreateNewAdmin.method,
          endpoints.AdminUsers.CreateNewAdmin.url,
          {
            ...data.data,
            permissions: data?.data?.permissions.map(
              (permission) => permission?.name
            ),
          }
        )
          .then((response) => {
            toast.success(`${response?.data?.message}`);
            setData(initialFormState);
            setLoading(false);
            // handleClose?.();
            onHide?.();
          })
          .catch((erorr) => {
            setError(erorr.response.data.message);
            Object.values(erorr.response.data.errors).forEach((msg) => {
              toast.error(`${msg}`);
            });
            setLoading(false);
          });
      })
      .catch((error) => {
        setError(
          error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {})
        );
      });
  };
  /**
   * this function is used to handle submit form
   * Edit admin
   * author Khaled Mofeed
   */
  const submitEditAdmin = async () => {
    setErrors(ERROR_INPUTS);

    try {
      setLoading(true);

      const { data: responseUpdateData } = await request(
        endpoints.AdminUsers.UpdateAdmin.method,
        endpoints.AdminUsers.UpdateAdmin.url,
        {
          user_id: data.data.id,
          first_name: data.data.first_name,
          last_name: data.data.last_name,
          mobile: data.data.mobile,
          status:
            typeof data.data.status === "string"
              ? data.data.status.toLowerCase() === "active"
                ? 1
                : 0
              : data.data.status
              ? 1
              : 0,
          roles: data.data.roles.map((role) =>
            typeof role === "string" ? role : role.name
          ),
          permissions: data.data.permissions.map((permission) =>
            typeof permission === "string" ? permission : permission.name
          ),
        }
      );
      toast.success(responseUpdateData.message);
      updateUser(data.data);
      setData(initialFormState);
      setLoading(false);
      // handleClose?.();
      onHide?.();
    } catch (error) {
      const { errors: responseErrors } = error.response.data;
      if (responseErrors) {
        setErrors(responseErrors);
        if (responseErrors.mobile[0]) {
          toast.error(responseErrors.mobile[0]);
        }
      } else {
        toast.error(
          "Something went wrong. Please try again or contact support."
        );
      }

      console.log(error.response.data);
    }

    setLoading(false);
  };
  /**
   * this function is used to handle submit form
   * add new admin
   * author Khaled Mofeed
   */
  const INPUT_FIELDS_LABLE = INPUT_FIELDS.map((input, index) => (
    <div className="col col-md-3 col-lg-4 mb-2" key={index}>
      <div className="input-icon">
        <input
          disabled={input.disabled}
          type={input.type}
          placeholder={input.label}
          id={`filled-${input.key}`}
          pattern={input.pattern}
          name={input.key}
          onChange={handleChange}
          value={data?.data[input.key]}
          className={`form-control   ${error[input.key]?.[0] && "is-invalid"}`}
        />
        {error[input.key]?.[0] && (
          <div className="invalid-feedback">{error[input.key]}</div>
        )}
      </div>
    </div>
  ));
  return {
    roles_permissions,
    errors,
    error,
    INPUT_FIELDS_LABLE,
    loading,
    allPermissions,
    ALL_PERMISSIONS_OF_ROLES,
    submitHandler,
    submitEditAdmin,
  };
};
