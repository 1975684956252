import { useState } from "react";
import { useAuth } from "../../../scripts/App/auth";
import { Checkbox, FormControlLabel } from "@mui/material";
import toast from "react-hot-toast";
import { request } from "../../../plugins/axios";
import { endpoints } from "../../../../utils/networks";
/**
 * (Custom Hook)
 * this custom hook handles handles all operations of the Role Modal {EDIT || ADD}
 * param {{data, setData, getRoles, handleClose}}
 * returns {{isLoading,
    ALL_PERMISSIONS,
    ALL_PERMISSIONS_OF_ROLES,
    addRole,
    editRole,}}
 * author Khaled Mofeed
 */
export const useRoleModal = (data, setData, getRoles, handleClose) => {
  /**
   * this data is returned from context to use it by functions
   * author Khaled Mofeed
   */
  const { user, roles_permissions, permissions, setState } = useAuth();
  /**
   * this state used for handel the loading when run function
   * author Khaled Mofeed
   */
  const [isLoading, setIsLoading] = useState(false);
  /**
   * this constant is return All Permissions of roles
   * author Khaled Mofeed
   */
  const ALL_PERMISSIONS = permissions.reduce((acc, curr) => {
    acc = [...acc, ...curr.permissions];
    return acc;
  }, []);
  /**
   * this constant is return All Permissions of roles by checkbox element to view for admins to make changes to it
   * author Khaled Mofeed
   */
  const ALL_PERMISSIONS_OF_ROLES = permissions.map((role, index) => {
    if (role.name === "administrator") return <></>;

    let checkIfSomePermisioIsFromRole = false;
    if (
      role.permissions
        .map((element) => {
          return (
            data.data.permissions.filter(
              (permission) => permission.name === element.name
            )[0] || {}
          );
        })
        .some((element) => "is_custom" in element && !element.is_custom)
    ) {
      checkIfSomePermisioIsFromRole = true;
    } else {
      checkIfSomePermisioIsFromRole = false;
    }

    return (
      <div className="col-lg-4 col-md-4 mt-4" key={role.name + " " + index}>
        <div className="select-choose-box">
          <FormControlLabel
            className="choose-box perParent"
            label={role.name}
            control={
              <Checkbox
                type="checkbox"
                className="checkbox"
                id="ID_checkbox"
                checked={role.permissions.every((element) => {
                  return data.data.permissions
                    .map((permission) => permission.name)
                    .includes(element.name);
                })}
                disabled={checkIfSomePermisioIsFromRole}
                indeterminate={
                  role.permissions.some((r) =>
                    data.data.permissions
                      .map((permission) => permission.name)
                      .includes(r.name)
                  ) &&
                  !role.permissions.every((element) => {
                    return data.data.permissions
                      .map((permission) => permission.name)
                      .includes(element.name);
                  })
                }
                onChange={(e) => {
                  const newPermissions =
                    e.target.checked === true
                      ? [
                          ...data.data.permissions,
                          ...(role.permissions.map((r) => r) || []),
                        ]
                      : data.data.permissions.filter(
                          (permission) =>
                            !role.permissions
                              .map((r) => r.name)
                              .includes(permission.name) ||
                            permission.name === role.name
                        );

                  setData({
                    permissions: newPermissions,
                  });
                }}
              />
            }
          />
          <div className="perChild">
            {role.permissions.map((child, index) => {
              const currentPermission =
                data.data.permissions.find(
                  (permission) => permission.name === child.name
                ) || {};
              const myPermissionsName = data.data.permissions.map(
                (permission) => permission.name
              );
              let selectedPermissionsArray = [];
              role.permissions.forEach((per) => {
                myPermissionsName.includes(per.name) &&
                  selectedPermissionsArray.push(per);
              });
              const length =
                role.permissions.filter((rr) => rr.name.includes("view"))
                  .length > 1
                  ? 2
                  : 1;
              const checkChildStartWithView =
                role.permissions.find((r) => {
                  return myPermissionsName.includes(r.name);
                }) &&
                child.name.includes("view") &&
                selectedPermissionsArray.length > length;
              const disabledCheckbox =
                ("is_custom" in currentPermission &&
                  !currentPermission.is_custom) ||
                checkChildStartWithView;

              return (
                <FormControlLabel
                  className="choose-box"
                  key={child.name + " " + index}
                  label={child.name}
                  sx={{
                    width: "fit-content",
                  }}
                  control={
                    <Checkbox
                      type="checkbox"
                      id="ID_checkbox1"
                      className="checkbox"
                      checked={
                        data.data.permissions
                          .map((permission) => permission.name)
                          .includes(child.name) ||
                        (!data.data.permissions.find(
                          (permission) => permission.name === child.name
                        )?.is_custom &&
                          data.data.permissions
                            .map((permission) => permission.name)
                            .includes(child.name))
                      }
                      disabled={disabledCheckbox}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setData({
                            permissions: [
                              ...data.data.permissions,
                              ...(data.data.permissions
                                .map((permission) => permission.name)
                                .includes(
                                  role.permissions.find((rr) =>
                                    rr.name.includes("view")
                                  )
                                )
                                ? []
                                : [
                                    ...role.permissions.filter((rr) =>
                                      rr.name.includes("view")
                                    ),
                                  ]),
                              child,
                            ],
                          });
                        } else {
                          setData({
                            permissions: data.data.permissions.filter(
                              (permission) =>
                                permission.name !== child.name &&
                                permission.name !== role.name
                            ),
                          });
                        }
                      }}
                    />
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  });
  /**
   * this function is used to handle the Added new role
   * author Khaled Mofeed
   */
  const addRole = async () => {
    if (!user.permissions.includes("add_system_role"))
      return toast.error("You dont have permissions to do this action");

    if (!data.data.name.trim()) return toast.error("Name is required");
    if (!data.data.permissions.length)
      return toast.error("Permissions is required");

    setIsLoading(true);
    try {
      const response = await request(
        endpoints.Roles.CreateNewRole.method,
        endpoints.Roles.CreateNewRole.url,
        {
          name: data.data.name,
          status: data.data.status ? 1 : 0,
          permissions: data.data.permissions.map((permission) =>
            typeof permission === "string" ? permission : permission.name
          ),
        }
      );

      setState({
        roles_permissions: [response.data.data, ...roles_permissions],
      });

      toast.success(response.data.message);
      getRoles();
      handleClose();
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };
  /**
   * this function is used to handle the Edit role
   * author Khaled Mofeed
   */
  const editRole = async () => {
    if (!user.permissions.includes("edit_system_role"))
      return toast.error("You dont have permissions to do this action");

    const editRoleData = {
      role_id: data.data.id,
      name: data.data.name,
      status: data.data.status === "active" ? 1 : 0,
      permissions: data.data.permissions.map((permission) =>
        typeof permission === "string" ? permission : permission.name
      ),
    };
    try {
      const { data: editRoleResponse } = await request(
        endpoints.Roles.UpdateRole.method,
        endpoints.Roles.UpdateRole.url,
        editRoleData
      );

      toast.success(editRoleResponse.message);
      getRoles();
      handleClose();
    } catch (error) {
      console.log(error, "error");
    }
  };

  return {
    isLoading,
    ALL_PERMISSIONS,
    ALL_PERMISSIONS_OF_ROLES,
    addRole,
    editRole,
  };
};
