import { Link } from "react-router-dom";
/**
 * (Header)
 * this Header of Role Page, have title and action for (add new role)
 * author Khaled Mofeed
 */
function Header(props) {
  return (
    <div className="card-header border-0">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label text-dark" id="ID_Roles1">
          Roles Management
        </span>
        <span className="text-muted mt-1 font-size-sm" id="ID_ManageRoles">
          Manage your roles
        </span>
      </h3>
      <div className="card-toolbar">
        <Link
          className="d-none d-sm-inline-block btn btn-md btn-primary px-4 shadow-sm"
          onClick={props.action}
          id="ID_Add"
        >
          <i className="fas fa-user-plus fa-sm text-white-50 mr-2"></i>
          Add new Role
        </Link>
      </div>
    </div>
  );
}

export default Header;
