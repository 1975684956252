import React, { useState } from "react";
import "./style.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { socialMediaConfig } from "../../../configs/configFooter";

/*  This Component for displaying Footer 
*  Defining the data of social media from the config social media  file
 | khaled Mofeed | 29/10/2022 */

const socialMedia = socialMediaConfig;
function Footer() {
  const [readMore, setReadMore] = useState(false);

  return (
    <Row className="justify-content-center align-items-center  footer">
      <Col md={8} xl={6} className="text-center content-footer">
        <div className="title-div">
          <img src="img/logo.svg" alt="" id="id_logoFooter" />
        </div>
        <p className="text-footer" id="id_spanFooter">
          MetaVerse users often experience a euphoric state of happiness, where
          they can live out their wildest dreams in a virtual world. The ability
          to create and customize their own avatars, interact with other users,
          and explore new virtual{!readMore && "... "}
          {!readMore ? (
            <span onClick={() => setReadMore(true)}>Read More</span>
          ) : (
            `landscapes can bring immense
          joy and satisfaction to MetaVerse users. The feeling of belonging to a community of
          like-minded individuals also contributes to their positive mental state. Overall,
          MetaVerse provides a safe and fun space for users to escape from the stresses of reality
          and embrace their happy selves.`
          )}
        </p>

        <Row className="justify-content-center align-items-center">
          <Col lg={7} xl={7} className="text-center">
            <ul className="social-ul-footer">
              {socialMedia.map((socialMedia) => (
                <li key={socialMedia.id}>
                  <a
                    href={socialMedia.link}
                    rel="noreferrer"
                    target="_blank"
                    className="linkedin-team"
                    id={socialMedia.id}
                  >
                    <i className={socialMedia.iconClass}></i>
                  </a>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Col>
      <Col md={10} xl={10} className="footer-information">
        <Row className="justify-content-center align-items-center">
          <Col md={3} xl={3} className="text-center d-none d-lg-block">
            <div className="card-info-footer">
              <img src="/img/Button.svg" alt="Button" id="id_imgCallFooter" />
              <div className="text-info">
                <p className="title-info" id="id_subTitleFooter">
                  Have a question?
                </p>
                <p className="feedback-info" id="id_numberFooter">
                  310-437-2766
                </p>
              </div>
            </div>
          </Col>
          <Col md={3} xl={3} className="text-center d-none d-lg-block">
            <div className="card-info-footer">
              <img src="/img/Button2.svg" alt="Button" id="id_emailFooter" />
              <div className="text-info">
                <p className="title-info" id="id_subTitleFooter2">
                  Contact us at
                </p>
                <p className="feedback-info" id="id_numberFooter2">
                  unreal@outlook.com
                </p>
              </div>
            </div>
          </Col>
          <Col lg={6} xl={6} className="rights">
            <p id="id_RightsFooter">© 2023 TeleVerse. All Rights Reserved.</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Footer;
