// import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useAuth } from "../../scripts/App/auth";
import "./style.css";
/**
 * Sidebar Component
 * author Khaled Mofeed
 */
function Sidebar(props) {
  const { user } = useAuth();

  return (
    <>
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        <Link
          to="/"
          className="sidebar-brand d-flex align-items-center justify-content-center"
        >
          <img
            src="/img/logo.svg"
            alt="logo"
            className="logo d-none d-lg-block"
            id="ID_logo_mobile"
          />
          <img
            src="/img/logo-mobile.svg"
            alt="logo"
            className="logo-mobile d-block d-lg-none"
            id="ID_logo_mobile2"
          />
        </Link>

        <hr className="sidebar-divider "></hr>

        <div className="sidebar-heading py-3" id="ID_Management">
          System Management
        </div>
        {user?.permissions?.includes("view_admin_user") && (
          <li className="nav-item">
            <NavLink
              className="nav-link collapsed"
              to="/back-office/users-management"
              id="ID_Users"
            >
              <i className="fas fa-users fa-cog"></i>
              <span>Users</span>
            </NavLink>
          </li>
        )}
        {user?.permissions?.includes("view_system_role") && (
          <li className="nav-item">
            <NavLink
              className="nav-link collapsed"
              to="/back-office/RolesManagement"
              id="ID_Roles"
            >
              <i className="fas fa-users-cog"></i>
              <span>Roles</span>
            </NavLink>
          </li>
        )}

        {user?.permissions?.includes("view_system_role") && (
          <li className="nav-item">
            <NavLink
              className="nav-link collapsed"
              to="/back-office/revenue"
              id="ID_Revenue"
            >
              <i className="fas fa-users-cog"></i>
              <span>Revenue</span>
            </NavLink>
          </li>
        )}

        {user?.permissions?.includes("view_event") && (
          <li className="nav-item">
            <NavLink
              className="nav-link collapsed"
              to="/back-office/event-management"
              id="ID_Events"
            >
              <i className="fas fa-users-cog"></i>
              <span>Events</span>
            </NavLink>
          </li>
        )}

        <li className="nav-item">
          <NavLink
            className="nav-link collapsed"
            to="/back-office/proposal-management"
            id="ID_Events"
          >
            <i className="fas fa-users-cog"></i>
            <span>Proposals</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <Link className="nav-link collapsed" id="ID_Ticket">
            <i className="fas fa-ticket-alt"></i>
            <span>Ticket Categories</span>
          </Link>
        </li>
        <hr className="sidebar-divider"></hr>

        <div className="sidebar-heading" id="ID_Other">
          Other
        </div>

        <li className="nav-item">
          <Link className="nav-link collapsed" id="ID_Customers">
            <i className="fas fa-user-friends"></i>
            <span>Customers</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" id="ID_Customers">
            <i className="fas fa-money-check-alt"></i>
            <span>Claimer</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" id="ID_Sponsor">
            <i className="fas fa-star"></i>
            <span>Sponsor</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" id="ID_Smart">
            <i className="fas fa-share-alt"></i>
            <span>Smart Contract</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" id="ID_Vesting">
            <i className="fas fa-vector-square"></i>
            <span>Vesting</span>
          </Link>
        </li>
        <hr className="sidebar-divider d-none d-md-block" id="ID_divider"></hr>

        <div className="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
          ></button>
        </div>
      </ul>
    </>
  );
}

export default Sidebar;
