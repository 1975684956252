import { useUploadImage } from "../../Hooks/useUploadImage";
/**
 * Change Avatar Photo component in profile page
 * author Khaled Mofeed
 */
export default function ChangeAvatar() {
  /**
   * use the useUploadImage custom hook to handle the operations
   * author Khaled Mofeed
   */
  const { updateImage, user } = useUploadImage();
  return (
    <>
      <div className="row">
        <label className="col-xl-3"></label>
        <div className="col-lg-9 col-xl-6">
          <h6 className="font-weight-bold mb-6" id="id_custome-info-lable">
            Customer Info
          </h6>
        </div>
      </div>
      <div className="form-group row">
        <label
          className="col-xl-3 col-lg-3 col-form-label"
          id="id_avatar_lable"
        >
          Avatar
        </label>
        <div className="col-lg-9 col-xl-6">
          <div
            className="image-input image-input-outline"
            id="kt_profile_avatar"
          >
            <div
              className="image-input-wrapper"
              id="id_bg_avatar_image"
              style={{
                backgroundImage: `url(${user.avatar})`,
              }}
            ></div>
            <label
              className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
              data-action="change"
              data-toggle="tooltip"
              title=""
              data-original-title="Change avatar"
            >
              <i className="fa fa-pen icon-sm text-muted" id="id_icon_edit"></i>
              <input
                type="file"
                name="profile_avatar"
                accept=".png, .jpg, .jpeg"
                id="id_input_file_lable"
                onChange={updateImage}
              />
            </label>
          </div>
          <span className="form-text text-muted" id="id_allow_file_lable">
            Allowed file types: png, jpg, jpeg , size : less than 2 MB
          </span>
        </div>
      </div>
    </>
  );
}
