/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import MainHeader from "../Layout/Header";
import Footer from "../Layout/Footer";
import ComingSoon from "./ComingSoon";
import Live from "./Live";
import Closed from "./Closed";
import { AppContext } from "../../scripts/context";
import AppLoader from "../../components/AppLoader";

function NftPresale() {
  const {
    state: {
      contracts: {
        nft: {
          data: { time },
        },
      },
      user,
    } = {},
  } = useContext(AppContext) || {};

  // this state is used to determine which component to render
  const statusObj = {
    // default: <DisconnectedWallet />,
    before: <ComingSoon />,
    live: <Live />,
    after: <Closed />,
  };

  const [nftPresaleStatus, setNftPresaleStatus] = useState(statusObj[time]);

  useEffect(() => {
    setNftPresaleStatus(statusObj[time]);
  }, [time, user]);

  return (
    <Container fluid>
      <MainHeader />
      { nftPresaleStatus || <AppLoader />}
      {/* <DivLoadingSkeleton /> */}
      <Footer />
    </Container>
  );
}

export default NftPresale;
