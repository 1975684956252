import React, { useContext } from "react";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { AppContext } from "../../../../scripts/context";
import { RemoveLocalStorag } from "../../../../Helper/Helper";
import NftCard from "../../../marketPlace/components/Card";
// import { RemoveLocalStorag } from "../../../Helper/Helper";
function NftMarketPlace(props) {
  // this is the Container of MarketPlace Page components have:
  // SortMapLis, component for view the date by List or by Map
  // Card, Component for view the data of NFT card
  // Pagenation ,Component used for render the data from Api by pagination
  // | khaled Mofeed
  const { allNftFillter } = useContext(AppContext);
  const AllNftFillterData = allNftFillter.allNftFillter;
  const firstFourNftCards = AllNftFillterData?.slice(0, 4); // create a new array with the first 4 elements

  // use it for remove it from local storage to unSelected any nft in map || khaled Mofeed
  RemoveLocalStorag("selectedHexagonId");
  return (
    <Col md={12} xl={12} className="TrendingSection">
      <Row className="marketplace-list ProfileCard">
        {firstFourNftCards?.map((AllNftFillter) => (
          <NftCard imgSrc="/img/card1.svg" AllNftFillter={AllNftFillter} />
        ))}
      </Row>
    </Col>
  );
}

export default NftMarketPlace;
