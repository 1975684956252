import React, { useState } from "react";
import { readFilesFromEvent } from "../../../../../Helper/Helper";

function EditEventImage({ cover, setFileValue, errors }) {
  /**
   * EditEventImage Component to Add event image
   * author Khaled Mofeed
   */

  const [eventCover, setEventCover] = useState();
  /**
   * AddImage function to add new image and reader
   * author Khaled Mofeed
   */
  async function AddImage(event) {
    try {
      const eventCover1 = await readFilesFromEvent(event);
      setEventCover(eventCover1);
      setFileValue(event, cover);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <div className="image-input imageCardLeftProfile" id="kt_profile_avatar">
        <div
          className="image-input-wrapper"
          id="id_bg_avatar_image2"
          style={{
            backgroundImage: `url(${eventCover})`,
          }}
        ></div>
        <label
          className="btn btn-xs btn-icon btn-circle btn-black btn-hover-text-primary btn-shadow align-items-center"
          data-action="change"
          data-toggle="tooltip"
          title=""
          data-original-title="Event Cover"
          id="id_lable_edit1"
        >
          <div className="upload-file">
            <i className="fas fa-file-upload" id="id_icon_edit1"></i>

            <span>PNG, GIF, WEBP, SVG</span>
          </div>

          <input
            type="file"
            name={cover}
            accept=".png, .jpg, .jpeg, .svg"
            id="id_input_file_lable1"
            onChange={AddImage}
          />
        </label>
      </div>
      <div className={`${errors.cover && "is-invalid"}`}></div>
      {errors.cover && (
        <div className="invalid-feedback text-center" id="last_cover2">
          {errors.cover}
        </div>
      )}
    </>
  );
}

export default EditEventImage;
