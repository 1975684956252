import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import axiosInstance, { request } from "../../../admin/plugins/axios";
import { fillFormData } from "../../../Helper/Helper";
import { AppContext } from "../../../scripts/context";
import { endpoints } from "../../../utils/networks";
import schemaSubmitEvent from "../../events/components/SubmitEvent/components/schemaSubmitEvent";
import schemaUpdateProfile from "../../profile/components/EditProfile/components/schemaUpdateProfile";
import { useNavigate } from "react-router-dom";
/**
 * (Custom Hook)
 * this custom hook handles all operations of the form
 * param args
 * returns {{        form,
    handleForgetPasswordSubmit,
    handleLoginSubmit,
    handelLogin_2FASubmit,
    handleResetPasswordSubmit,
    onChangeRecaptcha,
    handleBackToLogin,
    handelUpdateInfoProfileSubmit,
    handelChangesPasswordSubmit,
    recaptchaRef,
    setForm,
    setValue,
    setLoading,
    loading,
    errors,
    setErrors,
    twoFA,
    setTwoFA,
    handelCancelLogin,}}
 * author Khaled Mofeed
 */
export const useForm = (args = {}) => {
  /**
   * initialize the form
   */
  const [form, setForm] = useState(args);
  /**
   * validator object which contains the error messages author Khaled Mofeed
   */
  const [errors, setErrors] = useState({});
  // const [loading, setLoading] = useState(false);
  const { userData, setUserData, loading, setLoading, setLoadingEdit } =
    useContext(AppContext);

  /**
   * this function is used to handle the setter operations
   * for all form's elements
   *
   * param attribute
   * param value
   * author Khaled Mofeed
   */
  const setValue = (event, field = null) => {
    const name = field ?? event?.target.name;
    const value = event?.target.value;
    try {
      // set form values by adding the existed values
      // in addition to adding the new one
      setForm({ ...form, [name]: value });
    } catch (e) {}
  };

  const setArrayValue = (name, value) => {
    try {
      // set form values by adding the existed values
      // in addition to adding the new one
      setForm({ ...form, [name]: value });
    } catch (e) {}
  };

  const setFileValue = (event, field = null) => {
    const name = field ?? event?.target.name;
    const value = event.target.files[0];
    try {
      // set form values by adding the existed values
      // in addition to adding the new one
      setForm({ ...form, [name]: value });
    } catch (e) {}
  };

  /**
   * submit the Update Info Profile form
   * param event
   * author Khaled Mofeed
   */
  const handelUpdateProfileSubmit = (event) => {
    const ERROR_INPUTS_UPDATE_INFO_PROFILE = {
      name: false,
      bio: false,
      custom_url: false,
      twitter: false,
      facebook: false,
      portfolio: false,
      linkedin: false,
      instagram: false,
    };
    event.preventDefault();
    setErrors(ERROR_INPUTS_UPDATE_INFO_PROFILE);

    schemaUpdateProfile
      .validate(form, { abortEarly: false })
      .then(() => {
        setLoading(true);
        setLoadingEdit(true);
        request(
          endpoints.ProfileData.UpdateProfile.method,
          endpoints.ProfileData.UpdateProfile.url,
          form
        )
          .then(({ data }) => {
            setLoading(false);
            setLoadingEdit(false);
            toast.success("Your information has been updated");

            setUserData({
              ...userData,
              name: form.name,
              bio: form.bio,
              custom_url: form.custom_url,
              twitter: form?.twitter,
              facebook: form?.facebook,
              portfolio: form?.portfolio,
              linkedin: form?.linkedin,
              instagram: form?.instagram,
            });
            setForm({
              ...form,
              name: form.name || "",
              bio: form.bio || "",
              custom_url: form.custom_url || "",
              twitter: form?.twitter || "",
              facebook: form?.facebook || "",
              portfolio: form?.portfolio || "",
              linkedin: form?.linkedin || "",
              instagram: form?.instagram || "",
            });
          })
          .catch(({ response }) => {
            setErrors(ERROR_INPUTS_UPDATE_INFO_PROFILE);
            setLoading(false);
            setLoadingEdit(false);
            if (response.data.errors) {
              setErrors({
                ...errors,
                ...Object.keys(response.data.errors).map((key) => ({
                  [key]: response.data.errors[key][0],
                }))[0],
              });
            }
          });
      })
      .catch((error) => {
        setErrors(ERROR_INPUTS_UPDATE_INFO_PROFILE);

        setErrors(
          error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {})
        );
      });
  };
  const ERROR_INPUTS_SUMIT_ERROR = {
    cover: false,
    name: false,
    email: false,
    description: false,
    categories: [],
    start_date: false,
    start_time: false,
    end_date: false,
    end_time: false,
    location: false,
    additional_info: false,
  };
  const initialSubmit = {
    cover: "",
    name: "",
    email: "",
    description: "",
    categories: [],
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    location: "",
    additional_info: "",
  };
  const navigate = useNavigate();
  const creatEventRequest = (form) => {
    request(
      endpoints.Event.CreateEvent.method,
      endpoints.Event.CreateEvent.url,
      form
    )
      .then(({ data }) => {
        setLoading(true);
        toast.success("Your Event Has Created Succesfuly");
        navigate("/events");
        setForm(initialSubmit);
      })
      .catch(({ response }) => {
        setErrors(ERROR_INPUTS_SUMIT_ERROR);
        setLoading(false);

        if (response.data.errors) {
          setErrors({
            ...errors,
            ...Object.keys(response.data.errors).map((key) => ({
              [key]: response.data.errors[key][0],
            }))[0],
          });
          console.log(response.data.errors);
          Object.keys(response.data.errors).forEach((_error) => {
            toast.error(response.data.errors[_error][0]);
          });
        }
      });
  };
  const handelSubmitEvent = (event) => {
    event.preventDefault();
    setErrors(ERROR_INPUTS_SUMIT_ERROR);
    schemaSubmitEvent
      .validate(form, { abortEarly: false })
      .then(() => {
        setLoading(true);
        fillFormData(form);
        console.log("form", form);
        axiosInstance.defaults.headers["Content-Type"] = "multipart/form-data";
        creatEventRequest(form);
      })
      .catch((error) => {
        setErrors(ERROR_INPUTS_SUMIT_ERROR);
        setErrors(
          error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {})
        );
      })
      .finally(() => {
        setLoading(false);
        axiosInstance.defaults.headers["Content-Type"] = "application/json";
      });
  };
  return {
    form,
    handelUpdateProfileSubmit,
    handelSubmitEvent,
    setForm,
    setValue,
    setLoading,
    loading,
    errors,
    userData,
    setErrors,
    setArrayValue,
    setFileValue,
  };
};
