import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import "./GoogleMFADialog/styles.css";
import DownloadAppStep from "./GoogleMFADialog/DownloadAppStep";
import ScanQRCodeStep from "./GoogleMFADialog/ScanQRCodeStep";
import BackupKeyStep from "./GoogleMFADialog/BackupKeyStep";
import ActivatedGoogleAuthenticatorStep from "./GoogleMFADialog/ActivatedGoogleAuthenticatorStep";
import DisableGoogleAuthenticatorStep from "./GoogleMFADialog/DisableGoogleAuthenticatorStep";
import { useModalTwofa } from "../Hooks/useModalTwofa";
/**
 * this 2FA Modal handles all operations of the Google Authentication
 * returns {{
 * Download App,
 * Scan QR Code
 * Backup Key
 * Activated Google authenticator
 * Inactivated Google authenticator
 * }}
 * author Khaled Mofeed
 */
function ModalTwofa(props) {
  /**
   * use the useModalTwofa custom hook to handle the operations
   * author Khaled Mofeed
   **/
  const {
    user,
    isStep1,
    setIsStep1,
    isStep2,
    setIsStep2,
    isStep3,
    setIsStep3,
    isStep4,
    setIsStep4,
    isStep5,
    setIsStep5,
    liStep2,
    liStep3,
    liStep4,
    liStep5,
    authenticationInfo,
    getData2fa,
  } = useModalTwofa();
  useEffect(() => {
    getData2fa();
  }, []);
  useEffect(() => {
    setIsStep1(true);
    setIsStep2(false);
    setIsStep3(false);
    setIsStep4(false);
    setIsStep5(false);
  }, [props.show]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Setup Google Authentication
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="wizard ">
        <div className="steps clearfix">
          <ul
            role="tablist"
            className={
              isStep1
                ? "step1doen"
                : isStep2
                ? "step2doen"
                : isStep3
                ? "step3doen"
                : isStep4
                ? "step4doen"
                : isStep5
                ? "step5doen"
                : "undone"
            }
          >
            <li
              role="tab"
              className={isStep1 ? "first current" : "first"}
              aria-disabled="false"
              aria-selected="true"
            >
              <a id="liStep1" href="#Download_App" aria-controls="2faSteps-p-0">
                <span className="number">1</span>
                <span className="title">Download App</span>
              </a>
            </li>

            {!user.two_fa ? (
              <>
                <li
                  role="tab"
                  className={isStep2 ? "current" : ""}
                  aria-disabled="true"
                >
                  <a
                    id="liStep2"
                    ref={liStep2}
                    href="#Scan_QR_Code"
                    aria-controls="2faSteps-p-1"
                    className="disabled"
                  >
                    <span className="number">2</span>
                    <span className="title">Scan QR Code</span>
                  </a>
                </li>
                <li
                  role="tab"
                  className={isStep3 ? "current" : ""}
                  aria-disabled="true"
                >
                  <a
                    id="liStep3"
                    ref={liStep3}
                    href="#Backup_Key"
                    aria-controls="2faSteps-p-2"
                    className="disabled"
                  >
                    <span className="number">3</span>
                    <span className="title">Backup Key</span>
                  </a>
                </li>
                <li
                  role="tab"
                  className={isStep4 ? "current" : " last"}
                  aria-disabled="true"
                >
                  <a
                    id="liStep4"
                    ref={liStep4}
                    href="#Activated_Google"
                    aria-controls="2faSteps-p-3"
                    className="disabled"
                  >
                    <span className="number">4</span>
                    <span className="title">
                      Activated Google authenticator
                    </span>
                  </a>
                </li>
              </>
            ) : (
              <>
                <li
                  role="tab"
                  className={isStep5 ? "current" : " last"}
                  aria-disabled="true"
                >
                  <a
                    id="liStep5"
                    ref={liStep5}
                    href="#2faSteps-h-3"
                    aria-controls="2faSteps-p-3"
                    className="disabled"
                  >
                    <span className="number">2</span>
                    <span className="title">Disable Google authenticator</span>
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
        {isStep1 && (
          <DownloadAppStep
            setIsStep1={setIsStep1}
            setIsStep2={setIsStep2}
            setIsStep5={setIsStep5}
            ifGoogle_2fa={user.two_fa}
          />
        )}

        {isStep2 && (
          <ScanQRCodeStep
            authenticationInfo={authenticationInfo}
            setIsStep1={setIsStep1}
            setIsStep2={setIsStep2}
            setIsStep3={setIsStep3}
            liStep2={liStep2}
          />
        )}
        {isStep3 && (
          <BackupKeyStep
            authenticationInfo={authenticationInfo}
            setIsStep2={setIsStep2}
            setIsStep3={setIsStep3}
            setIsStep4={setIsStep4}
            liStep3={liStep3}
          />
        )}
        {isStep4 && (
          <ActivatedGoogleAuthenticatorStep
            authenticationInfo={authenticationInfo}
            setIsStep1={setIsStep1}
            setIsStep3={setIsStep3}
            setIsStep4={setIsStep4}
            liStep4={liStep4}
            onHide={props.onHide}
          />
        )}

        {isStep5 && (
          <DisableGoogleAuthenticatorStep
            authenticationInfo={authenticationInfo}
            setIsStep1={setIsStep1}
            setIsStep5={setIsStep5}
            onHide={props.onHide}
            liStep5={liStep5}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ModalTwofa;
