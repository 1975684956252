import { useState } from "react";
import { compareAsc } from "date-fns";
import {
  HTTP_ACTIVE,
  HTTP_COMLEETE_RANGE,
  HTTP_EQUAL_RANGE,
  HTTP_IN_ACTIVE,
  HTTP_PERCENTAGE,
} from "../../../constants";
/**
 * (Custom Hook)
 * this custom hook handles all operations of the Proposal page
 * param args
 * returns {{      
    toggle,
    sortAsec,
    sortDesc,
    statusProposal,
    rangeLikeValue,
    rangeVotes,
    checked,
    setChecked,
    refresh,
    setRefresh,
    data,
    setData,
    sortAsc,}}
 * author Khaled Mofeed
 */

export const useProposal = () => {
  const [data, setData] = useState([]);
  const [checked, setChecked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [sortAsc, setSortAsc] = useState(false);
  /**
   * this function is used to toggle the value of check box
   * author Khaled Mofeed
   */
  function toggle(value) {
    return !value;
  }
  /**
   * this functions is used to sort data by Ascending and Descending
   * author Khaled Mofeed
   */
  function sortByDate(arg) {
    const items = data.transactions.sort((a, b) => {
      if (a.createdAt < b.createdAt) return arg ? 1 : -1;
      if (a.createdAt > b.createdAt) return arg ? -1 : 1;
      return 0;
    });
    data.transactions = items;
    setData(data);
    setRefresh(true);
    setSortAsc(!sortAsc);
  }
  /**
   * this functions is used to render the status of Proposal
   * author Khaled Mofeed
   */
  const statusProposal = (closeAt) => {
    const result = compareAsc(new Date(closeAt), new Date());
    if (result === -1) {
      return HTTP_IN_ACTIVE;
    } else {
      return HTTP_ACTIVE;
    }
  };
  /**
   * this functions is used to render the percent of like number
   * author Khaled Mofeed
   */
  const rangeLikeValue = (numberOfLike, numberOfDislike) => {
    const range =
      (numberOfLike / (numberOfDislike + numberOfLike)) * HTTP_PERCENTAGE;
    if (range === 0 || !range) {
      return HTTP_EQUAL_RANGE;
    } else {
      return range;
    }
  };
  /**
   * this functions is used to render the percent of vote number
   * author Khaled Mofeed
   */
  const rangeVotes = (numberOfLike, numberOfDislike, softCap) => {
    const totalVotes = numberOfDislike + numberOfLike;
    if (totalVotes >= softCap) {
      return HTTP_COMLEETE_RANGE;
    } else {
      return (totalVotes / softCap) * HTTP_PERCENTAGE;
    }
  };
  return {
    toggle,
    sortByDate,
    statusProposal,
    rangeLikeValue,
    rangeVotes,
    checked,
    setChecked,
    refresh,
    setRefresh,
    data,
    setData,
    sortAsc,
  };
};
