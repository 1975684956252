import React, { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { AppContext } from "../../../scripts/context";
import FollowModal from "./FollowModal";
function Head(props) {
  // the component for view
  // Fillter of nft cards ,
  // Followings Modal,
  // Followers Modal,
  // | khaled Mofeed
  const { userData } = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);
  const [following, setFollowing] = useState(false);
  const Following = async () => {
    setModalShow(true);
    setFollowing(true);
  };
  const Followers = async () => {
    setModalShow(true);
    setFollowing(false);
  };
  return (
    <>
      <Row className="pt-4 mt-1">
        <Col md={12} xl={12}>
          <h5 className="nickName">
            {userData?.name}
            <img src="/img/Vector.svg" className="icon-Vector" alt="Vector" />
          </h5>
        </Col>
      </Row>
      <Row className="pb-3 mt-3 mb-4 fillter-tap">
        <Col md={6} xl={6}>
          <Button variant="outline-secondary" className="btnCustome mr-3">
            All
          </Button>
          <Button variant="outline-secondary" className="btnCustome activeBtn">
            On Sale
          </Button>
        </Col>
        <Col md={6} xl={6} className="text-right">
          <Button
            variant="outline-secondary"
            className="btnCustome"
            onClick={Following}
          >
            {userData.following_count} Following
          </Button>
          <Button
            variant="outline-secondary"
            className="btnCustome ml-3"
            onClick={Followers}
          >
            {userData.followers_count} Followers
          </Button>
        </Col>
      </Row>
      <FollowModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        following={following}
      />
    </>
  );
}

export default Head;
