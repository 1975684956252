import { useContext } from "react";
import toast from "react-hot-toast";
import { request } from "../../../admin/plugins/axios";
import { AppContext } from "../../../scripts/context";
import { endpoints } from "../../../utils/networks";
/**
 * (useAttendance Custom Hook)
 * this custom hook handles the Attendance of events
 * param {id}
 * returns {{loading, Attend}}
 * author Khaled Mofeed
 */
export const useAttendance = (id) => {
  const { setLoading, loading } = useContext(AppContext);
  /**
   * Attend, function used for handle the Attendance of events
   * author Khaled Mofeed
   */
  const Attend = async (id) => {
    setLoading(true);
    try {
      const response = await request(
        endpoints.Event.Attend.method,
        endpoints.Event.Attend.url,
        {
          event_id: id,
        }
      );
      toast.success(response?.data.message);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return { loading, Attend };
};
