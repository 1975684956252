import { Link } from "react-router-dom";

function DownloadAppStep(props) {
  /**
   * this first Step in Google Authentication
   * Download and install the Google Authenticator app
   * author Khaled Mofeed
   */
  function handleNextStep() {
    props.setIsStep1(false);
    props.setIsStep2(true);
  }
  function handleNextStep5() {
    props.setIsStep1(false);
    props.setIsStep5(true);
  }

  return (
    <>
      <div className="content clearfix">
        <div>
          <h3 className="text-center title-section" id="id_download_app_lable">
            Download App
          </h3>
          <p className="mb-3" id="id_sublable">
            Download and install the Google Authenticator app
          </p>
        </div>
        <div className="row row-sm">
          <div className="css-dx6lmr">
            <a
              id="enableGA_a_appStore"
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              target="_blank"
              rel="noreferrer noopener"
              className="css-ovthrp"
            >
              <img
                id="id_enableGA_a_appStore_img"
                src="/img/download-apple.svg"
                className="css-1sysnl8"
                alt="download-apple"
              />
              <div
                className="css-1sgz1lk"
                id="id_enableGA_a_appStore_description"
              >
                <div className="css-vurnku">Download from</div>
                <div className="css-1gubtqr">App Store</div>
              </div>
            </a>
            <div className="css-104e7hr"></div>
            <a
              id="enableGA_a_googlePlay"
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              target="_blank"
              rel="noreferrer noopener"
              className="css-1i0rbqg"
            >
              <img
                id="id_enableGA_a_googlePlay_img"
                src="/img/download-google.svg"
                className="css-1sysnl8"
                alt="download-google"
              />
              <div
                className="css-1sgz1lk"
                id="id_enableGA_a_googlePlay_description"
              >
                <div className="css-vurnku">Download from</div>
                <div className="css-1gubtqr">Google Store</div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="actions clearfix">
        <ul role="menu" aria-label="Pagination">
          <li className="disabled">
            <Link id="id_Previous_step">Previous</Link>
          </li>
          <li>
            <Link
              id="id_Next_step"
              onClick={props.ifGoogle_2fa ? handleNextStep5 : handleNextStep}
            >
              Next
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default DownloadAppStep;
