import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../scripts/App/auth";
import "./style.css";
/**
 * Topbar Component
 * author Khaled Mofeed
 */
function Topbar(props) {
  const auth = useAuth();
  const [showDropdown, setShowDropdown] = useState(false);
  const ToggleShowDropdown = () => {
    // 👇️ passed function to setState
    setShowDropdown((showDropdown) => !showDropdown);
  };

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      <button
        id="sidebarToggleTop"
        className="btn btn-link d-md-none rounded-circle mr-3"
      >
        <i className="fa fa-bars"></i>
      </button>

      <ul className="navbar-nav ml-auto">
        <div className="topbar-divider d-none d-sm-block"></div>

        <li className="nav-item dropdown no-arrow">
          <Link
            className="nav-link dropdown-toggle"
            onClick={ToggleShowDropdown}
            id="userDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span
              className="mr-2 d-none d-lg-inline text-gray-600 small"
              id="ID_full_name"
            >
              {auth?.user?.full_name}
            </span>
            <img
              className="img-profile rounded-circle"
              src={auth?.user?.avatar}
              alt="profile img"
              id="ID_avatar"
            />
          </Link>
          <div
            className={`dropdown-menu dropdown-menu-right shadow dropdown-profile ${
              showDropdown && "show"
            }`}
            aria-labelledby="userDropdown"
            id="ID_userDropdown"
          >
            <Link
              className="dropdown-item"
              to="/back-office/profile"
              id="ID_Profile"
            >
              <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
              Profile
            </Link>
            <Link className="dropdown-item" href="#" id="ID_Help">
              <i className="far fa-question-circle fa-sm fa-fw mr-2 text-gray-400"></i>
              Help Center
            </Link>

            <div className="dropdown-divider"></div>
            <Link
              className="dropdown-item"
              onClick={auth.logout}
              id="ID_Logout"
            >
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Logout
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default Topbar;
