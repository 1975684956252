import React, { useEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import revenueXYConfig from '../../../../configs/revenueXY.config';
import { shortenDate } from '../../../../Helper/Helper';

function Chart({ label, data, isIncome }) {
  const chartRef = useRef();

  am4core.useTheme(am4themes_animated);

  const XYChart = am4charts.XYChart;

  useEffect(() => {
    if (!data) return;
    // create chart
    const chart = am4core.create(chartRef.current, XYChart);

    // add data
    chart.data = data[isIncome ? 'income' : 'claimed'].map((item) => ({
      date_at: shortenDate(item.date_at),
      total: item.total,
    }));

    // create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date_at';
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // create series
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = 'total';
    series.dataFields.categoryX = 'date_at';
    series.columns.template.tooltipText = '{valueY}';

    // disable labels
    categoryAxis.renderer.labels.template.disabled = true;

    series.columns.template.fill = revenueXYConfig(isIncome);

    // make the head rounded
    series.columns.template.strokeWidth = 0;
    series.columns.template.strokeOpacity = 0;
    series.columns.template.column.cornerRadiusTopLeft = 100;
    series.columns.template.column.cornerRadiusTopRight = 100;

    //change grid color to white
    valueAxis.renderer.grid.template.stroke = am4core.color('#343444');
    valueAxis.renderer.grid.template.strokeOpacity = 1;

    // adjust width of the column
    series.columns.template.width = am4core.percent(33.33);

    // change color of the font to white
    valueAxis.renderer.labels.template.fill = am4core.color('#777E90');

    // display labels in x axis
    categoryAxis.renderer.labels.template.disabled = false;
    categoryAxis.renderer.labels.template.fill = am4core.color('#777E90');
    categoryAxis.renderer.labels.template.fontSize = 10;

    //display all labels in x axis
    categoryAxis.renderer.minGridDistance = 1;

    //remove y axis lines
    categoryAxis.renderer.grid.template.disabled = true;

    //minimum value of y axis
    valueAxis.min = 0;

    // dispose chart when unmounting component
    return () => chart.dispose();
  }, [data]);

  return (
    <div className='chart-container'>
      <div className='d-flex'>
        <h1 style={{marginTop: '1rem'}}>{label}</h1>
      </div>
      <div className='d-flex justify-content-end'><p>{(data?.total ?? 0).toFixed(2)} TELV</p></div>
      <div ref={chartRef} style={{ width: '100%', height: '180px' }} className='revenue-chart' />
    </div>
  );
}

export default Chart;
