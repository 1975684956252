import React, { useState } from "react";
import schema from "./schema";
import toast from "react-hot-toast";

import "./style.css";
function Form(props) {
  /*  use the useState custom hook to define variables | khaled Mofeed | 29/10/2022 */
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    mobile: "",
    address: "",
    message: "",
  });
  const [error, setError] = useState(false);
  /* Define an empty variable to be used to dump the form after submitting | khaled Mofeed | 29/10/2022 */
  const initialData = {
    name: "",
    email: "",
    mobile: "",
    address: "",
    message: "",
  };
  /*  this function is used to submit Form | khaled Mofeed | 29/10/2022 */
  const submitHandler = (event) => {
    event.preventDefault();
    /*  this schema is used to validator fields which contains the error messages | khaled Mofeed | 29/10/2022  */
    schema
      .validate(formdata, { abortEarly: false })
      .then(() => {
        toast.success(`You message has been sent !`);
        setFormdata(initialData);
      })
      .catch((error) => {
        setError(
          error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {})
        );
      });
  };
  /*  this function used to handle value of input to set it to state | khaled Mofeed | 29/10/2022  */
  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
    setError({
      ...error,
      [event.currentTarget.name]: false,
    });
  };

  return (
    <>
      <form className="form row position-relative" onSubmit={submitHandler}>
        <div className="form-group col-12 col-md-6">
          <input
            type="text"
            className={`form-control form-control-solid border-0 bg-dark-2 ${
              error.name && "is-invalid"
            }`}
            name="name"
            placeholder="Your name"
            onChange={handleChange}
            value={formdata.name}
            id="id_nameGetTouch"
          />
          {error.name && (
            <div className="invalid-feedback" id="id_nameErrorGetTouch">
              {error.name}
            </div>
          )}
        </div>

        <div className="form-group col-12 col-md-6">
          <input
            type="email"
            className={`form-control form-control-solid border-0 bg-dark-2 ${
              error.email && "is-invalid"
            }`}
            name="email"
            placeholder="Email address"
            onChange={handleChange}
            value={formdata.email}
            id="id_emailGetTouch"
          />
          {error.email && (
            <div className="invalid-feedback" id="id_emailErrorGetTouch">
              {error.email}
            </div>
          )}
        </div>

        <div className="form-group col-12 col-md-6">
          <input
            type="text"
            className="form-control form-control-solid border-0 bg-dark-2"
            name="mobile"
            placeholder="mobile"
            onChange={handleChange}
            value={formdata.mobile}
            id="id_mobileGetTouch"
          />
        </div>
        <div className="form-group col-12 col-md-6">
          <input
            type="text"
            className="form-control form-control-solid border-0 bg-dark-2"
            name="address"
            placeholder="Enter Your Address"
            onChange={handleChange}
            value={formdata.address}
            id="id_addressGetTouch"
          />
        </div>

        <div className="form-group col-12">
          <textarea
            name="message"
            className={`form-control form-control-solid border-0 bg-dark-2 ${
              error.message && "is-invalid"
            }`}
            rows="4"
            placeholder="Message"
            onChange={handleChange}
            value={formdata.message}
            id="id_messageGetTouch"
          ></textarea>
          {error.message && (
            <div className="invalid-feedback" id="id_msgErrorGetTouch">
              {error.message}
            </div>
          )}
        </div>
        <div className="col-12 col-md-12 text-center">
          <button
            type="submit"
            name="submit"
            value="Submit"
            className="btn btn-lg btn-primary px-10"
            id="id_SubmitGetTouch"
          >
            Submit Now
          </button>
        </div>
      </form>
    </>
  );
}

export default Form;
