import React, { useState } from "react";
import { useNftPresale } from "../../../../scripts/nftPresale";
import CustomTable from "../../../../components/CustomTable";
import Pagination from "./Pagination";
import "./style.css";

function Purchased() {
  const limit = 3;
  const [tableTotalList, setTableTotalList] = useState(0);
  const { getUserPurchasedTransactionsList } = useNftPresale();

  const [currentPage, setCurrentPage] = useState(1);
  let pages = 0;

  const transactionList = async (limit) => {
    const { transactions, totalList } = await getUserPurchasedTransactionsList(
      currentPage - 1,
      limit
    );
    setTableTotalList(totalList);
    pages = Math.ceil(totalList / limit) || 1;
    return transactions;
  };

  return (
    <div className="shared-space">
      <h2 className="table-label">DS Purchased</h2>
      <CustomTable cb={transactionList} currentPage={currentPage} no={limit} />
      <Pagination
        totalList={tableTotalList}
        pages={pages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

export default Purchased;
