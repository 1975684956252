import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useUploadImage } from "../Hooks/useUploadImage";

function EditCoverProfile() {
  //  this Component have change cover and edit profile buttons | khaled Mofeed
  const { updateImage } = useUploadImage("cover");
  return (
    <Row className="pt-4 pb-4 mt-1 mb-4">
      <Col md={12} xl={12} className="text-right">
        <div
          className="image-input imageCardLeftProfile"
          id="kt_profile_avatar"
        >
          <label
            className="btnCustome mr-3 btn btn-outline-secondary"
            data-action="change"
            data-toggle="tooltip"
            title=""
            data-original-title="Change avatar"
          >
            Edit cover photo
            <img src="/img/Line.svg" className="icon-btn" alt="Line" />
            <input
              type="file"
              name="profile_cover"
              accept=".png, .jpg, .jpeg, .svg"
              id="id_input_file_lable2"
              onChange={updateImage}
            />
          </label>
        </div>
        <Link
          to="/edit-Profile"
          className="btnCustome btn btn-outline-secondary"
        >
          Edit profile
          <img src="/img/edit.svg" className="icon-btn" alt="edit" />
        </Link>
      </Col>
    </Row>
  );
}

export default EditCoverProfile;
