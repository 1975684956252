import React, { useContext, useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import LandLatestOffers from "./components/tables/LandLatestOffers";
import ImageUploader from "./components/ImageUploader";
import { useParams } from "react-router-dom";
import { pxToRem } from "./theme";
import { BsArrowLeft } from "react-icons/bs";
import { Button, Typography } from '@mui/material';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import { useLandData, useUpdateLandData } from '../../../hooks/useMarketplace';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import ResaleForm from './components/SaleOptionsForm';
import { editLandProfileSchema } from '../../../utils/validationSchemas';
import { AppContext } from '../../../scripts/context';

const EditLand = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, refetch, error } = useLandData(id);
  const landData = data?.data?.data[0] || {};
  const {
    state: {
      user: { address: account },
    },
  } = useContext(AppContext);

  useEffect(() => {
    if (error) {
      navigate('/marketplace');
    }
  }, [error, navigate]);

  const isOwner = useMemo(() => {
    if (!account || !landData?.seller?.address) return true;
    return account === landData?.seller?.address;
  }, [account, landData?.seller?.address]);

  useEffect(() => {
    if (isLoading ) return;
    if (!isOwner) {
      toast.error('You are not the owner of this land');
      navigate(`/land/${id}`);
    }
  }, [id, isLoading, isOwner, navigate]);

  const { mutate: updateLandData, isSuccess: profileDidUpdate } = useUpdateLandData(id);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSubmit = (values) => {
    setIsSubmitting(true);
    updateLandData(values);
  };

  useEffect(() => {
    if (profileDidUpdate) {
      toast.success('Profile updated successfully');
      setIsSubmitting(false);
    }
  }, [profileDidUpdate]);


  const handleNavigateBack = () => {
    navigate(-1); // Go back one page
  };

  const initialValues = useMemo(
    () => ({
      name: landData?.name || '',
      email: landData?.email || '',
      url: landData?.url || '',
      bio: landData?.bio || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  return (
    <Container fluid>
      <Header />
      <Row className='justify-content-center DistributionVesting mt-3'>
        <Col md={10} xl={10} className='mb-0'>
        <Typography component="h5" variant="h5" className="editProfileIcon">
            <BsArrowLeft onClick={handleNavigateBack} className="pointer" />
            Edit Land Profile
          </Typography>
          <hr className='mb-5' />
          <Row>
            <Col md={4} className='d-md-block d-flex mb-4 '>
              <ImageUploader src={landData.image} id={id} />
            </Col>
            <Col md={8} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={editLandProfileSchema}>
                {({ values, handleChange, handleBlur, errors, touched }) => (
                  <Form className='form row position-relative bg-border-gradiant contact-form-bg'>
                    <div className='form-group col-12 col-md-12 '>
                      <label htmlFor='basic-url'>Display name</label>
                      <input
                        type='text'
                        className={`form-control form-control-solid border-0 bg-dark-2 ${
                          errors.name && touched.name && 'is-invalid'
                        }`}
                        name='name'
                        placeholder='Enter your display name'
                        id='id_name'
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.name && touched.name && (
                        <div className='invalid-feedback'>{errors.name}</div>
                      )}
                    </div>

                    <div className='form-group  col-sm-6'>
                      <label className='basic-url'>EMAIL</label>
                      <input
                        type='text'
                        className={`form-control form-control-solid border-0 bg-dark-2 ${
                          errors.email && touched.email && 'is-invalid'
                        }`}
                        name='email'
                        id='id_nameSocial'
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {errors.email && touched.email && (
                        <div className='invalid-feedback'>{errors.email}</div>
                      )}
                    </div>
                    <div className='form-group col-sm-6'>
                      <label className='basic-url'>URL</label>
                      <input
                        type='text'
                        className={`form-control form-control-solid border-0 bg-dark-2 ${
                          errors.url && touched.url && 'is-invalid'
                        }`}
                        name='url'
                        id='id_nameSocial'
                        value={values.url}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.url && touched.url && (
                        <div className='invalid-feedback' id='last_name'>
                          {errors.url}
                        </div>
                      )}
                    </div>
                    <div className='form-group col-12 col-md-12'>
                      <label for='basic-url'>Bio</label>
                      <textarea
                        name='bio'
                        className={`form-control form-control-solid border-0 bg-dark-2 ${
                          errors.bio && touched.bio && 'is-invalid'
                        }`}
                        rows='3'
                        placeholder='About yourselt in a few words'
                        id='id_bio'
                        value={values.bio}
                        onChange={handleChange}></textarea>
                      {errors.bio && touched.bio && (
                        <div className='invalid-feedback'>{errors.bio}</div>
                      )}
                    </div>
                    <div className='form-group col-12 col-md-12 mt-2 '>
                      <Button
                        type='submit'
                        size='large'
                        sx={{
                          borderRadius: pxToRem(85),
                          marginBottom: pxToRem(12),
                          fontWeight: '600',
                        }}
                        fullWidth
                        variant='contained'
                        color='primary'>
                        Update Profile
                        {isSubmitting && (
                          <Spinner
                            style={{ marginLeft: '.5rem' }}
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        )}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
              <hr />
              <ResaleForm landData={landData} refetch={refetch} />
              <hr />
              {landData?.token_id && <LandLatestOffers owner tokenId={landData.token_id} />}
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default EditLand;
