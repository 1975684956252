import React from 'react';
import './style.css';
import NFT from '../../../../assets/home/NFT.png';
import { Image, Col, Row } from 'react-bootstrap';

function Hero() {
  return (
      <Row className="what-televerse justify-content-center align-items-center">
        <Col md={10} >
      <Row  className='align-items-center'>
        <Col className='what-is-televerse-img ' lg={5}>
          <Image className='nft-img' src={NFT} alt='NFT Image' fluid/>
        </Col>
        <Col className='what-is-televerse-text' lg={7}>
          <span className='what-is-televerse-head'>What is {Screen.width <992 && <br/>}Televerse?</span>
          <p className='what-is-televerse-description'>
            Televerse is a point of intersection (Central Hub) among other metaverses, where it is
            the first blockchain-powered portal to all metaverses, NFT projects, and other
            metaverses marketplaces. <br/><br/>Where many Metaverses have come to light, and each one of them
            has its own isolated world, it becomes hard for VR users to discover those worlds, know
            about services and even trust them, especially when it comes to purchasing services and
            NFTs.
          </p>
        </Col>
      </Row>
      </Col>
      </Row>
);
}

export default Hero;
