import React from 'react';
import './style.css';
import Currencies from '../../../../assets/home/currencies.png';
import { Col, Container, Row, Image } from 'react-bootstrap';

function index() {
  return (
    <>
      <Container fluid>
        <Row className='justify-content-center'>
          <Col md={10}>
            <Row className='align-items-center min-height-100'>
              <Col className='televerse-world-img' lg={6}>
                <Image className='mt-10p' src={Currencies} alt='currencies' fluid />
              </Col>
              <Col className='token-text' lg={6}>
                <h1 className='televerse-world-head'>Token</h1>
                <p className='token-description'>
                  be the only payment currency for paid services within the Televers world, and it
                  is the only currency used for purchases and trading within Televers Marketplace.
                  <br />
                  <br />
                  TELV was created on the binance smart chain, which gave it many advantages, and the
                  foundations of its distribution were laid, and its eco-system was built to ensure
                  that it provides it with a sense of strength and durability.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default index;
