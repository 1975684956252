import { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import Sidebar from "../../conmponents/SideBar";
import Topbar from "../../conmponents/Topbar";
import EnhancedTable from "../../layouts/table";
import {
  FormControlLabel,
  FormGroup,
  Switch,
  TableRow,
  Typography,
} from "@mui/material";
import Header from "./conmponents/header";
import LoadingTableRows from "../../conmponents/LoadingTableRows";
import FilterForm from "./conmponents/FilterForm";
import ConfirmDeleteModal from "./conmponents/ConfirmDeleteModal";
import RoleModal from "./conmponents/RoleModal";
import { useRole } from "./Hooks/useRole";

function RolesManagement(props) {
  /**
   * use the useRole custom hook to handle the operations
   * author Khaled Mofeed
   */
  const {
    initRoleState,
    user,
    loading,
    deleteLoading,
    setDeleteLoading,
    tableData,
    setTableData,
    filter,
    setFilter,
    role,
    setRole,
    deleteItem,
    setDeleteItem,
    order,
    setOrder,
    handleChangeRowsPerPage,
    handleChangePage,
    getRoles,
    changeStatus,
    deleteRow,
  } = useRole();
  /**
   * use the useRef to return body
   * author Khaled Mofeed
   */
  const body = useRef(document.body);

  useEffect(() => {
    body.current.classList.add("admin");
    if (!user.permissions.includes("view_system_role")) return;
    getRoles();
  }, []);

  return (
    <div id="wrapper" className="admin">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar />

          <div className="container-fluid">
            <div className="card card-custom gutter-b">
              <Header
                action={() => {
                  if (!user.permissions.includes("add_system_role"))
                    return toast.error(
                      "You dont have permissions to do this action"
                    );

                  setRole({ ...role, status: true, edit: false });
                }}
              />
              <div className="card-body py-0">
                <EnhancedTable
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleChangePage={handleChangePage}
                  handleRequestSortHeader={(order, orderBy) => {
                    if (!orderBy) return;
                    getRoles({
                      name: filter.data.name,
                      status: filter.data.status,
                      sortBy: orderBy,
                      sortOrder: order,
                      page: 1,
                      perPage: tableData.rowsPerPage,
                    });

                    setOrder({ order, orderBy });
                  }}
                  TableRows={
                    loading ? (
                      <LoadingTableRows
                        elementsCount={tableData.tableHead.length}
                      />
                    ) : tableData.tableItems.length > 0 ? (
                      tableData.tableItems.map((row, index) => (
                        <tr key={index} id="ID_tr">
                          <td id="ID_td1">{row.name}</td>
                          <td id="ID_td2">{row.users_count}</td>
                          <td id="ID_td3">
                            <FormGroup
                              className="Switch-div"
                              sx={{
                                maxWidth: "fit-content",
                                justifyContent: "center",
                              }}
                            >
                              <FormControlLabel
                                id="ID_FormControlLabel"
                                control={
                                  <Switch checked={row.status === "Active"} />
                                }
                                label={
                                  row.status === "Active"
                                    ? "Active"
                                    : "Inactive"
                                }
                                onChange={() => {
                                  if (
                                    !user.permissions.includes(
                                      "edit_system_role"
                                    )
                                  )
                                    return toast.error(
                                      "You dont have permissions to do this action"
                                    );
                                  changeStatus(row.id);

                                  const newTableData = {
                                    ...tableData,
                                    tableItems: tableData.tableItems.map(
                                      (item) => {
                                        if (item.id === row.id) {
                                          return {
                                            ...item,
                                            status:
                                              row.status === "Active"
                                                ? "Inactive"
                                                : "Active",
                                          };
                                        }
                                        return item;
                                      }
                                    ),
                                  };
                                  setTableData(newTableData);
                                }}
                              />
                            </FormGroup>
                          </td>
                          <td id="ID_td3">
                            <button
                              type="button"
                              onClick={() => {
                                if (
                                  !user.permissions.includes(
                                    "delete_system_role"
                                  )
                                )
                                  return toast.error(
                                    "You dont have permissions to do this action"
                                  );

                                setDeleteItem({
                                  status: !deleteItem.status,
                                  name: row.name,
                                  data: {
                                    role_id: row.id,
                                    alternative_role_id: "",
                                  },
                                });
                              }}
                              className="btn btn-light-primary btn-icon"
                              id="ID_trash"
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                            <button
                              id="buttonbutton2"
                              type="button"
                              className="btn btn-light-primary ms-2 btn-icon"
                              onClick={() => {
                                if (
                                  !user.permissions.includes("edit_system_role")
                                )
                                  return toast.error(
                                    "You dont have permissions to do this action"
                                  );

                                setRole({
                                  status: true,
                                  edit: true,
                                  data: row,
                                });
                              }}
                            >
                              <i className="fas fa-user-edit"></i>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <TableRow>
                        <td
                          colSpan={tableData.tableHead.length}
                          id="ID_tdNoData"
                        >
                          <Typography
                            sx={{ textAlign: "center" }}
                            variant="body2"
                            color="textPrimary"
                          >
                            No data found
                          </Typography>
                        </td>
                      </TableRow>
                    )
                  }
                  {...tableData}
                >
                  <div className="card-header flex-md-wrap border-0 ">
                    <div className="row align-items-center w-md-100">
                      <div className="col-md-12 ">
                        <div
                          className="card-title filter-title"
                          id="ID_setFilter"
                          onClick={() =>
                            setFilter({
                              ...filter,
                              status: !filter.status,
                            })
                          }
                        >
                          <h6 className="card-label" id="ID_FilterLable">
                            <i className="fas fa-sort-amount-down-alt mr-2"></i>
                            Filter by <i className="fas fa-sort list"></i>
                          </h6>
                        </div>
                      </div>

                      {filter.status && (
                        <FilterForm
                          filter={filter}
                          setFilter={setFilter}
                          handleSearch={(data) => {
                            if (data?.data) {
                              getRoles({
                                ...data.data,
                                sortBy: order.orderBy,
                                sortOrder: order.order,
                              });
                            } else {
                              getRoles({
                                ...filter.data,
                                sortBy: order.orderBy,
                                sortOrder: order.order,
                              });
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                </EnhancedTable>

                <ConfirmDeleteModal
                  data={deleteItem}
                  deleteRow={() => {
                    if (!user.permissions.includes("delete_system_role"))
                      return toast.error(
                        "You dont have permissions to do this action"
                      );

                    deleteRow();
                  }}
                  setData={(object) =>
                    setDeleteItem({ ...deleteItem, ...object })
                  }
                  isLoading={deleteLoading}
                  setIsLoading={setDeleteLoading}
                />
                <RoleModal
                  data={role}
                  getRoles={getRoles}
                  setData={(object) =>
                    setRole({
                      ...role,
                      data: { ...role.data, ...object },
                    })
                  }
                  removeAssignedUsers={() => {
                    const tempTableItems = tableData.tableItems;
                    tempTableItems.forEach((item) => {
                      if (item.name === role.data.name) {
                        item.users = [];
                      }
                    });
                    setTableData({ ...tableData, tableItems: tempTableItems });
                  }}
                  handleClose={() => {
                    setRole(initRoleState);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RolesManagement;
