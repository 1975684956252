import React, { useEffect, useState, useContext } from "react";
import { Container } from "react-bootstrap";
import MainHeader from "../Layout/Header";
import Footer from "../Layout/Footer";
import ComingSoon from "./ComingSoon";
import Live from "./Live";
import Closed from "./Closed";
import { AppContext } from "../../scripts/context";
import AppLoader from "../../components/AppLoader";

function Index() {
  const {
    state: {
      contracts: {
        presale: {
          data: { time },
        },
      },
      user,
    } = {},
  } = useContext(AppContext) || {};

  const statusObj = {
    // default: <DisconnectedWallet />,
    before: <ComingSoon />,
    live: <Live />,
    after: <Closed />,
  };

  // this state is used to determine which component for render
  const [renderedComponent, setRenderedComponent] = useState(statusObj[time]);

  useEffect(() => {
    setRenderedComponent(statusObj[time]);
  }, [time, user]);

  return (
    <Container fluid>
      <MainHeader />
      { renderedComponent || <AppLoader />}
      <Footer />
    </Container>
  )
}

export default Index;
