import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { editUrl } from "../proposalContractFunctions"
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

/**
 * Edit Proposal url Modal Component
 * author Mahmoud Skaik
 */
export default function EditUrlModal({
    handleClose,
    data,
    getProposalsList,
    ...props
}) {


    const [proposalUrlState, setProposalUrlState] = useState(data.url);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        setProposalUrlState(data.url)
    }, [data])

        /**
    * handle edit proposal url function
    * author Mahmoud Skaik
     */
    const handleEditProposalUrl = async () => {
        setIsLoading(true)
        try {
            const tx = await editUrl(data.id, proposalUrlState)

            const response = await tx.wait();
            if (response.status == 1) {
                setProposalUrlState()
                getProposalsList();
                handleClose();
                toast.success('Update Successfully')
            } else {
                toast.error('Update Failed')
            }


        } catch (error) {
            toast.error(JSON.parse(JSON.stringify(error)).reason)
        } finally {
            setIsLoading(false);
        }

    }


    return (
        <Modal
            {...props}
            onHide={() => {
                setProposalUrlState();
                setIsLoading(false);
                handleClose();
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h3 className="card-label mb-0" id="ID_name_modal">
                        Create New Proposal
                    </h3>
                </Modal.Title>
            </Modal.Header>
            <ModalBody className="px-5">
                <form className="row">
                    <div className="col col-md-12 col-lg-12 mb-2">
                        <div className="input-icon">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="URL"
                                id="url"
                                name="url"
                                // value={proposalUrlState}
                                value={proposalUrlState}
                                onChange={(e) => setProposalUrlState(e.target.value)} />
                            <span>
                            </span>
                        </div>
                    </div>
                </form>
            </ModalBody>
            <Modal.Footer>
                <Button
                    className="btn btn-light-primary"
                    onClick={() => {
                        setProposalUrlState();
                        setIsLoading(false);
                        handleClose()
                    }}

                    disabled={isLoading}
                    id="ID_Close"
                >
                    Close
                </Button>
                <Button
                    onClick={() => handleEditProposalUrl()}

                    className="btn btn-success"
                    disabled={isLoading}
                    id="ID_CREATE_PROPOSAL"
                >
                    Edit Proposal Url
                    {isLoading && (
                        <Spinner
                            style={{ marginLeft: ".5rem" }}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            id="ID_Spinner1"
                        />
                    )}
                </Button>


            </Modal.Footer>
        </Modal>
    );
}