import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { PolarChartData } from "../../../../configs/configPolarChart";
import { DatafieldChart } from "../../../../plugins/PieChart/PieChart";

/*  Render Polar Chart Component for displaying analytical data through infographics and charts
*  Defining the data through the config PolarChart and PieChart file
 | khaled Mofeed | 29/10/2022 */

function PolarChart(props) {
  useEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.PieChart);
    chart.data = PolarChartData;
    const colors = [
      am4core.color("#4285f4"),
      am4core.color("#ea4335"),
      am4core.color("#fbbc04"),
      am4core.color("#34a853"),
      am4core.color("#ff9901"),
      am4core.color("#46bdc6"),
      am4core.color("#267ba3"),
      am4core.color("#f07b72"),
      am4core.color("#af52de"),
    ];
    DatafieldChart(chart, "value", "country", "Legend", colors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      id="chartdiv"
      style={{
        width: "100%",
        height: "640px",
        position: "relative",
      }}
    >
      <img
        src="/img/Group.svg"
        alt="chart-center"
        className="chart-center"
        id="chart-center"
      />
    </div>
  );
}

export default PolarChart;
