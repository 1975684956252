const usePagination = ({ setCurrentPage, currentPage, maxPage }) => {
  let currentThree = [currentPage - 1, currentPage, currentPage + 1];

  // if the current page is 1, we will add 2 to the array
  if (currentPage === 1) {
    currentThree = [currentPage, currentPage + 1, currentPage + 2];
  }

  // if the current page is the last page, we will add the last two pages to the array
  if (currentPage === maxPage && maxPage > 2) {
    currentThree = [maxPage - 2, maxPage - 1, maxPage];
  }

  const next = () => {
    if (currentPage === maxPage) return;
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  };

  const prev = () => {
    if (currentPage === 1) return;
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  };

  const jump = (page) => {
    if (page < 1 || page > maxPage) return;
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  };

  return { next, prev, jump, currentThree };
};

export default usePagination;
