/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import abi from "../configs/abis/televerse-nft-contract.json";
import config from "../configs";

const useNftProposal = () => {
  /* this address is used to connect to the smart contract | khaled Mofeed */
  const nftContractPrposalAddress = config.get(
    "smartContract.NFT_CONTRACT_PROPOSAL_ADDRESS"
  );

  const [contract, setContract] = useState(null);
  const [nftTotal, setNftTotal] = useState();
  /* this function is used to connect to the smart contract | khaled Mofeed */
  const connectToContract = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      nftContractPrposalAddress,
      abi,
      signer
    );
    setContract(contract);
  };
  /* this function is used to get the single data from smart contract | khaled Mofeed */
  const singleValueFunc = async (func, args) => {
    const value = await contract[func](args);
    return parseInt(value);
  };
  /* this function is used to get the total NFT for proposal | khaled Mofeed */
  const getNftProposal = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    const data = await singleValueFunc("balanceOf", address);
    setNftTotal(data);
  };

  useEffect(() => {
    connectToContract();
  }, [window.ethereum]);

  useEffect(() => {
    getNftProposal();
  }, [contract, window.ethereum]);

  return {
    nftTotal,
  };
};

export default useNftProposal;
