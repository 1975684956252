import { Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom/dist";
import { useForm } from "../../layouts/forms/actions";

function ForgetPasswordForm(props) {
  /**
   * use the useSearchParams to handle the operations
   * of forms
   * author Khaled Mofeed
   */
  const [searchParams] = useSearchParams({
    token: "",
  });
  /**
   * use the useForm custom hook to handle the operations
   * of forms
   * author Khaled Mofeed
   */
  const {
    form,
    handleResetPasswordSubmit,
    setValue,
    errors,
    loading,
    handleBackToLogin,
  } = useForm({
    email: searchParams.get("email"),
    password: "",
    password_confirmation: "",
  });
  /**
   * extract the attributes of form
   * author Khaled Mofeed
   */
  const { email, password, password_confirmation } = form;

  return (
    <>
      <h2 className="mb-2">Reset Password ?</h2>
      <div className="text-center mb-4" style={{ color: "#ccc" }}>
        Please enter and confirm your new password below to access your account.
      </div>
      <form
        className="form row position-relative"
        onSubmit={handleResetPasswordSubmit}
      >
        <div className="form-group col-12 col-md-12">
          <input
            type="password"
            className={`form-control form-control-solid border-0 bg-dark-2 ${
              errors.password && "is-invalid"
            }`}
            placeholder="New Password"
            name="password"
            value={password}
            onChange={setValue}
            id="password"
          />
          {errors.password && (
            <div className="invalid-feedback">{errors.password}</div>
          )}
        </div>
        <div className="form-group col-12 col-md-12">
          <input
            type="password"
            className={`form-control form-control-solid border-0 bg-dark-2 ${
              errors.password_confirmation && "is-invalid"
            }`}
            placeholder="Confirm Password"
            name="password_confirmation"
            value={password_confirmation}
            onChange={setValue}
            id="password_confirmation"
          />
          {errors.password_confirmation && (
            <div className="invalid-feedback">
              {errors.password_confirmation}
            </div>
          )}
        </div>

        <div className="col-12 col-md-12 text-center">
          <button
            type="submit"
            name="submit"
            value="Submit"
            className="btn btn-lg btn-primary px-10"
            disabled={loading}
          >
            Request
            {loading && (
              <Spinner
                style={{ marginLeft: ".5rem" }}
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
          <button
            type="button"
            name="Cancel"
            className="btn btn-lg btn-secondary px-10"
            onClick={handleBackToLogin}
            disabled={loading}
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  );
}

export default ForgetPasswordForm;
