import { Button, Checkbox, FormControlLabel } from '@mui/material';
import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import { useResale } from '../../../../hooks/useMarketplace';
import { toast } from 'react-hot-toast';
import { pxToRem } from '../theme';
import { isEqual } from 'lodash';

const ResaleForm = ({ landData, refetch }) => {
  const { resale, makeAcceptOffer, setPrice } = useResale();

  const handleSubmit = (values) => {
    if (isEqual(values, initialValues)) {
      toast.error('Please change at least one value');
      return;
    }

    if (!landData?.is_offered_for_sale) {
      if (!+values.price) {
        toast.error('Please enter a price');
        return;
      }
      resale(landData?.token_id, values.price, values.listForSale, values.acceptOffers);
    } else {
      // * and if the listForSale value is different from the initial value, or the price is different from the initial value, we need to call the setPrice function, and pass the token_id, the price, and the listForSale value.
      if (values.listForSale !== initialValues.listForSale || values.price != initialValues.price) {
        if (values.listForSale) {
          if (!+values.price || +values.price <= 0) {
            toast.error('Please enter a price');
            return;
          }
          setPrice(landData?.token_id, values.price, true);
        } else {
          setPrice(landData?.token_id, 0, false);
        }
      }

      // * otherwise if the land is offered for sale, that means that the land is already listed for sale, so we need to check if the acceptOffers value is different from the initial value, and if it is different, we need to call the makeAcceptOffer function, and pass the token_id and the acceptOffers value.
      if (values.acceptOffers !== initialValues.acceptOffers) {
        makeAcceptOffer(landData?.token_id, values.acceptOffers);
      }
    }
    setTimeout(() => {
      refetch();
    }, 10000);
  };

  const initialValues = useMemo(
    () => ({
      acceptOffers: landData?.is_open_make_offer || false,
      listForSale: landData?.is_show_buy || false,
      price: landData?.price || '0.0000',
    }),
    [landData?.is_open_make_offer, landData?.is_show_buy, landData?.price],
  );

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, handleChange, handleBlur, setFieldValue }) => {
        const handleAcceptOffersChange = (event) => {
          const { checked } = event.target;

          // If "acceptOffers" is being checked, ensure "listForSale" is also checked
          // If not, uncheck "acceptOffers"
          if (checked && !values.listForSale) {
            setFieldValue('acceptOffers', true);
            setFieldValue('listForSale', true);
          } else {
            handleChange(event);
          }
        };

        const handleListForSaleChange = (event) => {
          const { checked } = event.target;

          // If "listForSale" is being unchecked, also uncheck "acceptOffers"
          if (!checked) {
            setFieldValue('acceptOffers', false);
          }

          handleChange(event);
        };

        return (
          <Form className='remove-input-defaults bg-border-gradiant contact-form-bg'>
            <div className='resale-form d-flex align-items-center gap-3 flex-wrap justify-content-between'>
              <div className='d-flex lg-justify-between xs-wrap'>
                <div className='d-flex align-items-lg-center flex-column flex-lg-row'>
                  <FormControlLabel
                    control={<Checkbox checked={values.acceptOffers} />}
                    label='Accept Offers'
                    name='acceptOffers'
                    onChange={handleAcceptOffersChange}
                    onBlur={handleBlur}
                    className='m-0 mr-5 mp-checkbox'
                  />
                  <FormControlLabel
                    control={<Checkbox checked={values.listForSale} />}
                    label='List For Sale'
                    name='listForSale'
                    onChange={handleListForSaleChange}
                    onBlur={handleBlur}
                    className='m-0 mr-5 '
                  />
                </div>
                <div className='d-flex align-items-center '>
                  <span className='text-white text-sm mr-3 text-center'>The Price</span>

                  <div className='input-currency-wrapper'>
                    <input
                      type='number'
                      name='price'
                      value={values.price}
                      onChange={(event) => {
                        if (event.target.value > 1000000) {
                          event.target.value = 1000000;
                        }
                        handleChange(event);
                      }}
                      onBlur={handleBlur}
                      className='form-control form-control-solid bg-dark2 input-currency'
                    />
                  </div>
                </div>
              </div>
              <Button
                type='submit'
                size='large'
                className='submit-resale'
                sx={{
                  borderRadius: pxToRem(85),
                  marginBottom: pxToRem(12),
                  fontWeight: '600',
                  width: '25%',
                  margin: '0',
                }}
                variant='contained'
                color='primary'>
                Submit
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ResaleForm;
