import React from "react";
import Container from "react-bootstrap/Container";
import Header from "../Layout/Header";
import Advisors from "../../components/home&token/Advisors";
import Chart from "./components/Chart";
import DistributionVesting from "./components/DistributionVesting";
import Footer from "../Layout/Footer";
import GetTouch from "../../components/home&token/GetTouch";
import Herosection from "./components/HeroSection";
import JoinTELV from "../../components/home&token/JoinTELV";
import RoadMap from "./components/RoadMap";
import Team from "../../components/home&token/Team";
import WriteAboutUs from "../../components/home&token/WriteAboutUs";

function index(props) {
  return (
    <>
      <Container fluid className="bg-hero">
        <Header />
        <Herosection />
      </Container>
      <Container fluid>
        <JoinTELV />
        <DistributionVesting />
        <Chart />
        <RoadMap />
        <Team negativeMargin={true} />
      </Container>
      <Container fluid className="advisory-bg">
        <Advisors />
      </Container>
      <Container fluid className="WriteAboutUs-bg">
        <WriteAboutUs />
      </Container>
      <Container fluid>
        <GetTouch />
      </Container>
      <Container fluid>
        <Footer />
      </Container>
    </>
  );
}

export default index;
