import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../../../Layout/Footer";
import MainHeader from "../../../../Layout/Header";
import HeadSubmit from "./HeadSubmit";
import InfoForm from "./InfoForm";
// this is the Container of PageSubmit Event Page have:
// HeadSubmit , header of page component
// InfoForm, the component that user needs to add new event by form
// | khaled Mofeed
function ContainerPageSubmitEvent(props) {
  return (
    <Container fluid>
      <MainHeader />
      <Row className="justify-content-center DistributionVesting pb-0 min-height-100per">
        <Col md={10} xl={10} className="mb-5 mb-md-0">
          <Row>
            <HeadSubmit />
          </Row>

          <InfoForm />
        </Col>
      </Row>
      <Footer />
    </Container>
  );
}

export default ContainerPageSubmitEvent;
