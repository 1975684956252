import { useState } from "react";
import { useAuth } from "../../../scripts/App/auth";
import toast from "react-hot-toast";
import { request } from "../../../plugins/axios";
import { endpoints } from "../../../../utils/networks";
/**
 * (Custom Hook)
 * this custom hook handles all operations of the Event Modal {Approve || Reject}
 * param {{data, setData, handleClose}}
 * returns {{isLoading,
 * changeStatus
}}
 * author Mahmoud Skaik
 */
export const useEventModal = (data, setData, handleClose, updateEvent) => {
  /**
   * this data is returned from context to use it by functions
   * author Mahmoud Skaik
   */
  const { user, roles_permissions, permissions, setState } = useAuth();
  /**
   * this state used for handel the loading when run function
   * author Mahmoud Skaik
   */
  const [isLoading, setIsLoading] = useState(false);

  /**
   * this function is used to handle the Change Status
   * author Mahmoud Skaik
   */
  const changeStatus = async(uuid, status) => {
        if (!user.permissions.includes("edit_event"))
      return toast.error("You dont have permissions to do this action");

    setIsLoading(true);

    return request(
      endpoints.Events.ChangeStatus.method,
      endpoints.Events.ChangeStatus.url,
      {
        event_uuid: uuid,
        status: status
      }
    )
      .then((response) => {
        toast.success(response?.data?.message);
         updateEvent(uuid, status);
        handleClose();
      })
      .catch((erorr) => {
        toast.error(erorr?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return {
    isLoading,
    changeStatus
  };
};
