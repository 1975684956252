import React from 'react';
import './style.css';
import TechImg from '../../../../assets/home/powered-portal-to.png';
import TechImgMobile from '../../../../assets/home/powered-portal-to-mobile.png';
import { Image, Col, Row } from 'react-bootstrap';

function Portals() {
  return (
      <Row className='powered-section align-content-center justify-content-center'>
        <Col md={8}>
        <Row className='align-items-center'>
          <Col className='powered-text d-flex justify-content-lg-end ' md={6}>
            <span className='powered-portals-head'>First<br/> Blockchain<br/> Powered<br/> portal to:</span>
          </Col>
          <Col className='powered-img' md={6}>
            <Image className='portals-img' src={TechImg} alt='Technologies Image' fluid />
            <Image className='portals-img-mob' src={TechImgMobile} alt='Technologies Image' fluid />
          </Col>
        </Row>
        </Col>
      </Row>
  );
}

export default Portals;
