import React, { useContext, useMemo } from "react";
import { Container } from "react-bootstrap";
import MainHeader from "../Layout/Header";
// import DisconnectedWallet from '../../components/DisconnectedWallet';
// import Footer from "../../components/CustomFooter";
import { AppContext } from "../../scripts/context";
import BalanceDetails from "./BalanceDetails/components";
import Footer from "../Layout/Footer";

function Index() {
  const { state: { user } = {} } = useContext(AppContext) || {};

  const ComponentToRender = useMemo(
    () => user?.address && <BalanceDetails />,
    [user?.address]
  );

  return (
    <Container fluid>
      <MainHeader />
      {ComponentToRender}
      <Footer />
    </Container>
  );
}

export default Index;
