import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../Layout/Footer";
import MainHeader from "../Layout/Header";
import Supply from "./Component/Supply";
import TabPages from "./Component/TabPages";
import Revenue from "./Component/Revenues";
import Proposal from "./Component/Proposal";
import Claim from "./Component/Revenues/Claim";
import "./style.css";
import { digitalShareTabs } from "../../constants";
import { AppContext } from "../../scripts/context";
// import DisconnectedWallet from '../../components/DisconnectedWallet';
/**
 * (Digital Share Page)
 * author Khaled Mofeed
 **/
function Index({ active }) {
  const {
    state: { user },
  } = useContext(AppContext);

  return (
    <Container fluid>
      <MainHeader />
      {/* {user.address ? ( */}
      <Row className="justify-content-center DistributionVesting min-height-100per">
        <Col md={10} xl={10} className="mb-5">
          <Row>
            <Col xs={11} className="d-block mx-auto d-md-none mb-5">
              <Supply />
            </Col>
            <Col md={8} xl={8} className="mobile-size">
              {active === digitalShareTabs.REVENUE ? <Revenue /> : <Proposal />}
            </Col>
            <Col md={4} xl={4} className="d-none d-md-block">
              <Supply />
              {active === digitalShareTabs.REVENUE && (
                <div className="d-none d-xl-block">
                  <Claim />
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {/* ) : (
        // This component will appear if there is no connection to the wallet with MetaMask | khaled Mofeed
        <DisconnectedWallet />
      )} */}
      <Footer />
    </Container>
  );
}

export default Index;
