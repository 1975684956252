import React from "react";
import "./style.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
/* this Card Event Slider Component use for displaying Events data in Down of XL screen | khaled Mofeed */
function cardEventSlider({ AllEventDetails, TimeEvent, handleModalClick }) {
  const date = new Date(AllEventDetails?.start_date);
  const formattedDate = date?.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });
  return (
    <>
      <Row className="eventsSliderImgs">
        <Col md={6} xl={7}>
          <img
            src={AllEventDetails.cover}
            alt="events"
            className="w-100 h-100 eventsImg pointer"
            id={AllEventDetails?.id}
            onClick={handleModalClick}
          />
        </Col>
        <Col md={6} xl={5}>
          <div className="EventSpecail">
            <Row>
              <Col lg={12} className="px-0  border-bottom">
                <Row>
                  <Col xs={3}>
                    <div className="dateInfo">
                      <h4>{formattedDate}</h4>
                    </div>
                  </Col>
                  <Col xs={9} className="pl-0">
                    <div className="titleAndDesc">
                      <h4>{AllEventDetails.name}</h4>
                      <p>
                        {AllEventDetails?.categories?.map((category) => (
                          <span key={category.id} className="categorySpan">
                            {category.name} ,{" "}
                          </span>
                        ))}
                        Organized by <span>{AllEventDetails.created_by}</span>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={12} className="px-0 py-4 border-bottom">
                <Row>
                  <Col xs={6}>
                    <div className="SatetAt">
                      <img src="/img/events/start.svg" alt="start at" />
                      {TimeEvent(
                        AllEventDetails.start_date,
                        AllEventDetails.end_date,
                        AllEventDetails.start_time
                      )}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="AddCalaender text-right">
                      <button>ADD TO CALENDER</button>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={12} className="px-0 py-4 border-bottom">
                <Row>
                  <Col xs={6}>
                    <div className="SatetAt">
                      <img src="/img/events/location.svg" alt="LightBtn" />{" "}
                      Conference Center
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="actionLable">
                      <span className="lable-dark">
                        <img
                          src="/img/events/location-light.svg"
                          alt="location"
                        />{" "}
                        {AllEventDetails.location.substring(0, 6) + "..."}
                      </span>
                      <span className="lable-LightBtn">
                        <img src="/img/events/LightBtn.svg" alt="location" />
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col lg={12} className="px-0 pt-4">
                <div className="ActionJump">
                  <button className="btnCustome btn btn-outline-secondary w-100 mr-2">
                    JUMP IN
                  </button>
                  <img
                    src="/img/events/share.svg"
                    alt="share"
                    className="shareBtn"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default cardEventSlider;
