import { useEffect } from "react";
import Range from "./Range";
import { formatDistanceStrict } from "date-fns";
import { useBallot } from "../../../../scripts/useBallot";
import { useProposal } from "../../Hooks/useProposal";
import Filter from "./Filter";
import {
  HTTP_ACTIVE,
  HTTP_IN_ACTIVE,
  HTTP_PAGE_No,
  HTTP_PER_PAGE,
  HTTP_UNIT,
} from "../../../../constants";
import useNftProposal from "../../../../hooks/useNftProposal";
/**
 * (Card Proposal Component for view The Cards of Proposal and fillter by activity and date)
 * author Khaled Mofeed
 **/
function CardProposal({ proposalsList, currentPage, no }) {
  const { like, dislike } = useBallot();
  const { nftTotal } = useNftProposal();
  /**
   * use the useProposal custom hook to handle the operations
   * author Khaled Mofeed
   */
  const {
    toggle,
    sortByDate,
    statusProposal,
    rangeLikeValue,
    rangeVotes,
    checked,
    setChecked,
    refresh,
    setRefresh,
    data,
    setData,
    sortAsc,
  } = useProposal();

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
    return () => setRefresh(false);
  }, [refresh, data]);

  /**
   * function to set data
   * author Khaled Mofeed
   */
  const fetchData = async () => {
    const result = await proposalsList(HTTP_PAGE_No, HTTP_PER_PAGE);
    setData(result);
  };
  /**
   * use effect use to render th data
   * author Khaled Mofeed
   */
  useEffect(() => {
    fetchData();
  }, [proposalsList, currentPage, no]);

  return (
    <>
      <Filter
        toggle={toggle}
        sortByDate={sortByDate}
        checked={checked}
        setChecked={setChecked}
        sortAsc={sortAsc}
      />
      {!data?.totalList === 0 ? (
        <div className="row cardProposalNoData mt-4">
          <div className="col-12 mb-0">
            <h6 className="mb-0 text-center"> No Data Found </h6>
          </div>
        </div>
      ) : (
        data?.transactions?.map(
          ({
            id,
            key,
            closeAt,
            numberOfLike,
            numberOfDislike,
            softCap,
            title,
          }) => (
            <>
              <div
                className={`row cardProposal mt-4 ${
                  statusProposal(closeAt) === HTTP_ACTIVE
                    ? "active"
                    : "inactive"
                } ${checked && "dnoneForInactive"}`}
                key={key}
              >
                <div className="col-12 mb-4">
                  <h6 className="mb-3">{title} </h6>
                </div>
                <div className="col-12 col-lg pt-3">
                  <div className="CardView">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6">
                            <p className="firstline mb-4">
                              Status:{" "}
                              <strong> {statusProposal(closeAt)}</strong>
                            </p>
                          </div>
                          <div className="col-6 pl-0 text-right">
                            <p className="firstline mb-4">
                              Closes{" "}
                              <strong>
                                {statusProposal(closeAt) === HTTP_IN_ACTIVE ? (
                                  <span>Ended</span>
                                ) : (
                                  formatDistanceStrict(
                                    new Date(closeAt),
                                    new Date(),
                                    {
                                      addSuffix: true,
                                      unit: HTTP_UNIT,
                                    }
                                  )
                                )}
                              </strong>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 ">
                        <div className="row ">
                          <div className="col-6 ">
                            <p className="secondline pt-2 mb-4">
                              Total Votes <br></br>
                              <strong>{numberOfDislike + numberOfLike}</strong>
                            </p>
                          </div>
                          <div className="col-6 pl-0 text-right">
                            <p className="secondline pt-2 mb-4">
                              Soft Cap <br></br>
                              <strong> {softCap}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 range">
                        <Range
                          className="w-100 Range"
                          value={rangeVotes(
                            numberOfLike,
                            numberOfDislike,
                            softCap
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-12 col-lg mt-4 mt-lg-auto mb-0 ml-lg-auto">
                  <div className="CardViewActive">
                    <div className="row">
                      <div className="col-6">
                        <div className="firstline mb-4">
                          <button
                            onClick={() => like(id)}
                            className="likebtn"
                            disabled={
                              statusProposal(closeAt) === HTTP_IN_ACTIVE ||
                              nftTotal === 0
                                ? true
                                : undefined
                            }
                          >
                            <img
                              src="/img/Like.svg"
                              alt="Like"
                              className="LikeBtn"
                            />
                          </button>
                        </div>
                        <p className="secondline mb-4">
                          With <br></br>
                          <strong> {numberOfLike} </strong>
                        </p>
                      </div>
                      <div className="col-6 text-right">
                        <p className="firstline mb-4">
                          <button
                            onClick={() => dislike(id)}
                            className="likebtn"
                            disabled={
                              statusProposal(closeAt) === HTTP_IN_ACTIVE ||
                              nftTotal === 0
                                ? true
                                : undefined
                            }
                          >
                            <img
                              src="/img/disLike.svg"
                              alt="Like"
                              className="LikeBtn"
                            />
                          </button>
                        </p>
                        <p className="secondline mb-4">
                          Against <br></br>
                          <strong> {numberOfDislike}</strong>
                        </p>
                      </div>
                      <div className="col-12 range">
                        <Range
                          className="w-100 Range"
                          value={rangeLikeValue(numberOfLike, numberOfDislike)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        )
      )}
    </>
  );
}

export default CardProposal;
