import React, { useState } from 'react';
import CustomTable from './Table';
import Pagination from './Pagenation';
// import { getUserPurchasedTransactionsList } from '../../presaleContractFunctions';
import './style.css';
import { useRevenueDetails } from '../../../../hooks/useRevenue';

function Transactions({ needToRefetch }) {
  let pages = 0;
  const [currentPage, setCurrentPage] = useState(1);
  let { data: transactions, error, refetch } = useRevenueDetails(currentPage, 5);

  transactions = transactions?.data?.data;
  console.log(transactions);

  return (
    <div className='shared-space'>
      <CustomTable data={transactions?.details} currentPage={currentPage} />
      <Pagination
        data={transactions?.pagination_options}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

export default Transactions;
