export const EventTableData = {
    page: 1,
    pagesCount: -1,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    tableHead: [
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Name",
        sortable: true
      },
      {
        id: "dateTime",
        numeric: false,
        disablePadding: false,
        label: "Date-Time",
        sortable: true
      },
      {
        id: "location",
        numeric: false,
        disablePadding: false,
        label: "Location",
        sortable: true
      },
      {
        id: "creator",
        numeric: false,
        disablePadding: false,
        label: "Creator",
        sortable: false
      },
      {
        id: "creatorAddress",
        numeric: false,
        disablePadding: false,
        label: "Creator Address",
        sortable: false
      },
      {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        sortable: true

      },
      {
        id: "schedule_status",
        numeric: false,
        disablePadding: false,
        label: "Schedule Status",
        sortable: true

      },
      {
        id: "actions",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        sortable: true

      },
    ],
    tableItems: [],
  };
  