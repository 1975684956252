/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import Copy from "../../../../assets/copy-coins.svg";
import { useNftPresale } from "../../../../scripts/nftPresale";
import { copyToClipboard, useDebounce } from "../../../../Helper/Helper";
import "./style.css";
import { toast } from "react-hot-toast";
import clsx from "clsx";
import { AppContext } from "../../../../scripts/context";

function Buy() {
  const TIME_TO_DISABLE_BUTTON = 1000;
  const [isDisabled, setIsDisabled] = useState(false);
  const [moreThanMaxBy, setMoreThanMaxBy] = useState(false);
  const [enterValue, setEnterValue] = useState();

  const {
    userData,
    state: { user = {}, getShortenAddress } = {},
    disconnect,
  } = useContext(AppContext) || {};
  const { price, balance, NFT, maxBuy, purchase, setNFT } = useNftPresale();

  // to get my address
  // const bnbAdressShort =
  //   user.address?.slice(0, 9) + "..." + user.address?.slice(-4);
  function copyAdressToClipboard() {
    copyToClipboard(user.address);
  }

  const purchaseHandler = async (e) => {
    try {
      await purchase(e);
    } catch (error) {
      console.log(error);
      toast.error("Purchase failed");
    }
  };

  const setNFTHandler = (e) => {
    setMoreThanMaxBy(false);
    setEnterValue(e.target.value);
    if (e.target.value < 1) {
      setNFT(1);
      return;
    }
    if (e.target.value > maxBuy) {
      setNFT(maxBuy);
      setMoreThanMaxBy(true);
      return;
    }
    setNFT(e.target.value);
    setMoreThanMaxBy(false);
  };
  const setNFTMaxHandler = (e) => {
    setNFT(maxBuy);
  };

  useEffect(() => {
    // Set a timeout to enable the button after 1 second
    const timeoutId = setTimeout(() => {
      setIsDisabled(false);
    }, TIME_TO_DISABLE_BUTTON);

    // Cleanup function to clear the timeout when the component unmounts or the state changes
    return () => clearTimeout(timeoutId);
  }, [isDisabled]);

  const debouncedPurchase = useDebounce(
    purchaseHandler,
    TIME_TO_DISABLE_BUTTON
  );

  const purchaseButtonHandler = (e) => {
    e.stopPropagation();

    setIsDisabled(true);
    debouncedPurchase();
  };

  return (
    <Row className="bg-live align-items-center justify-content-center shared-margin shared-space">
      <Col xs={10} className="live-buy-section">
        <h1>Buy DIGITAL SHARE</h1>
        <div className="bnb-address d-flex justify-content-between align-items-center">
          <p>
            <span className="balanceLable"> Balance</span>
            {Number(balance).toFixed(4)} BNB
          </p>
          <div>
            <span className="mr-2">{getShortenAddress(user.address)}</span>
            <img
              onClick={copyAdressToClipboard}
              src={Copy}
              alt="copy"
              className="Copy"
            />
          </div>
        </div>
        <div className="presal-amount">
          <h6 className="d-flex align-self-start">
            Amount To Buy <span> ( Minimum is 1 ) </span>
          </h6>
          <div className="d-flex justify-content-between align-items-center pb-2">
            <input
              type="number"
              value={NFT}
              onChange={setNFTHandler}
              min={1}
              max={maxBuy}
              step={1}
              lang="en"
              inputmode="decimal"
              autocomplete="off"
              autocorrect="off"
              placeholder="0.0"
              // spellcheck="false"
              // minlength="1"
              // maxlength="79"
              // pattern="[0-9]*[.,]?[0-9]+"
              pattern="^[0-9]*[.,]?[0-9]*$"
              className="setNFTHandlerInput"
            />
            {/* <input
              class="token-amount-input _1cvvxtw5 _1cvvxtw8"
              inputmode="decimal"
              title="Token Amount"
              autocomplete="off"
              autocorrect="off"
              type="text"
              pattern="^[0-9]*[.,]?[0-9]*$"
              placeholder="0.0"
              minlength="1"
              maxlength="79"
              spellcheck="false"
              value=""
            ></input> */}
            <span onClick={setNFTMaxHandler}>MAX</span>
          </div>

          <hr />
          {moreThanMaxBy && (
            <p className="moreThanMaxBy">
              The maximum amount of DS for one account is{" "}
              <span> {maxBuy} </span>
            </p>
          )}
        </div>
        <div className="fees-data">
          <div>
            <h6>Price</h6>
            <h2>{price} BNB / DS </h2>
          </div>
          <div>
            <h6>Deducted Amount</h6>
            <h2>{(price * NFT).toFixed(3)} BNB</h2>
          </div>
        </div>
        <div className="mt-3">
          <span
            onClick={purchaseButtonHandler}
            className={clsx("presale-submit-btn", isDisabled && "disabled ")}
            disabled={isDisabled}
          >
            Submit
          </span>
        </div>
      </Col>
    </Row>
  );
}

export default Buy;
