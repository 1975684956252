import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { addRevenue } from "../revenueContractFunctions"
import { useState } from "react";
import { toast } from "react-hot-toast";

export default function RevenueModal({
    handleClose,
    channelsList,
    getChannelsList,
    getRevenuesList,
    revenueStatisticsData,
    ...props
}) {
      /**
   * Create revenue modal component
   * author Mahmoud Skaik
   */
    const [revenueDate, setRevenueDate] = useState('')
    const [revenueChannelsAmounts, setRevenueChannelsAmounts] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const handleAddRevenue = async () => {
        setIsLoading(true)

        const date = new Date(revenueDate).getTime() / 1000;
        const channelIds = Object.keys(revenueChannelsAmounts);
        const amounts = [];
        const amountsValues = Object.values(revenueChannelsAmounts);

        for (const key in amountsValues) {
            amounts.push('0x' + Number((amountsValues[key] == '' ? 0 : amountsValues[key]) * 10 ** 16).toString(16))
        }
        
        try {
            const tx = await addRevenue(date, channelIds, amounts)

            const response = await tx.wait();
            if (response.status == 1) {
                setRevenueDate('');
                setRevenueChannelsAmounts({});
                getRevenuesList();
                revenueStatisticsData();
                handleClose();
                toast.success('Created Successfully')
            } else {
                toast.error('Created Failed')
            }


        } catch (error) {
            toast.error(JSON.parse(JSON.stringify(error)).reason)
        } finally {
            setIsLoading(false);
        }

    }

    return (
        <Modal
            {...props}
            onHide={() => {
                setRevenueDate('');
                setRevenueChannelsAmounts({});
                setIsLoading(false);
                handleClose();
              }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h3 className="card-label mb-0" id="ID_name_modal">
                        Add New Revenue
                    </h3>
                </Modal.Title>
            </Modal.Header>
            <ModalBody className="px-5">
                <form className="row">
                    <div className="col col-md-12 col-lg-12 mb-2  ">
                        {channelsList.map((item, index) => {
                            return (
                                <div key={index} className="row align-items-center mt-3 mb-4">

                                    <div className="input-icon">

                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={item.name}
                                            name={"channelName" + item.id}
                                            id={"channelName" + item.id}
                                            value={revenueChannelsAmounts[item.id]}
                                            onChange={(e) => setRevenueChannelsAmounts({
                                                ...revenueChannelsAmounts,
                                                [item.id]: e.target.value
                                            })}
                                        />
                                    </div>
                                </div>
                            );
                        })}

                        <div className="row align-items-center mt-3 mb-4">
                            <div className="input-icon">
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder="Revenue Date"
                                    id="channel_name"
                                    name="channel_name"
                                    value={revenueDate}
                                
                                    onChange={(e) => setRevenueDate(e.target.value)}
                                />

                            </div>


                            <span>
                            </span>
                        </div>
                    </div>
                </form>

            </ModalBody>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        setRevenueDate('');
                        setRevenueChannelsAmounts({});
                        setIsLoading(false);
                        handleClose();
                      }}
                    className="btn btn-light-primary"
                    id="ID_Close"
                    disabled={isLoading}
                >
                    Close
                </Button>
                <Button
                    onClick={() => handleAddRevenue()}
                    className="btn btn-success"
                    id="ID_ADD_REBENUE"
                    disabled={isLoading}
                >
                    Add Revenue
                    {isLoading && (
                        <Spinner
                            style={{ marginLeft: ".5rem" }}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            id="ID_Spinner1"
                        />
                    )}
                </Button>



            </Modal.Footer>
        </Modal>
    );
}