import React from 'react';
import './style.css';
import { Image, Col, Row } from 'react-bootstrap';
import WorldMap from '../../../../assets/home/world-map.png';

function TeleverseWorld() {
  return (
    <Row className='justify-content-center align-items-center televerseworld'>
      <Col md={10}>
        <Row className='align-items-center televerse-world'>
          <Col className='televerse-world-text' lg={5}>
            <span className='televerse-world-head'>Televerse World</span>
            <p className='televerse-world-description'>
              In a world full of creativity and diversity of content, the user will live an exciting
              experience in the televerse world sharing that with others who share the same
              interests with the ability to smoothly move from one field to another and explore
              other interests.
            </p>
          </Col>
          <Col
            className='televerse-world-img'
            lg={{ offset: 1, order: 'last' }}
            xs={{ order: 'first' }}>
            <Image className='map-img' src={WorldMap} alt='televerse-world' fluid />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default TeleverseWorld;
