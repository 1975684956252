export const BrandsImageConfig = [
  {
    id: "logo1",
    image: "img/logo1.svg",
  },
  {
    id: "logo2",
    image: "img/logo2.svg",
  },
  {
    id: "logo3",
    image: "img/logo3.svg",
  },
  {
    id: "logo4",
    image: "img/logo4.svg",
  },
  {
    id: "logo5",
    image: "img/logo5.svg",
  },
];
