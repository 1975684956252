import ProgressBar from "react-bootstrap/ProgressBar";
/**
 * Range Component for view the percentage by progress bar
 * author Khaled Mofeed
 */
function Range({ value }) {
  const now = value;
  return (
    <ProgressBar
      now={now}
      label={<img src="/img/rang.svg" alt="range" className="rangImage" />}
    />
  );
}

export default Range;
