import { useCallback, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { request } from '../admin/plugins/axios';
import { AppContext } from '../scripts/context';
import { endpoints } from '../utils/networks';

export const useClaimRevenue = () => {
  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [claimError, setBuyError] = useState(null);
  const [claimTxHash, setBuyTxHash] = useState(null);
  const [claimTxReceipt, setBuyTxReceipt] = useState(null);
  const [claimTxError, setBuyTxError] = useState(null);
  const [claimTxStatus, setBuyTxStatus] = useState(null);
  const {
    state: {
      contracts: {
        revenue: { contract },
      },
    },
  } = useContext(AppContext);

  const claim = useCallback(async () => {
    if (!contract) {
      return;
    }
    try {
      setIsClaimLoading(true);
      setBuyError(null);
      setBuyTxHash(null);
      setBuyTxReceipt(null);
      setBuyTxError(null);
      setBuyTxStatus(null);

      const tx = await contract.stake();

      setBuyTxHash(tx.hash);

      const receipt = await tx.wait();

      setBuyTxReceipt(receipt);

      if (receipt.status === 1) {
        setBuyTxStatus('success');
      } else {
        setBuyTxStatus('fail');
        setBuyTxError('Transaction failed');
      }
    } catch (error) {
      const errMessage = JSON.parse(JSON.stringify(error)).reason;
      setBuyTxError(errMessage);
    } finally {
      setIsClaimLoading(false);
    }
  }, [contract]);

  return {
    isClaimLoading,
    claimError,
    claimTxHash,
    claimTxReceipt,
    claimTxError,
    claimTxStatus,
    claim,
  };
};

export const useRevenueDetails = (page, limit) => {
  const { method, url } = endpoints.Revenue.Details;

  return useQuery(['revenueDetails', page, limit], () =>
    request(method, url, { params: { per_page: limit, page } }),
  );
};

export const useRevenueIncome = () => {
  const { method, url } = endpoints.Revenue.Income;

  return useQuery('revenueIncome', () => request(method, url));
};

export const useRevenueClaimed = () => {
  const { method, url } = endpoints.Revenue.Claimed;

  return useQuery('revenueClaimed', () => request(method, url));
};
