export const TableDataConfig = [
  {
    id: 1,
    Token_Distribution: "Staking Rewards",
    Percentage: "10%",
    Fundin_coins: "100,000,000",
    Vesting_Monthly: "2%",
  },
  {
    id: 2,
    Token_Distribution: "Marketplace Rewards",
    Percentage: "10%",
    Fundin_coins: "100,000,000",
    Vesting_Monthly: "3%",
  },
  {
    id: 3,
    Token_Distribution: "Marketing",
    Percentage: "5%",
    Fundin_coins: "50,000,000",
    Vesting_Monthly: "5%",
  },
  {
    id: 4,
    Token_Distribution: "Seed Sale",
    Percentage: "5%",
    Fundin_coins: "50,000,000",
    Vesting_Monthly: "4%",
  },
  {
    id: 5,
    Token_Distribution: "Public Sale",
    Percentage: "10%",
    Fundin_coins: "100,000,000",
    Vesting_Monthly: "5%",
  },
  {
    id: 6,
    Token_Distribution: "Advisories",
    Percentage: "5%",
    Fundin_coins: "50,000,000",
    Vesting_Monthly: "2%",
  },
  {
    id: 7,
    Token_Distribution: "Team",
    Percentage: "15%",
    Fundin_coins: "150,000,000",
    Vesting_Monthly: "4%",
  },
  {
    id: 8,
    Token_Distribution: "Exchanges Liquidity",
    Percentage: "20%",
    Fundin_coins: "200,000,000",
    Vesting_Monthly: "3%",
  },
  {
    id: 9,
    Token_Distribution: "Foundation",
    Percentage: "20%",
    Fundin_coins: "200,000,000",
    Vesting_Monthly: "2%",
  },
];
