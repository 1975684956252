import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AppContext } from "../../../../../scripts/context";
import { useForm } from "../../../../Layout/forms/actions";
import { Spinner } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";

import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { useTimeEvent } from "../../../Hooks/useTimeEvent";
import EditEventImage from "./EditEventImage";
function InfoForm(props) {
  // InfoForm component, all Components for submit event page,

  /**
   * use the useTimeEvent custom hook to handle the operations
   * of Events Times
   * author Khaled Mofeed
   */
  const { AllEventCategories } = useTimeEvent();
  /**
   * use the useForm custom hook to handle the operations
   * of forms
   * author Khaled Mofeed
   */
  const {
    form,
    handelSubmitEvent,
    loading,
    setValue,
    setArrayValue,
    setFileValue,
    errors,
  } = useForm({
    cover: "cover",
  });

  /**
   * extract the attributes of form
   * author Khaled Mofeed
   */
  const {
    cover,
    name,
    email,
    description,
    categories,
    location,
    additional_info,
  } = form;
  const [selectedDateStart, setSelectedDateStart] = useState();
  const [selectedDateEnd, setSelectedDateEnd] = useState();
  /**
   * function for formate the date Selected
   * author Khaled Mofeed
   */

  const formatSelectedDate = (date, type) => {
    type === "start_date"
      ? setSelectedDateStart(date)
      : setSelectedDateEnd(date);
    return date
      ? `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`
      : "";
  };
  /**
   * function for formate the Time Selected
   * author Khaled Mofeed
   */
  const formatSelectedTime = (date) => {
    if (date) {
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    }
    return "";
  };

  return (
    <>
      <form className="row" onSubmit={handelSubmitEvent}>
        <Col md={5} xl={5} className="avatarImage">
          <Row>
            <Col md={12}>
              <label for="basic-url">Event Cover</label>
            </Col>

            <Col md={12} className="pt-0 eventImage">
              <EditEventImage
                cover={cover}
                setFileValue={setFileValue}
                errors={errors}
              />
            </Col>
          </Row>
        </Col>

        <Col md={7} xl={7}>
          <Row>
            <Col
              md={12}
              className="justify-content-center align-items-center bg-border-gradiant contact-form-bg"
            >
              <div className="form row position-relative">
                <div className="form-group col-12 col-md-6">
                  <label for="basic-url">Event Name</label>
                  <input
                    type="text"
                    className={`form-control form-control-solid border-0 bg-dark-2 ${
                      errors.name && "is-invalid"
                    }`}
                    name="name"
                    placeholder="Enter your display name"
                    id="id_name"
                    value={name}
                    onChange={setValue}
                  />
                  {errors.name && (
                    <div className="invalid-feedback" id="last_name">
                      {errors.name}
                    </div>
                  )}
                </div>
                <div className="form-group col-12 col-md-6">
                  <label for="basic-url">Email</label>
                  <input
                    type="text"
                    className={`form-control form-control-solid border-0 bg-dark-2 ${
                      errors.email && "is-invalid"
                    }`}
                    name="email"
                    placeholder="Enter Your email"
                    id="id_email2"
                    value={email}
                    onChange={setValue}
                  />
                  {errors.email && (
                    <div className="invalid-feedback" id="last_email">
                      {errors.email}
                    </div>
                  )}
                </div>
                <div className="form-group col-12 col-md-12">
                  <label for="basic-url">Description</label>
                  <textarea
                    name="description"
                    className={`form-control form-control-solid border-0 bg-dark-2 ${
                      errors.description && "is-invalid"
                    }`}
                    rows="3"
                    placeholder="About yourselt in a few words"
                    id="id_description"
                    value={description}
                    onChange={setValue}
                  ></textarea>
                  {errors.description && (
                    <div className="invalid-feedback" id="last_name">
                      {errors.description}
                    </div>
                  )}
                </div>
                <h6 className="nickName mt-2 mb-4">Categories</h6>
                <div className="form-group col-12 col-md-12 mb-2">
                  <div className="row mb-3">
                    <div
                      className={`col-12  ${errors.categories && "is-invalid"}`}
                    >
                      <FormControl
                        sx={{ m: 3 }}
                        component="fieldset"
                        variant="standard"
                        className="m-0 columns_3"
                      >
                        {AllEventCategories?.map((Categories, index) => {
                          return (
                            <FormControlLabel
                              className="CategoriesInput"
                              key={Categories.id}
                              label={Categories.name}
                              sx={{
                                width: "fit-content",
                              }}
                              control={
                                <Checkbox
                                  type="checkbox"
                                  className="CheckboxCategories"
                                  // onChange={setValue}
                                  value={Categories.id}
                                  name={`categories-${index}`}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setArrayValue("categories", [
                                        ...(categories || []),
                                        e.target.value,
                                      ]);
                                    } else {
                                      setArrayValue(
                                        "categories",
                                        categories?.filter(
                                          (ss) => ss !== e.target.value
                                        ) || []
                                      );
                                    }
                                  }}
                                />
                              }
                            />
                          );
                        })}
                      </FormControl>
                    </div>
                    {errors.categories && (
                      <div className="invalid-feedback mt-4" id="last_name">
                        {errors.categories}
                      </div>
                    )}
                  </div>
                </div>
                <div className="nickName mt-2 mb-4 border-bottom"></div>
                <div className="form-group col-md-6">
                  <label className="basic-url">Start Date</label>
                  <DateTimePicker
                    value={selectedDateStart}
                    onChange={(date) => {
                      if (date) {
                        setArrayValue(
                          "start_date",
                          formatSelectedDate(date, "start_date")
                        );
                      }
                    }}
                    name="start_date"
                    className="w-100"
                    calendarClassName="Selectdatetime"
                    minDate={new Date()}
                    maxDate={selectedDateEnd}
                    locale="English"
                    format="yyyy-MM-dd"
                  />
                  <div className={`${errors.start_date && "is-invalid"}`}></div>
                  {errors.start_date && (
                    <div className="invalid-feedback" id="last_name">
                      {errors.start_date}
                    </div>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label className="basic-url">Start Time</label>
                  <DateTimePicker
                    onChange={(date) => {
                      if (date) {
                        setArrayValue("start_time", formatSelectedTime(date));
                      }
                    }}
                    name="start_time"
                    className="w-100"
                    clockClassName="SelectTime"
                    disableCalendar
                    locale="English"
                    format="hh:mm:ss a"
                  />
                  <div className={`${errors.start_time && "is-invalid"}`}></div>
                  {errors.start_time && (
                    <div className="invalid-feedback" id="last_name">
                      {errors.start_time}
                    </div>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label className="basic-url">End Date</label>
                  <DateTimePicker
                    value={selectedDateEnd}
                    onChange={(date) => {
                      if (date) {
                        setArrayValue(
                          "end_date",
                          formatSelectedDate(date, "end_date")
                        );
                      }
                    }}
                    minDate={selectedDateStart}
                    name="end_date"
                    className="w-100"
                    calendarClassName="Selectdatetime"
                    locale="English"
                    format="y-MM-dd"
                  />
                  <div className={`${errors.end_date && "is-invalid"}`}></div>
                  {errors.end_date && (
                    <div className="invalid-feedback" id="last_name">
                      {errors.end_date}
                    </div>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label className="basic-url">End Time</label>
                  <DateTimePicker
                    onChange={(date) => {
                      if (date) {
                        setArrayValue("end_time", formatSelectedTime(date));
                      }
                    }}
                    name="end_time"
                    className="w-100"
                    clockClassName="SelectTime"
                    clearAriaLabel="close"
                    disableCalendar
                    locale="English"
                    format="hh:mm:ss a"
                  />
                  <div className={`${errors.end_time && "is-invalid"}`}></div>
                  {errors.end_time && (
                    <div className="invalid-feedback" id="last_name">
                      {errors.end_time}
                    </div>
                  )}
                </div>
                <div className="form-group col-12 col-md-13">
                  <label className="basic-url">Location</label>
                  <input
                    type="text"
                    className={`form-control form-control-solid border-0 bg-dark-2 ${
                      errors.location && "is-invalid"
                    }`}
                    name="location"
                    id="id_nameSocial"
                    value={location}
                    onChange={setValue}
                  />
                  {errors.location && (
                    <div className="invalid-feedback" id="last_name">
                      {errors.location}
                    </div>
                  )}
                </div>
                <div className="form-group col-12 col-md-12">
                  <label className="basic-url">Addiitional info</label>
                  <textarea
                    type="text"
                    rows="3"
                    placeholder="About yourselt in a few words"
                    className={`form-control form-control-solid border-0 bg-dark-2 ${
                      errors.additional_info && "is-invalid"
                    }`}
                    name="additional_info"
                    id="id_nameSocial1"
                    value={additional_info}
                    onChange={setValue}
                  ></textarea>
                  {errors.additional_info && (
                    <div className="invalid-feedback" id="last_name">
                      {errors.additional_info}
                    </div>
                  )}
                </div>
                <div className="col-12 col-md-12">
                  <button
                    id="info__submitUpdate"
                    className="custom-primary btn btn-primary w-100"
                    type="submit"
                    name="submit"
                    value="Submit"
                    disabled={loading}
                  >
                    Submit
                    {loading && (
                      <Spinner
                        style={{ marginLeft: ".5rem" }}
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </form>
    </>
  );
}

export default InfoForm;
