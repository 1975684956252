import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { ethers } from "ethers";
import {
  getTELVExchangeRate,
  getAddress,
  getBalance,
  getMinMaxAllocation,
  getTotalPresaleTELVAmount,
  getTotalPurchasedTELVAmount,
  getUserTotalPurchasedAmount,
  purchaseTELV,
} from "../pages/presale/presaleContractFunctions";
import { INITIAL_ADDRESS } from "../constants";
import { claculatePurchaseData } from "../Helper/web3";
import { isNil } from "lodash";
import { toast } from "react-hot-toast";

const PresaleContext = createContext(null);

export const PresaleProvider = ({ children }) => {
  const gasFees = 0.00278589; // ! block chain developer shall make a function for it

  // web3 values
  const [address, setAddress] = useState(INITIAL_ADDRESS);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [balance, setBalance] = useState(0);
  const [{ minAllocation, maxAllocation }, setMinMax] = useState({});
  const [totalPresaleTELVAmount, setTotalPresaleTELVAmount] = useState(null);
  const [totalPurchasedTELVAmount, setTotalPurchasedTELVAmount] =
    useState(null);
  const [userTotalPurchasedAmount, setUserTotalPurchasedAmount] =
    useState(null);

  // own values
  const [telv, setTelv] = useState(0);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [maxBuy, setMaxBuy] = useState(null);
  const [affiliate, setAffiliate] = useState("");
  const [minimum, setMinimun] = useState(0);
  const [refetch, setRefetch] = useState(false);

  const valuesGetter = async () => {
    await Promise.all([
      generalFunc(setAddress, getAddress),
      generalFunc(setExchangeRate, getTELVExchangeRate),
      generalFunc(setBalance, getBalance),
      generalFunc(setMinMax, getMinMaxAllocation),
      generalFunc(setTotalPresaleTELVAmount, getTotalPresaleTELVAmount),
      generalFunc(setTotalPurchasedTELVAmount, getTotalPurchasedTELVAmount),
      generalFunc(setUserTotalPurchasedAmount, getUserTotalPurchasedAmount),
    ]);
    getParams();
  };

  // check balance every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setRefetch(!refetch);
    }, 10000);
    return () => clearInterval(interval);
  }, [refetch]);

  useEffect(() => {
    generalFunc(setBalance, getBalance);
  }, [refetch]);

  useEffect(() => {
    setMinimun(
      userTotalPurchasedAmount >= minAllocation
        ? 0
        : minAllocation - userTotalPurchasedAmount
    );
  }, [minAllocation, userTotalPurchasedAmount]);

  useEffect(() => {
    valuesGetter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!maxBuy || !userTotalPurchasedAmount || !minAllocation) return;
    if (telv < minimum) {
      setTelv(minimum);
    }
    if (telv > maxBuy) {
      setTelv(maxBuy);
    }
  }, [telv, maxBuy, minimum, userTotalPurchasedAmount, minAllocation]);

  // to get the affiliate address from the url params
  const getParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const affiliateAddress = urlParams.get("sponsor");
    if (
      affiliateAddress &&
      affiliateAddress.length === 42 &&
      affiliateAddress.substring(0, 2) === "0x"
    ) {
      setAffiliate(affiliateAddress);
    }
  };

  // helper function to get all the data from the contract
  const generalFunc = async (setter, cb) => {
    const data = await cb();
    setter(data);
  };

  const purchase = useCallback(
    async (e) => {
      e.stopPropagation();

      // calculate the total amount of BNB to pay
      if (userTotalPurchasedAmount >= maxAllocation) {
        setError("You have already purchased the maximum amount of TELV");
        return;
      }
      if (telv < minimum) {
        setError(`Amount must be greater than ${minimum} TELV`);
        return;
      }
      if (telv > maxBuy) {
        setError(`Maximum purchase amount is ${maxBuy} TELV`);
        return;
      }
      try {
        let totalPay = telv * 10 ** 16 * exchangeRate;
        totalPay = "0x" + Number(totalPay).toString(16);

        const sponsorAddress = affiliate ? affiliate : INITIAL_ADDRESS;

        await purchaseTELV(
          ethers.utils.parseUnits(telv.toString(), 16),
          sponsorAddress,
          {
            value: totalPay,
          }
        );
        setSuccess("Your purchase is being processed.");
      } catch (error) {
        const errMessage = JSON.parse(JSON.stringify(error)).reason;
        toast.error(errMessage);
      }
    },
    [
      telv,
      exchangeRate,
      affiliate,
      maxBuy,
      minimum,
      userTotalPurchasedAmount,
      maxAllocation,
    ]
  );

  const ableToBuyLogic = () => {
    if (
      isNil(balance) ||
      isNil(minAllocation) ||
      isNil(userTotalPurchasedAmount) ||
      isNil(gasFees) ||
      isNil(exchangeRate) ||
      isNil(maxAllocation)
    )
      return;

    if (balance < gasFees) {
      setError("Low BNB balance");
      return;
    }
    // here to check if the token is sold out
    if (userTotalPurchasedAmount >= maxAllocation) {
      setError("You have already purchased the maximum amount of TELV");
      return;
    }
    const generalMax = Math.min(
      maxAllocation,
      totalPresaleTELVAmount - totalPurchasedTELVAmount
    );
    const userMax = Math.min(
      generalMax,
      maxAllocation - userTotalPurchasedAmount
    );

    const { ableToPurchase, amountToCheck } = claculatePurchaseData({
      balance,
      exchangeRate,
      userMax,
      minAllocation,
      userTotalPurchasedAmount,
      gasFees,
    });

    ableToPurchase >= amountToCheck
      ? setMaxBuy(+ableToPurchase)
      : setError("Low BNB balance, you can not buy TELV");
  };

  useEffect(() => {
    ableToBuyLogic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    balance,
    exchangeRate,
    maxAllocation,
    maxBuy,
    minAllocation,
    totalPresaleTELVAmount,
    totalPurchasedTELVAmount,
    userTotalPurchasedAmount,
    minimum,
  ]);

  const value = useMemo(
    () => ({
      address,
      exchangeRate,
      balance,
      telv,
      error,
      setError,
      maxBuy,
      affiliate,
      setTelv,
      purchase,
      userTotalPurchasedAmount,
      minimum,
      success,
      setSuccess,
      refetch,
    }),
    [
      address,
      exchangeRate,
      balance,
      telv,
      error,
      setError,
      maxBuy,
      affiliate,
      setTelv,
      purchase,
      userTotalPurchasedAmount,
      minimum,
      success,
      setSuccess,
      refetch,
    ]
  );
  return (
    <PresaleContext.Provider value={value}>{children}</PresaleContext.Provider>
  );
};

export const usePresale = () => {
  return useContext(PresaleContext);
};
