export const routes = {
  marketplace: "/marketplace",
  map: "/map",
  news: "/news",
  events: "/events",
  submitevents: "/submit-events",
  token: "/token",
  whitepaper: "/whitepaper",
  home: "",
  sale: "/token/presale",
  vesting: "/vesting",
  proposal: "/proposal",
  revenues: "/revenues",
  profile: "/profile",
  editProfile: "/edit-Profile",
  NftPresale: "/digital-share/sale",
  loginAdmin: "/back-office/login",
  resetPasswordAdmin: "/back-office/reset-password",
  usersManagementAdmin: "/back-office/users-management",
  RolesManagementAdmin: "/back-office/RolesManagement",
  RevenueManagementAdmin: "/back-office/revenue",
  eventManagementAdmin: "/back-office/event-management",
  proposalManagementAdmin: "/back-office/proposal-management",
  profileAdmin: "/back-office/profile",
  land: "/land/:id",
  editLand: "/land/:id/edit",
};
