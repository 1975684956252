import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import LineChart from "./lineChart";
import PolarChart from "./polarChart";
import "./style.css";
/*  Render Components for displaying analytical data through infographics and charts | khaled Mofeed | 29/10/2022 */
function Chart(props) {
  return (
    <Row className="justify-content-center DistributionVesting min-height-100per">
      <Col md={10} xl={9}>
        <div className="text-center py-2rem Release1">
          <img
            src="/img/Chart.svg"
            alt="chart"
            className="chart d-none"
            id="id_imgChart"
          />
          <PolarChart />
        </div>
        <div className="Release">
          <h2 id="id_ReleaseTitle">Release schedule:</h2>
          <img
            src="/img/chart2.svg"
            alt="chart"
            className="chart2 d-none"
            id="id_imgChart2"
          />
          <LineChart className="chart2" />
        </div>
      </Col>
    </Row>
  );
}

export default Chart;
