import React, { SVGProps } from "react";

function EditIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      fill="none"
      viewBox="0 0 35 35"
      {...props}
    >
      <path
        stroke="#777E90"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M24.556 7.423l2.219 1.766a1.06 1.06 0 01-.009 1.551l-1.686 2.018-5.736 6.84a.732.732 0 01-.382.232l-2.975.674a.75.75 0 01-.78-.652v-3.057a.659.659 0 01.168-.396l5.63-6.366 1.932-2.307a1.23 1.23 0 011.62-.303z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#777E90"
        d="M16.528 10.89a1 1 0 000-2v2zm-3.967-1v-1h-.002l.002 1zm-5.288 5.312h1V15.2l-1 .002zm0 7.968l1 .003v-.003h-1zm5.288 5.313l-.002 1h.002v-1zm7.934 0v1h.002l-.002-1zm5.288-5.313h-1v.003l1-.003zm1-5.312a1 1 0 10-2 0h2zm-4.854-8.206a1 1 0 00-1.849.763l1.849-.763zm3.367 4.077a1 1 0 10-.432-1.953l.432 1.953zm-14.056 9.77a1 1 0 100 2v-2zm10.578 2a1 1 0 000-2v2zm-5.29-16.61H12.56v2h3.967v-2zm-3.97 0a6.301 6.301 0 00-6.285 6.315l2-.005a4.301 4.301 0 014.29-4.31l-.004-2zm-6.285 6.313v7.968h2v-7.968h-2zm0 7.966a6.301 6.301 0 006.286 6.315l.005-2a4.301 4.301 0 01-4.291-4.31l-2-.005zm6.288 6.315h7.934v-2H12.56v2zm7.936 0a6.301 6.301 0 006.286-6.315l-2 .005a4.301 4.301 0 01-4.29 4.31l.004 2zm6.286-6.313v-5.312h-2v5.312h2zM20.08 10.415c.274.662.832 1.601 1.665 2.326.85.74 2.069 1.315 3.551.988l-.432-1.953c-.683.15-1.275-.082-1.806-.544a4.539 4.539 0 01-1.13-1.58l-1.848.763zM11.24 25.5h10.578v-2H11.239v2z"
      ></path>
    </svg>
  );
}

export default EditIcon;
