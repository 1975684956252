import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { pxToRem } from '../theme';
import CopyToClipboard from './CopyToClpboard';
import clsx from 'clsx';

const StyledInfoCard = styled('div')(() => ({
  border: '1px solid #343444',
  padding: `${pxToRem(13)} ${pxToRem(26)}`,
  borderRadius: `${pxToRem(16)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: pxToRem(74),
}));

const Title = styled(Typography)(() => ({
  marginRight: '2rem',
}));

const InfoCard = ({ title, value, formatedValue, clipboardCopy, sx = {}, isBio = false }) => {
  return (
    <StyledInfoCard sx={sx}>
      <Title component='p' variant='body4' color='GrayText' className={clsx({ 'mt-2': isBio })}>
        {`${title}:`}
      </Title>
      <Typography component='p' variant='h7' className='d-flex' style={{ lineHeight: isBio ? '40px' :'48px' }}>
        {formatedValue ? formatedValue : value}
        {clipboardCopy && <CopyToClipboard value={value} />}
      </Typography>
    </StyledInfoCard>
  );
};

export default InfoCard;
