import React from 'react';
import './style.css';
import ContainerPage from './components/ContainerPage';
function index() {
  /*  Container for Render Components for displaying User profile page | khaled Mofeed  */
  return (
    <>
      <ContainerPage />
    </>
  );
}

export default index;
