import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ModalBody, Spinner } from "react-bootstrap";
import { useAuth } from "../../../scripts/App/auth";
import SelectMenu from "../../../conmponents/SelectMenu";
/**
 * (Confirm Delete Modal)
 * this Modal use for Confirm the Delete operation of the Role
 * author Khaled Mofeed
 */
export default function ConfirmDeleteModal({
  data,
  setData,
  deleteRow,
  isLoading,
  ...props
}) {
  const { roles_permissions } = useAuth();
  const handleClose = () => setData({ status: false });

  return (
    <Modal
      {...props}
      show={data.status}
      onHide={handleClose}
      aria-labelledby="Role-Modal"
      size="md"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3 className="card-label" id="ID_Please">
            Please select new role for{" "}
            <span className="border-bottom" id="ID_Role1">
              {" "}
              {data.name}{" "}
            </span>{" "}
            Role Users before deleting it?
          </h3>
        </Modal.Title>
      </Modal.Header>
      <ModalBody className="p-17 px-5">
        <div className="row align-items-center mt-3 mb-4">
          <div className="col-md-12 ">
            <SelectMenu
              label="Select New Role"
              handleChange={({ target }) => {
                setData({
                  data: {
                    ...data.data,
                    alternative_role_id: target.value,
                  },
                });
              }}
              options={roles_permissions
                .filter((item) => item.id !== data.data.role_id)
                .map((role) => ({
                  value: role.id,
                  label: role.name,
                }))}
              value={data.data.alternative_role_id}
              sx={{ width: "100%", flex: "1 1 40%" }}
              id="ID_SelectMenu"
            />
          </div>
        </div>
      </ModalBody>
      <Modal.Footer>
        <Button
          disabled={isLoading}
          onClick={handleClose}
          className="btn btn-light-primary"
          id="ID_Cancel"
        >
          Cancel
        </Button>
        <Button
          onClick={deleteRow}
          disabled={isLoading}
          className="btn btn-primary"
          id="ID_Update"
        >
          Update And Delete
          {isLoading && (
            <Spinner
              style={{ marginLeft: ".5rem" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              id="ID_Spinner"
            />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
