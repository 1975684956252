import React from "react";
import { Row, Col } from "react-bootstrap";
import Buy from "./Buy";
import Rocket from "./Rocket";
import Share from "./Share";
import Purchased from "./Purchased";
import Affiliates from "./Affiliates";
import { usePresale } from "../../../../scripts/usePresale";

function Index(props) {
  const { refetch } = usePresale();
  return (
    <Col className="live-presale-max-width mt-5">
      <Row className="justify-content-center ">
        <Col
          xs={12}
          sm={10}
          lg={6}
          className="d-flex flex-column justify-content-between"
        >
          <Rocket {...props} />
          <Share />
        </Col>
        <Col xs={12} sm={10} lg={4}>
          <Buy />
        </Col>
      </Row>
      <Row className="justify-content-center ">
        <Col xs={12} sm={10} lg={5} xl={6}>
          <Purchased refetch={refetch} />
        </Col>
        <Col xs={12} sm={10} lg={5} xl={4}>
          <Affiliates />
        </Col>
      </Row>
    </Col>
  );
}

export default Index;
