import { useAuth } from "../../../scripts/App/auth";
import toast from "react-hot-toast";
import axiosInstance, { request } from "../../../plugins/axios";
import { endpoints } from "../../../../utils/networks";
/**
 * (Custom Hook)
 * this custom hook handles Change avatar image
 * param args
 * returns {{updateImage,user}}
 * author Khaled Mofeed
 */
export const useUploadImage = () => {
  const { user, setState } = useAuth();
  const updateImage = (event) => {
    if (!event.target.files?.[0].type.includes("image"))
      return toast.error("Please upload an image");
    const file = event.target.files?.[0] || "";

    const formData = new FormData();
    formData.append("avatar", file);
    axiosInstance.defaults.headers["Content-Type"] = "multipart/form-data";
    request(
      endpoints.Profile.changeAvatar.method,
      endpoints.Profile.changeAvatar.url,
      formData
    )
      .then(({ data }) => {
        setState({ user: { ...user, avatar: data.avatar } });
        toast.success(data.message);
      })
      .catch((err) => {
        if (err.response.data.errors) {
          toast.error(err.response.data.errors.avatar[0]);
        } else {
          toast.error(err.response.data.message);
        }
      });
  };

  return { updateImage, user };
};
