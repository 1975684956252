import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TIME_TO_GET_DATA } from "../../../constants";
import { endpoints } from "../../../utils/networks";
import schemaForgetPassword from "../../Auth/ForgetPassword/schemaForgetPassword";
import schemaLogin from "../../Auth/Login/schemaLogin";
import schemaResetPassword from "../../Auth/ResetPassword/schemaResetPassword";
import schemaChangesPassword from "../../pages/Profile/conmponents/schemaChangesPassword";
import schemaUpdateInformation from "../../pages/Profile/conmponents/schemaUpdateInformation";
import axiosInstance, { request } from "../../plugins/axios";
import { useAuth } from "../../scripts/App/auth";

/**
 * (Custom Hook)
 * this custom hook handles all operations of the form
 * param args
 * returns {{        form,
    handleForgetPasswordSubmit,
    handleLoginSubmit,
    handelLogin_2FASubmit,
    handleResetPasswordSubmit,
    onChangeRecaptcha,
    handleBackToLogin,
    handelUpdateInfoProfileSubmit,
    handelChangesPasswordSubmit,
    recaptchaRef,
    setForm,
    setValue,
    setLoading,
    loading,
    errors,
    setErrors,
    twoFA,
    setTwoFA,
    handelCancelLogin,}}
 * author Khaled Mofeed
 */
export const useForm = (args = {}) => {
  /**
   * initialize the form
   */
  const [form, setForm] = useState(args);
  /**
   * validator object which contains the error messages author Khaled Mofeed
   */
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const recaptchaRef = React.createRef();
  const { user, setState, setShouldUpdate } = useAuth();
  const [twoFA, setTwoFA] = useState(false);
  const [searchParams] = useSearchParams({
    token: "",
  });

  /**
   * this function is used to handle the setter operations
   * for all form's elements
   *
   * param attribute
   * param value
   * author Khaled Mofeed
   */
  const setValue = (event, field = null) => {
    const name = field ?? event?.target.name;
    const value = event?.target.value;
    try {
      // set form values by adding the existed values
      // in addition to adding the new one
      setForm({ ...form, [name]: value });
    } catch (e) {}
  };
  /**
   * close the 2FA screen
   * param event
   * author Khaled Mofeed
   */
  const handelCancelLogin = async () => {
    setLoading(false);
    setTwoFA(false);
  };
  /**
   * back to login screen
   * param event
   * author Khaled Mofeed
   */
  const handleBackToLogin = (e) => {
    navigate("/back-office/login");
  };
  /**
   * handle the Recaptcha in form
   * param event
   * author Khaled Mofeed
   */
  function onChangeRecaptcha(value) {
    setForm({
      ...form,
      "g-recaptcha-response": value,
    });
  }
  /**
   * submit the Forget Password form
   * param event
   * author Khaled Mofeed
   */
  const handleForgetPasswordSubmit = (e) => {
    e.preventDefault();
    setErrors({
      email: false,
    });
    schemaForgetPassword
      .validate(form, { abortEarly: false })
      .then(() => {
        setLoading(true);
        request(
          endpoints.auth.ForgetPassword.method,
          endpoints.auth.ForgetPassword.url,
          form
        )
          .then((res) => {
            setLoading(false);
            setForm({
              ...form,
              email: "",
            });
            toast.success(res.data.message);
          })
          .catch((error) => {
            setLoading(false);

            if (error?.response?.data?.errors) {
              toast.error("Invalid credentials");
              return;
            }
            toast.error(error.response.data.message);
          });
      })
      .catch((error) => {
        setErrors(
          error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {})
        );
      });
  };
  /**
   * submit the Login form
   * param event
   * author Khaled Mofeed
   */
  const handleLoginSubmit = (event) => {
    event.preventDefault();
    setErrors({ email: false, password: false, "g-recaptcha-response": false });
    schemaLogin
      .validate(form, { abortEarly: false })
      .then(() => {
        setLoading(true);
        request(endpoints.auth.Login.method, endpoints.auth.Login.url, form)
          .then(async ({ data }) => {
            console.log("dTAaaa", data);
            if (!data.token) {
              setLoading(false);
              return setTwoFA(true);
            }
            localStorage.setItem("token", data.token);
            axiosInstance.defaults.headers["Authorization"] =
              data.token.includes("Bearer")
                ? data.token
                : `Bearer ${data.token}`;
            try {
              // if (data.user.two_fa) {
              //   setTwoFA(true);
              const roles_and_permissions = await Promise.all([
                data.user.permissions.includes("view_system_role")
                  ? request(
                      endpoints.Roles.GetRoleList.method,
                      endpoints.Roles.GetRoleList.url,
                      {
                        params: {
                          getAll: 1,
                        },
                      }
                    )
                  : Promise.resolve(null),

                await request(
                  endpoints.Roles.GetAllPermissions.method,
                  endpoints.Roles.GetAllPermissions.url
                ),
              ]);
              setState({
                user: data.user,
                roles_permissions:
                  roles_and_permissions[0]?.data.data.data || [],
                permissions: roles_and_permissions[1].data.data || [],
                loading: false,
              });
              setLoading(false);
            } catch (error) {
              console.log(error);
            }
            if (data.user.two_fa) {
              setTwoFA(true);
            } else {
              setTimeout(() => {
                navigate("/back-office/profile");
              }, TIME_TO_GET_DATA);
              setShouldUpdate(true);
            }
            toast.success("You have successfully logged in");
          })
          .catch((error) => {
            setLoading(false);
            setTwoFA(false);
            window.grecaptcha.reset();
            if (error?.response?.data?.errors) {
              toast.error("Invalid credentials");
              Object.keys(error.response.data.errors).includes(
                "g-recaptcha-response"
              ) && recaptchaRef.current.reset();

              return;
            }
            toast.error(error?.response?.data?.message);
          });
      })
      .catch((error) => {
        setErrors(
          error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {})
        );
      });
  };
  /**
   * submit the Login With 2FA form
   * param event
   * author Khaled Mofeed
   */
  const handelLogin_2FASubmit = (value) => {
    const code = value.replace(/[^0-9]/g, "");
    setCode(code);

    if (code.length === 6) {
      request(endpoints._2FA.Login.method, endpoints._2FA.Login.url, {
        one_time_password: code,
      })
        .then((res) => {
          toast.success(res.data.message);
          setTimeout(() => {
            navigate("/back-office/profile");
          }, TIME_TO_GET_DATA);
          setShouldUpdate(true);
        })
        .catch((error) => {
          setCode("");
          toast.error(error.response.data.message);
        });
    }
  };

  /**
   * submit the Reset Password form
   * param event
   * author Khaled Mofeed
   */
  const handleResetPasswordSubmit = (e) => {
    e.preventDefault();
    setErrors({ email: false, password: false, password_confirmation: false });
    schemaResetPassword
      .validate(form, { abortEarly: false })
      .then(() => {
        setLoading(true);
        request(
          endpoints.auth.ResetPassword.method,
          endpoints.auth.ResetPassword.url +
            `?reset_token=${
              searchParams.get("reset_token") || ""
            }&email=${searchParams.get("email")}`,
          form
        )
          .then((res) => {
            setLoading(false);
            toast.success(res.data.message);
            navigate("/back-office/login");
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error.response.data.message);
          });
      })
      .catch((error) => {
        setErrors(
          error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {})
        );
      });
  };
  /**
   * submit the Update Info Profile form
   * param event
   * author Khaled Mofeed
   */
  const handelUpdateInfoProfileSubmit = (event) => {
    const ERROR_INPUTS_UPDATE_INFO_PROFILE = {
      first_name: false,
      last_name: false,
      mobile: false,
      old_password: false,
      password: false,
      password_confirmation: false,
    };
    event.preventDefault();
    setErrors(ERROR_INPUTS_UPDATE_INFO_PROFILE);

    schemaUpdateInformation
      .validate(form, { abortEarly: false })
      .then(() => {
        setLoading(true);
        request(
          endpoints.Profile.Update.method,
          endpoints.Profile.Update.url,
          form
        )
          // axios
          //   .post("/back-office/profile/update", userInformation)
          .then(({ data }) => {
            setLoading(false);
            toast.success("Your information has been updated");

            setState({
              user: {
                ...user,
                full_name: `${form.first_name} ${form.last_name}`,
                first_name: form.first_name,
                last_name: form.last_name,
                mobile: form.mobile,
              },
            });
            setForm({
              first_name: form.first_name || "",
              last_name: form.last_name || "",
              mobile: form.mobile || "",
            });
          })
          .catch(({ response }) => {
            setErrors(ERROR_INPUTS_UPDATE_INFO_PROFILE);
            setLoading(false);

            if (response.data.errors) {
              setErrors({
                ...errors,
                ...Object.keys(response.data.errors).map((key) => ({
                  [key]: response.data.errors[key][0],
                }))[0],
              });
            }
          });
      })
      .catch((error) => {
        setErrors(ERROR_INPUTS_UPDATE_INFO_PROFILE);

        setErrors(
          error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {})
        );
      });
  };
  /**
   * submit the Update Password Profile form
   * param event
   * author Khaled Mofeed
   */
  const handelChangesPasswordSubmit = (event) => {
    const ERROR_INPUTS = {
      old_password: "",
      password: "",
      password_confirmation: "",
    };
    event.preventDefault();
    setErrors(ERROR_INPUTS);

    schemaChangesPassword
      .validate(form, { abortEarly: false })
      .then(() => {
        setLoading(true);
        // axios
        //   .post("/back-office/profile/change-password", passInformation)

        request(
          endpoints.Profile.changePassword.method,
          endpoints.Profile.changePassword.url,
          form
        )
          .then((response) => {
            setLoading(false);
            toast.success(response?.data?.message);
            setForm({
              old_password: "",
              password: "",
              password_confirmation: "",
            });
          })
          .catch(({ response }) => {
            setErrors(ERROR_INPUTS);
            setLoading(false);

            if (response.data.errors) {
              setErrors({
                ...errors,
                ...Object.keys(response.data.errors).map((key) => ({
                  [key]: response.data.errors[key][0],
                }))[0],
              });
            }
          });
      })
      .catch((error) => {
        setErrors(ERROR_INPUTS);

        setErrors(
          error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {})
        );
      });
  };

  return {
    form,
    handleForgetPasswordSubmit,
    handleLoginSubmit,
    handelLogin_2FASubmit,
    handleResetPasswordSubmit,
    onChangeRecaptcha,
    handleBackToLogin,
    handelUpdateInfoProfileSubmit,
    handelChangesPasswordSubmit,
    recaptchaRef,
    setForm,
    setValue,
    setLoading,
    loading,
    errors,
    setErrors,
    twoFA,
    setTwoFA,
    handelCancelLogin,
    code,
  };
};
