import { useEffect } from "react";
import { HTTP_PAGE_No, HTTP_PER_PAGE } from "../../../../constants";
import { useBallot } from "../../../../scripts/useBallot";
import CardProposal from "./CardProposal";
/**
 * (Proposal Component for view The content of Proposal Page)
 * author Khaled Mofeed
 **/
function Proposal(props) {
  const { getProposalsList, supply } = useBallot();
  const proposalsList = async () => {
    const func = await getProposalsList(HTTP_PAGE_No, HTTP_PER_PAGE);
    return func;
  };
  useEffect(() => {
    proposalsList();
  }, []);

  return (
    <>
      <p className="my-4">
        A proposal is a suggestion to change something in the TELEVERSE or
        ecosystem. Users with Televerse NFT can vote for or against these
        proposals.
      </p>
      <p>
        Each Televerse NFT will be worth one vote, so the more Televerse NFT a
        User has owend, the more they will be able to influence the results of
        the votes.
      </p>
      <div className="container">
        <CardProposal
          proposalsList={proposalsList}
          currentPage={1}
          no={10}
          supply={supply}
        />
      </div>
    </>
  );
}

export default Proposal;
