import React from "react";
import "./style.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Table } from "react-bootstrap";
import { TableDataConfig } from "../../../../configs/configDistributionVesting";

/*  This Component for displaying Distribution and Vesting data by table
*  Defining the data through the config Table Data file
 | khaled Mofeed | 29/10/2022 */

function DistributionVesting(props) {
  const TableData = TableDataConfig;
  return (
    <Row className="justify-content-center DistributionVesting DistributionVestingCenter min-height-100per">
      <Col md={10} xl={9}>
        <h2>Distribution and Vesting:</h2>
        <Table responsive striped variant="dark">
          <thead>
            <tr>
              <th>
                <span className="titletable" id="id_th1_span">
                  Token Distribution
                </span>
              </th>
              <th>
                <span className="titletable" id="id_th2_span">
                  Percentage
                </span>
              </th>
              <th>
                <span className="titletable" id="id_th3_span">
                  Fundin coins
                </span>
              </th>
              <th>
                <span className="titletable" id="id_th4_span">
                  Vesting / Monthly
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {TableData.map((cardTableData) => (
              <tr key={cardTableData.id}>
                <td id="id_td1_span"> {cardTableData.Token_Distribution}</td>
                <td id="id_td2_span">{cardTableData.Percentage}</td>
                <td id="id_td3_span">{cardTableData.Fundin_coins}</td>
                <td id="id_td4_span">{cardTableData.Vesting_Monthly}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

export default DistributionVesting;
