import { useState } from "react";
import toast from "react-hot-toast";
import { PaginationData } from "../../../../configs/configPaginationData";
import { endpoints } from "../../../../utils/networks";
import axiosInstance, { request } from "../../../plugins/axios";
import { useAuth } from "../../../scripts/App/auth";
/**
 * (Custom Hook)
 * this custom hook handles all operations of the users admin page
 * param args
 * returns {{      initAdminState,
    user,
    modalShowEdit,
    paginationData,
    setPaginationData,
    admin,
    setAdmin,
    filter,
    setFilter,
    order,
    setOrder,
    loading,
    setLoading,
    confirmDelete,
    setConfirmDelete,
    idLoading,
    handleEdit,
    handleAdd,
    handleClose,
    handleChangeRowsPerPage,
    handleChangePage,
    getUsers,
    getInitData,
    updateStatus,
    addNewUser,
    updateUser,
    deleteUser,}}
 * author Khaled Mofeed
 */
const initAdminState = {
  status: false,
  edit: false,
  data: {
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    roles: [],
    status: true,
    permissions: [],
  },
};
const initFilterData = {
  status: true,
  data: {
    permissions: "",
    full_name: "",
    email: "",
    roles: [],
    status: "",
  },
};
export const useUser = () => {
  const { user } = useAuth();
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const [paginationData, setPaginationDatas] = useState(PaginationData);
  const setPaginationData = (data) =>
    setPaginationDatas({ ...paginationData, ...data });
  const [filter, setFilter] = useState(initFilterData);
  const [admin, setAdmin] = useState(initAdminState);
  const [order, setOrder] = useState({
    order: "asc",
    orderBy: "name",
  });
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState({
    status: false,
    id: "",
  });
  const [idLoading, setIdLoading] = useState();
  /**
   * this function is used to handle the Editing of user admin
   * author Khaled Mofeed
   */
  function handleEdit(ele) {
    setModalShowEdit(true);
    setAdmin({ status: true, edit: true, data: ele });
  }
  /**
   * this function is used to handle the Add new user admin
   * author Khaled Mofeed
   */
  function handleAdd() {
    setModalShowEdit(true);
    setAdmin({ ...admin, status: true, edit: false });
  }
  /**
   * this function is used to Close modal of Edit aor add new user admin
   * author Khaled Mofeed
   */
  function handleClose() {
    setModalShowEdit(false);
    setAdmin(initAdminState);
  }
  /**
   * this function is used to handle the Change of Rows the table Per Page
   * author Khaled Mofeed
   */
  const handleChangeRowsPerPage = (event) => {
    getUsers({
      name: filter.data.full_name,
      email: filter.data.email,
      roles: filter.data.roles,
      status: filter.data.status,
      permission: filter.data.permissions,
      ...(order.orderBy
        ? {
            sortBy: order.orderBy,
            sortOrder: order.order,
          }
        : {}),
      page: 1,
      perPage: event.target.value,
    });
  };
  /**
   * this function is used to handle the Change of page of table
   * author Khaled Mofeed
   */
  const handleChangePage = (event, newPage) => {
    getUsers({
      name: filter.data.full_name,
      email: filter.data.email,
      roles: filter.data.roles,
      status: filter.data.status,
      permission: filter.data.permissions,
      ...(order.orderBy
        ? {
            sortBy: order.orderBy,
            sortOrder: order.order,
          }
        : {}),
      page: newPage,
      perPage: paginationData.rowsPerPage,
    });
  };
  /**
   * this function is used to Get Users admin from API
   * author Khaled Mofeed
   */
  const getUsers = async (params) => {
    axiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    try {
      const {
        data: { data },
      } = await request(
        endpoints.AdminUsers.list.method,
        endpoints.AdminUsers.list.url,
        { params }
      );

      setPaginationData({
        tableItems: data.data.map((item) => ({
          ...item,
          permissions: item.permissions,
        })),
        pagesCount: data.total,
        rowsPerPage: Number(data.per_page),
        page: data.current_page,
      });
    } catch (error) {
      toast.error(
        error.response.data.message || "Something went wrong, please try again"
      );

      console.log(error);
    }
  };
  /**
   * this function is used to Get Users admin from API when call it
   * author Khaled Mofeed
   */
  const getInitData = async () => {
    await getUsers();
    setLoading(false);
  };
  /**
   * this function is used to Update the status of Users admin
   * author Khaled Mofeed
   */
  function updateStatus(id) {
    setIdLoading(id);
    return request(
      endpoints.AdminUsers.ChangeStatus.method,
      endpoints.AdminUsers.ChangeStatus.url,
      { user_id: id }
    )
      .then((response) => {
        getInitData();
        toast.success(response?.data?.message);
      })
      .catch((erorr) => {
        toast.error(erorr?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
        setIdLoading();
      });
  }
  /**
   * this function is used to Add new Users admin
   * author Khaled Mofeed
   */
  const addNewUser = (data) =>
    user.permissions.includes("add_admin_user")
      ? setPaginationData({ tableItems: [...paginationData.tableItems, data] })
      : toast.error("You dont have permissions to do this action");
  /**
   * this function is used to update Users admin
   * author Khaled Mofeed
   */
  const updateUser = (data) => {
    if (!user.permissions.includes("edit_admin_user"))
      return toast.error("You dont have permissions to do this action");

    const index = paginationData.tableItems.findIndex(
      (item) => item.id === data.id
    );
    setPaginationData({
      tableItems: [
        ...paginationData.tableItems.slice(0, index),
        data,
        ...paginationData.tableItems.slice(index + 1),
      ],
    });
  };
  /**
   * this function is used to delete Users admin
   * author Khaled Mofeed
   */
  const deleteUser = async (user_id) => {
    if (!user.permissions.includes("delete_admin_user"))
      return toast.error("You dont have permissions to do this action");
    try {
      await request(
        endpoints.AdminUsers.DeleteAdmin.method,
        endpoints.AdminUsers.DeleteAdmin.url + `?user_id=${user_id}`
      );

      toast.success("Admin deleted successfully");

      setPaginationData({
        tableItems: paginationData.tableItems.filter(
          (item) => item.id !== user_id
        ),
      });
      setConfirmDelete({ status: false, id: "" });
    } catch (error) {
      toast.error(
        "Error while deleting admin, please try again later or contact support"
      );
      console.log(error);
      setConfirmDelete({ status: false, id: "" });
    }
  };
  return {
    initAdminState,
    user,
    modalShowEdit,
    paginationData,
    setPaginationData,
    admin,
    setAdmin,
    filter,
    setFilter,
    order,
    setOrder,
    loading,
    setLoading,
    confirmDelete,
    setConfirmDelete,
    idLoading,
    handleEdit,
    handleAdd,
    handleClose,
    handleChangeRowsPerPage,
    handleChangePage,
    getUsers,
    getInitData,
    updateStatus,
    addNewUser,
    updateUser,
    deleteUser,
  };
};
