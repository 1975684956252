import React, { useEffect } from 'react';
import './style.css';
import { tableHeadLatesOffers } from '../../../../../configs/tableHeadLatesOffers';
import { Button, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Price from './Price';
import { StyledDataCell, StyledHeadCell, StyledTablePaper, StyledTableRow } from './styled';
import { useActions, useLatestOffers } from '../../../../../hooks/useMarketplace';
import { getTimeUntil, useDebounce } from '../../../../../Helper/Helper';
import { toast } from 'react-hot-toast';
import { EFFECT_TIME } from '../../../../../constants';

const LandLatestOffers = ({ tokenId, owner, needToRefetch, setNeedToRefetch }) => {
  const { data, isLoading, error, refetch } = useLatestOffers(tokenId, 1);
  const {
    isCancelLoading,
    cancelError,
    cancelTxStatus,
    acceptError,
    acceptTxStatus,
    isAcceptLoading,
    cancelTxHash,
    acceptTxHash,
    accept,
    cancel,
  } = useActions(tokenId);
  const latestOffers = data?.data?.data?.offers;

  useEffect(() => {
    if (needToRefetch) {
      refetch();
    }
  }, [needToRefetch, refetch]);

  useEffect(() => {
    let timer;
    if (acceptTxStatus === 'success') {
      toast.success('You accepted the offer successfully');
      timer = setTimeout(() => {
        refetch();
        setNeedToRefetch(!needToRefetch);
      }, EFFECT_TIME);
    }
    return () => {
      if (timer) clearTimeout(timer);
      toast.dismiss();
    };
  }, [acceptTxStatus, setNeedToRefetch]);

  useEffect(() => {
    let timer;
    if (cancelTxStatus === 'success') {
      toast.success('You rejected the offer successfully');
      timer = setTimeout(() => {
        refetch();
        setNeedToRefetch(!needToRefetch);
      }, EFFECT_TIME);
    }
    return () => {
      if (timer) clearTimeout(timer);
      toast.dismiss();
    };
  }, [cancelTxStatus, refetch]);

  useEffect(() => {
    if (cancelError) {
      toast.error(cancelError);
    }
  }, [cancelError]);

  useEffect(() => {
    if (acceptError) {
      toast.error(acceptError);
    }
  }, [acceptError]);

  useEffect(() => {
    if (isCancelLoading && !cancelTxHash) {
      toast.loading('Transaction is pending...');
    }
  }, [isCancelLoading, cancelTxHash]);

  useEffect(() => {
    if (isAcceptLoading && !acceptTxHash) {
      toast.loading('Transaction is pending...');
    }
  }, [acceptTxHash, isAcceptLoading]);

  const handleAccept = (offerer) => () => accept(offerer);
  const handleReject = (offerer) => () => cancel(offerer);

  return (
    <TableContainer component={StyledTablePaper} className="w-100">
      <Table>
        <TableHead>
          <TableRow>
            {tableHeadLatesOffers.map((headCell) => (
              <StyledHeadCell key={headCell.id}>{headCell.label}</StyledHeadCell>
            ))}
            {owner && <StyledHeadCell>Actions</StyledHeadCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {latestOffers?.length !== 0 ? (
            latestOffers?.map((item) => (
              <StyledTableRow
                key={item.uuid}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <StyledDataCell>
                  <div className='userInfo'>
                    <img
                      src={item.offerer.avatar}
                      className='avatarImgOffer'
                      alt={item.offerer.name}
                    />
                    {item.offerer.name}
                  </div>
                </StyledDataCell>
                <StyledDataCell>
                  <Price icon='/img/telv_filled.svg' price={item.price} symbol={''} />
                </StyledDataCell>
                <StyledDataCell>{getTimeUntil(item.end_offer)}</StyledDataCell>
                {owner && (
                  <StyledDataCell className='ActionBtns'>
                    <Button
                      variant='contained'
                      color='success'
                      className='successBTN mr-2'
                      onClick={handleAccept(item.offerer.address)}>
                      Accept
                    </Button>
                    <Button
                      variant='contained'
                      color='error'
                      className='errorBTN'
                      onClick={handleReject(item.offerer.address)}>
                      Reject
                    </Button>
                  </StyledDataCell>
                )}
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0,
                  padding: '40px',
                },
              }}>
              <StyledDataCell className='mx-auto text-center ' colSpan={owner ? 4 : 3}>
                No Offers
              </StyledDataCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LandLatestOffers;
