import { debounce } from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";
import { TableData } from "../../../../configs/configTableData";
import { ASYNC_TIME } from "../../../../constants";
import { endpoints } from "../../../../utils/networks";
import { request } from "../../../plugins/axios";
import { useAuth } from "../../../scripts/App/auth";

/**
 * (Custom Hook)
 * this custom hook handles all operations of the Role page
 * param args
 * returns {{            initRoleState,
    user,
    loading,
    deleteLoading,
    tableData,
    setTableData,
    filter,
    setFilter,
    role,
    setRole,
    deleteItem,
    setDeleteItem,
    order,
    setOrder,
    handleChangeRowsPerPage,
    handleChangePage,
    getRoles,
    changeStatus,
    deleteRow,
    setDeleteLoading,}}
 * author Khaled Mofeed
 */
const initFilterData = {
  status: true,
  data: {
    name: "",
    status: "",
  },
};
const initRoleState = {
  status: false,
  edit: false,
  data: {
    name: "",
    status: true,
    permissions: [],
  },
};
export const useRole = () => {
  const { user, roles_permissions, setState } = useAuth();
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [tableData, setTableDatas] = useState(TableData);
  const setTableData = (data) => setTableDatas({ ...tableData, ...data });
  const [filter, setFilter] = useState(initFilterData);
  const [role, setRole] = useState(initRoleState);
  const [deleteItem, setDeleteItem] = useState({
    status: false,
    name: "",
    data: {
      role_id: "",
      alternative_role_id: "",
    },
  });
  const [order, setOrder] = useState({
    order: "asc",
    orderBy: "name",
  });
  /**
   * this function is used to handle the Change of Rows the table Per Page
   * author Khaled Mofeed
   */
  const handleChangeRowsPerPage = (event) => {
    setTableData({
      rowsPerPage: Number(event.target.value),
      page: 1,
    });

    getRoles({
      name: filter.data.name,
      status: filter.data.status,
      sortBy: order.order,
      sortOrder: order.orderBy,
      page: 1,
      perPage: event.target.value,
    });
  };
  /**
   * this function is used to handle the Change of page of table
   * author Khaled Mofeed
   */
  const handleChangePage = (_, newPage) => {
    getRoles({
      name: filter.data.name,
      status: filter.data.status,
      sortBy: order.order,
      sortOrder: order.orderBy,
      page: newPage,
      perPage: tableData.rowsPerPage,
    });

    setTableData({ page: newPage });
  };
  /**
   * this function is used to Get Roles from API
   * author Khaled Mofeed
   */
  const getRoles = async (params) => {
    try {
      const {
        data: { data },
      } = await request(
        endpoints.Roles.GetRoleList.method,
        endpoints.Roles.GetRoleList.url,
        { params }
      );

      setLoading(false);
      setTableData({
        tableItems: data.data,
        pagesCount: data.total,
        rowsPerPage: data.per_page,
        page: data.current_page,
      });
    } catch (error) {
      setLoading(false);

      toast.error(
        error.response.data.message ||
          "Something went wrong. Please try again later or contact support."
      );
      console.log(error);
    }
  };
  /**
   * this function is used to Change the status of Role
   * author Khaled Mofeed
   */
  const changeStatus = debounce(async (role_id) => {
    if (!user.permissions.includes("edit_system_role"))
      return toast.error("You dont have permissions to do this action");

    try {
      await request(
        endpoints.Roles.ChangeStatus.method,
        endpoints.Roles.ChangeStatus.url,
        { role_id }
      );

      setState({
        roles_permissions: roles_permissions.map((role) => {
          if (role.id === role_id) {
            role.status = role.status === "Active" ? "Inactive" : "Active";
          }

          return role;
        }),
      });
    } catch (error) {
      console.log(error);

      toast.error(
        error.response.data.message ||
          "Something went wrong. Please try again later or contact support."
      );
    }
  }, ASYNC_TIME);
  /**
   * this function is used to Delete the Role
   * author Khaled Mofeed
   */
  const deleteRow = async () => {
    if (!user.permissions.includes("delete_system_role"))
      return toast.error("You dont have permissions to do this action");

    if (!deleteItem.data.alternative_role_id)
      return toast.error("Please select new role");

    setDeleteLoading(true);

    try {
      await request(
        endpoints.Roles.DeleteRole.method,
        endpoints.Roles.DeleteRole.url,
        {
          data: deleteItem.data,
        }
      );

      setTableData({
        tableItems: tableData.tableItems.filter(
          (item) => item.id !== deleteItem.data.role_id
        ),
      });
      setDeleteItem({
        status: false,
        name: "",
        data: {
          role_id: "",
          alternative_role_id: "",
        },
      });
      setState({
        roles_permissions: roles_permissions.filter(
          (item) => item.id !== deleteItem.data.role_id
        ),
      });
      setDeleteLoading(false);

      toast.success("Role deleted successfully");
    } catch (error) {
      setDeleteLoading(false);
      toast.error("Something went wrong when deleting role");
      console.log(error);
    }
  };
  return {
    initRoleState,
    user,
    loading,
    deleteLoading,
    tableData,
    setTableData,
    filter,
    setFilter,
    role,
    setRole,
    deleteItem,
    setDeleteItem,
    order,
    setOrder,
    handleChangeRowsPerPage,
    handleChangePage,
    getRoles,
    changeStatus,
    deleteRow,
    setDeleteLoading,
  };
};
