import { useContext } from "react";
import { AppContext } from "../../../scripts/context";
import { formatDistanceStrict, compareAsc } from "date-fns";
import moment from "moment/moment";
import { HTTP_DAYS, HTTP_IN_ACTIVE } from "../../../constants";
import { LiveState } from "../../../configs/configEvents";
/**
 * (useTimeEvent Custom Hook)
 * this custom hook handles the Time Event
 * param args
 * returns {{TimeEvent,
    AllEvent,
    LiveState,
    AllEventByFillter,
    AllEventCategories,}}
 * author Khaled Mofeed
 */
export const useTimeEvent = () => {
  const { event, eventFillter, eventCategories } = useContext(AppContext);
  const AllEvent = event.event;
  const AllEventByFillter = eventFillter.eventFillter;
  const AllEventCategories = eventCategories.eventCategories;

  const TimeEvent = (StartEvent, EndEvent, start_time) => {
    const Today = moment().format().split("T")[0];
    const EqaulStartDateWithToday = StartEvent === Today;
    const StartEvenTime = compareAsc(new Date(StartEvent), new Date());
    const EndEventTime = compareAsc(new Date(EndEvent), new Date());
    const InActice = StartEvenTime === -1 && EndEventTime === -1;
    const Live = StartEvenTime === -1 && EndEventTime === 1;
    const Later = StartEvenTime === 1 && EndEventTime === 1;

    const conditions = [
      {
        title: "TodayState",
        handler: () => <span className="todaySpan">Today At {start_time}</span>,
        condition: EqaulStartDateWithToday,
      },
      {
        title: "InActiveState",
        handler: () => HTTP_IN_ACTIVE,
        condition: InActice,
      },
      {
        title: "LiveState",
        handler: () => LiveState,
        condition: Live,
      },
      {
        title: "LaterState",
        handler: () => {
          if (
            formatDistanceStrict(new Date(StartEvent), new Date(), {
              unit: HTTP_DAYS,
            }) === "0 days"
          ) {
            return "Tomorow";
          } else {
            return formatDistanceStrict(new Date(StartEvent), new Date(), {
              addSuffix: true,
              unit: HTTP_DAYS,
            });
          }
        },
        condition: Later,
      },
    ];

    const matchingCondition = conditions.find(
      (condition) => condition.condition
    );

    return matchingCondition ? matchingCondition.handler() : null;
  };

  return {
    TimeEvent,
    AllEvent,
    LiveState,
    AllEventByFillter,
    AllEventCategories,
  };
};
