import React, { useState, useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { getAddress, getCommissionRate } from "../../presaleContractFunctions";
import arrOfShare from "../../../../configs/shareTelvAffiliate";
import { copyToClipboard, makeUrl } from "../../../../Helper/Helper";

/*
 * react-share is a library that provides a set of React components for sharing links on social media.
 * @see https://www.npmjs.com/package/react-share
 */

import "./style.css";

function Share() {
  const [address, setAddress] = useState("");
  const [commission, setCommission] = useState(0);

  // a general function to get data from promise and store it in state
  const generalFunc = async (setter, cb) => {
    const data = await cb();
    setter(data);
  };

  const useEffectFunc = async () => {
    await Promise.all([
      generalFunc(setAddress, getAddress),
      generalFunc(setCommission, getCommissionRate),
    ]);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffectFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // those are the links that will be shared and the text that will be added to the link
  const shareUrl = makeUrl({ sponsor: address });
  const title = "Join the Telv private sale";

  // to copy the link to the clipboard
  const copyShareUrlToClipboard = () => {
    copyToClipboard(shareUrl);
  };

  return (
    <Row className="bg-live align-items-center justify-content-center shared-margin shared-space">
      <Col xs={10} className="live-buy-section live-buy-sectionCard">
        <Row className="first-row-in-share">
          <Col md={5}>
            <h2>
              Share <span className="min-telv">TELV</span>
            </h2>
            <p>
              <span className="max-telv">TELV </span>private <br />
              sale link
            </p>
          </Col>
          <Col md={7}>
            <h2>
              & Earn <span className="purple-span">{commission}%</span>
            </h2>
            <p>
              of each affiliate’s <br />
              purchase transact.
            </p>
          </Col>
        </Row>

        <div className="second-row-in-share">
          <div className="share-link">
            <p>{shareUrl}</p>
          </div>
          <div className="share-btns">
            {arrOfShare(shareUrl, title).map(
              ({ name, icon, onClick }, index) => {
                return (
                  <Image
                    key={index + name}
                    src={icon}
                    alt={name}
                    onClick={onClick}
                    className="cursor-pointer"
                  />
                );
              }
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Share;
