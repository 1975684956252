import React from "react";
import { IconButton } from "@mui/material";
import { toast } from "react-hot-toast";
import ClipBoard from "react-copy-to-clipboard";
import CopyIcon from "../../../../assets/copyIcon.png";

const CopyToClipboard = ({ value }) => {
  return (
    <ClipBoard
      className="IconButtonCopy"
      text={value}
      options={{
        format: "text/plain",
      }}
      onCopy={() => {
        toast.success("Copied to clipboard!");
      }}
    >
      <IconButton className="IconButtonCopy">
        <img src={CopyIcon} alt="CopyIcon" className="CopyIconOwner" />
      </IconButton>
    </ClipBoard>
  );
};

export default CopyToClipboard;
