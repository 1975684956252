
function Statistics(props) {
    /**
   * Revenue statistic section component
   * author Mahmoud Skaik
   */
  const {statisticsData} = props;
  return (
    <div className="container-fluid">
      <h3 className="mb-3">Contract Statistics</h3>
      <div className="header-body">
        <div className="row">
          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">Total Income</h5>
                    <span className="h2 font-weight-bold mb-0">{statisticsData.totalIncome ?? 0}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">Total Claimed</h5>
                    <span className="h2 font-weight-bold mb-0">{statisticsData.totalClaimed ?? 0}</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">Channels Count</h5>
                    <span className="h2 font-weight-bold mb-0">{statisticsData.channelsCounter ?? 0}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">Revenues Count</h5>
                    <span className="h2 font-weight-bold mb-0">{statisticsData.revenuesCounter ?? 0}</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}
export default Statistics;
