/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import "./style.css";

function Claim({ income, claimed, claim }) {
  return (
    <Col md={12} className=" d-flex flex-column claim-box revenue-bg">
      <h1>READY TO CLAIM</h1>
      <hr />
      <div className="d-flex justify-content-between py-2">
        <span className="customeLable">Total Income</span>
        <span>{income ?? 0} TELV</span>
      </div>
      <hr />
      <div className="d-flex justify-content-between py-2">
        <span className="customeLable">Total Claimed</span>
        <span>{claimed ?? 0} TELV</span>
      </div>
      <div className="revenue-amount mt-4">
        <p>Remaining Amount</p>
        <p>{(income - claimed || 0).toFixed(2)} TELV</p>
      </div>
      <div className="mt-3">
        <span
          disabled={!!(income - claimed)}
          onClick={claim}
          className="presale-submit-btn"
        >
          Claim
        </span>
      </div>
    </Col>
  );
}

export default Claim;
