/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import Copy from "../../../../assets/copy-coins.svg";
import usePurchase from "../../../../scripts/usePresale";
import { copyToClipboard } from "../../../../Helper/Helper";
import toast from "react-hot-toast";
import "./style.css";
import { usePresale } from "../../../../scripts/usePresale";
import { EFFECT_TIME } from "../../../../constants";
import { AppContext } from "../../../../scripts/context";

function Buy() {
  const {
    userData,
    state: { user = {}, getShortenAddress } = {},
    disconnect,
  } = useContext(AppContext) || {};
  function copyAdressToClipboard() {
    copyToClipboard(user.address);
  }
  const {
    address,
    exchangeRate,
    balance,
    telv,
    error,
    setError,
    maxBuy,
    affiliate,
    setTelv,
    purchase,
    userTotalPurchasedAmount,
    minimum,
    success,
    setSuccess,
  } = usePresale();

  // to get my address
  const bnbAdressShort = address.slice(0, 9) + "..." + address.slice(-4);
  const affiliateShort = affiliate.slice(0, 9) + "..." + affiliate.slice(-4);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError("");
    }
    return () => {
      setTimeout(() => {
        toast.dismiss();
      }, EFFECT_TIME);
    };
  }, [error]);

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess("");
    }
    return () => {
      setTimeout(() => {
        toast.dismiss();
      }, EFFECT_TIME);
    };
  }, [success]);

  const purchaseHandler = async (e) => {
    await purchase(e);
  };

  return (
    <Row className="bg-live align-items-center justify-content-center shared-margin shared-space">
      <Col xs={10} className="live-buy-section ">
        <h1>Buy $TELV</h1>
        <div className="bnb-address d-flex justify-content-between align-items-center">
          <p>{Number(balance).toFixed(4)} BNB</p>
          <div>
            <span className="mr-2">{getShortenAddress(user.address)}</span>
            <img
              onClick={copyAdressToClipboard}
              src={Copy}
              alt="copy"
              className="cursor-pointer"
            />
          </div>
        </div>
        <div className="presal-amount">
          <h6 className="d-flex align-self-start">
            Amount {"\u00A0"}
            <span className="minAllo-span">
              ({userTotalPurchasedAmount} TELV)
            </span>
          </h6>
          <div className="d-flex justify-content-between align-items-center pb-2">
            <input
              type="number"
              value={telv || 0}
              onChange={(e) => setTelv(e.target.value)}
              min={0}
              max={maxBuy || 0}
              placeholder={"Min is: " + minimum}
              lang="en"
              pattern="[0-9]*[.,]?[0-9]+"
              className="setNFTHandlerInput"
            />
            <span
              onClick={() => {
                setTelv(maxBuy);
              }}
            >
              MAX
            </span>
          </div>
          <hr />
        </div>
        <div className="fees-data">
          <div>
            <h6>Exchange Rate</h6>
            <h2>{exchangeRate / 100} BNB/TELV</h2>
          </div>
          <div>
            <h6>Deducted Amount</h6>
            <h2>{((exchangeRate * telv) / 100).toFixed(3)} BNB</h2>
          </div>
          <div>
            <h6>Sponsor Address</h6>
            <h2>{affiliate ? affiliateShort : "N/A"}</h2>
          </div>
        </div>
        <div class="mt-3">
          <span onClick={purchaseHandler} className="presale-submit-btn">
            Submit
          </span>
        </div>
      </Col>
    </Row>
  );
}

export default Buy;
