import React, { useRef, useEffect, useContext } from 'react';
import * as d3 from 'd3';

import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../../../../scripts/context';
import {
  config_Parents_Sections,
  config_Title_Sections,
} from '../../../../../configs/map/config_Parents_Sections';
import { GetLocalStorag, SaveLocalStorag } from '../../../../../Helper/Helper';
function ContainerMap(IdSelected) {
  const {
    Nft_A_Section,
    Nft_B_Section,
    Nft_C_Section,
    Nft_D_Section,
    Nft_E_Section,
    Nft_F_Section,
    Nft_G_Section,
  } = useContext(AppContext);
  const mapRef = useRef(null);
  const navigate = useNavigate();
  // function used for Handle The Click Event  || Khaled Mofed
  const handleNavigateToLandProfile = (uuid) => {
    navigate(`/land/${uuid}`);
  };
  useEffect(() => {
    if (!Nft_A_Section || !Nft_B_Section || !Nft_C_Section || !Nft_D_Section || !Nft_E_Section || !Nft_F_Section || !Nft_G_Section) return
    const tooltip = d3
      .select('#map')
      .append('div')
      .attr('class', 'my-tooltip ')
      .style('opacity', 0);

    /** The formula for measuring the area of ​​a hexagon || author Khaled Mofeed */
    const _s32 = Math.sqrt(3) / 2;
    /** height of container Map || author Khaled Mofeed */
    const height = 600;
    /** Container Of Map || author Khaled Mofeed */
    const svgContainer = d3
      .select(mapRef.current)
      .append('svg')
      .attr('class', 'border svgStyle')
      .attr('width', '100%')
      .attr('height', height)
      .append('g');
    /** Add Zoom Effect To Map || author Khaled Mofeed */
    const zoom = d3.zoom().scaleExtent([1, 7]).on('zoom', handleZoom);
    svgContainer.call(zoom);
    /** Handle Nft image in hexagone || khaled Mofed */
    const defs = d3.select('svg').append('defs');
    /** Handle Zoom Map || khaled Mofed */
    function handleZoom(e) {
      d3.selectAll('path').attr('transform', e.transform);
    }

    //**************************************************
    //******* Parents_Sections  || Khaled Mofed ********
    //**************************************************/
    config_Parents_Sections?.map((Parents_Sections, index) => {
      const hexagon1Parent8 = svgContainer
        .selectAll('path.area')
        .data([generateHexagon(...(Parents_Sections?.location?.split(',').map(Number) || []))])
        .enter()
        .append('path')
        .style('stroke', `${Parents_Sections?.stroke}`)
        .style('fill', `${Parents_Sections?.fill || '#674291'}`)
        .attr('d', d3.line());
    });
    //****************************************************
    //***** Childs_Sections A,B,C,D,E,F,G || Khaled Mofeed
    //****************************************************/
    config_Title_Sections?.map((Title_Sections, index) => {
      /** get the parents and sub Parents of child for map || khaled Mofed */
      const _SectionMap_Parent = Title_Sections?.filter((obj) => {
        const firstDigit = obj.locationMap.split(',')[0].trim();
        return firstDigit === '100' || firstDigit === '33';
      });
      /** get the Child Without parents and sub Parents for map || khaled Mofed */
      const _SectionMap = Title_Sections?.filter((obj) => {
        const firstDigit = obj.locationMap.split(',')[0].trim();
        return !(firstDigit === '100' || firstDigit === '33');
      });
      /** render the data of child by sections || khaled Mofed */
      const _SectionData = _SectionMap.map((obj, index) => {
        const _Nft_Section = Title_Sections[0].section;
        /** A variable used to check for the type of a session || khaled Mofed */
        const Any_Nft_Section =
          (_Nft_Section === 'A' && Nft_A_Section) ||
          (_Nft_Section === 'B' && Nft_B_Section) ||
          (_Nft_Section === 'C' && Nft_C_Section) ||
          (_Nft_Section === 'D' && Nft_D_Section) ||
          (_Nft_Section === 'E' && Nft_E_Section) ||
          (_Nft_Section === 'F' && Nft_F_Section) ||
          (_Nft_Section === 'G' && Nft_G_Section);
        const matchingUser = Any_Nft_Section[index];
        if (matchingUser) {
          return {
            ...obj,
            ...matchingUser,
          };
        }
        return obj;
      });
      /** render the data of Parent by sections || khaled Mofed */
      _SectionMap_Parent.map((_sectionParent, index) => {
        const hexagon_Parent = svgContainer
          .selectAll('path.area')
          .data([generateHexagon(...(_sectionParent?.locationMap?.split(',').map(Number) || []))])
          .enter()
          .append('path')
          .style('stroke', `${_sectionParent?.stroke}`)
          .style('fill', `${_sectionParent?.fill || '#674291'}`)
          .attr('d', d3.line());
      });
      /** render the data of NFT || khaled Mofed */

      _SectionData?.map((_section, index) => {
        /** append the image Nft by Svg if it exists|| khaled Mofed */
        defs
          .append('svg:pattern')
          .attr('id', `${_section?.image}`)
          .attr('width', 25)
          .attr('height', 23)
          .append('svg:image')
          .attr('xlink:href', `${_section?.image}`)
          .attr('width', 25)
          .attr('height', 23)
          .attr('x', '-2')
          .attr('y', '-2');
        const isStrock = _section?.stroke;
        const isFill = _section?.fill;
        const unClick = _section?.unClick;
        const haveImage = _section?.image;
        const selectedNft = IdSelected === _section?.uuid;
        const hexagon_Child = svgContainer
          .selectAll('path.area')
          .data([generateHexagon(...(_section?.locationMap?.split(',').map(Number) || []))])
          .enter()
          .append('path')
          .attr(
            'class',
            !isStrock && !isFill
              ? haveImage
                ? 'HexaWithImage'
                : 'hexagon'
              : haveImage
              ? 'HexaWithImage'
              : '',
          )
          .style('stroke', `${_section?.stroke}`)
          .style(
            'fill',
            haveImage
              ? function (d) {
                  return `url(#${_section?.image})`;
                }
              : `${_section?.fill || '#674291'}`,
          )
          .attr('data-id', _section?.uuid)
          .on('click', function () {
            if (!unClick) {
              // Remove IdSelected class from all hexagons || khaled Mofed */
              d3.selectAll('.hexagon, .HexaWithImage').classed('IdSelected', false);
              // Add IdSelected class to the selected hexagon || khaled Mofed */
              d3.select(this).classed('IdSelected', true);
              // Save the ID of the selected hexagon in localStorage || khaled Mofed */
              SaveLocalStorag('selectedHexagonId', _section?.uuid);
              handleNavigateToLandProfile(_section?.uuid);
            }
          })
          .on('mouseover', function (event) {
            if (!unClick && _section.uuid) {
              d3.select(this).style('cursor', 'pointer');

              var tooltipWidth = 280;
              var tooltipHeight = 120;
              var containerWidth = d3.select('.marketplace-list').node().getBoundingClientRect().width; 
              var containerHeight = d3.select('.marketplace-list').node().getBoundingClientRect().height;
              var mouseX = event.pageX;
              var mouseY = event.pageY;
              var containerLeft = d3.select('.marketplace-list').node().getBoundingClientRect().left; 
              var containerTop = d3.select('.marketplace-list').node().getBoundingClientRect().top; 
          
              var tooltipLeft = mouseX - containerLeft + 20;
              if (mouseX - containerLeft + tooltipWidth > containerWidth) {
                tooltipLeft = mouseX - containerLeft - tooltipWidth - 20;
              }
          
              var tooltipTop = mouseY - containerTop - 28;
              if (tooltipTop + tooltipHeight > containerTop + containerHeight) {
                tooltipTop = containerTop + containerHeight - tooltipHeight;
              }
          
              

              tooltip.transition().duration(200).style('opacity', 0.9);
              tooltip
                .html(
                  `<div >
                  <div class='section'>
                    <div class='top'>
                      <span class='name'>${_section.name}</span>
                      <div class='coordinates' style='background-color: rgb(55, 51, 61);'>
                      <img src="/img/pin.svg" alt='coordinates' >
                      ${_section.location}
                      </div>
                    </div>
                  </div>
                  <div class='owner ${_section.is_show_buy ? 'mb-3' : 'mb-0'}'>
                    <p class='head'>Owner</p>
                    <span >
                      <img
                      src=${_section.seller.avatar}
                      class='avatarImgOffer'
                      alt={item.offerer.name}
                    />
                      <span class='name'>${_section.seller.name}</span>
                    </span>
                  </div>
                  <div class='${_section.is_show_buy ? 'd-block' : 'd-none'} owner'>
                  <p class='head d-block'>Price</p>
                  <span class='text-sm'>Avillable for sale</span>

                  <div class='d-flex align-items-center mt-1'>
                  <img src="/img/telv_filled.svg" alt='telv' width='18' height='18' class='mmr-2' />
                  <span class='name'> ${_section.price}</span>
                  </div>
                  </div>

                </div>`,
                )
                .style('left', tooltipLeft + 'px')
                .style('top', tooltipTop + 'px')
                .style('margin-left', 0)
                .style('margin-top', 0);

            }
          })
          .on('mouseout', function (event) {
            if (!unClick) {
              d3.select(this).style('cursor', 'default');
              // Hide the tooltip on mouseout
              tooltip.transition().duration(500).style('opacity', 0);
            }
          })
          .attr('d', d3.line());
      });
    });

    const selectedHexagonId = GetLocalStorag('selectedHexagonId');
    // Apply the IdSelected class to the selected hexagon || khaled Mofed */
    if (selectedHexagonId) {
      svgContainer.select(`path[data-id="${selectedHexagonId}"]`).classed('IdSelected', true);
    }
    // function used for Generate Hexagon Shape  || Khaled Mofed
    function generateHexagon(size, xDiff, yDiff) {
      return [
        [size + xDiff, 0 + yDiff], // Center Right
        [size / 2 + xDiff, size * _s32 + yDiff], // Bottom Right
        [-size / 2 + xDiff, size * _s32 + yDiff], // Bottom Left
        [-size + xDiff, 0 + yDiff], // Center Left
        [-size / 2 + xDiff, -size * _s32 + yDiff], // Top left
        [size / 2 + xDiff, -size * _s32 + yDiff], // Top Right
      ];
    }

    return () => {
      svgContainer.on('click', null);
      // remove all the tooltips on unmount
      tooltip.remove();
    };
  }, [ Nft_A_Section, Nft_B_Section, Nft_C_Section, Nft_D_Section, Nft_E_Section, Nft_F_Section, Nft_G_Section]);

  return (
    <div className='col-12 relative'>
      <div className='MapContainer ' id='map'>
        <svg ref={mapRef} width='100%' height='100%'>
          <path className='hexagon' />
        </svg>
      </div>
    </div>
  );
}

export default ContainerMap;
