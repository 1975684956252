import React from "react";
import "./style.css";
/* displaying Team data in Down of XL screen | khaled Mofeed | 29/10/2022 */
function Testimonial({ cardTeamSlider }) {
  return (
    <div className="align-items-center itemslide">
      <img
        className="mx-auto"
        src={cardTeamSlider.authorImage}
        alt={cardTeamSlider.name}
        id="id_imgMemTeamSlider"
      />
      <div className="text-of-slider">
        <h2 id="id_nameMemTeamSlider">{cardTeamSlider.name}</h2>
        <span id="id_jobMemTeamSlider">{cardTeamSlider.job}</span>

        <p id="id_discriptionMemTeamSlider">
          {cardTeamSlider.firstcontent.slice(0, 85)} ...
        </p>

        <ul className="social-ul">
          <li>
            <a
              href={cardTeamSlider.facebook}
              rel="noreferrer"
              target="_blank"
              className="linkedin-team"
              id="id_facebookMemTeamSlider"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
          </li>
          <li>
            <a
              href={cardTeamSlider.twitter}
              rel="noreferrer"
              target="_blank"
              className="linkedin-team"
              id="id_twitterMemTeamSlider"
            >
              <i className="fab fa-twitter"></i>
            </a>
          </li>
          <li>
            <a
              href={cardTeamSlider.instagram}
              rel="noreferrer"
              target="_blank"
              className="linkedin-team"
              id="id_instagramMemTeamSlider"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Testimonial;
