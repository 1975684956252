import React from 'react';
import HeroImg from '../../../../assets/home/landing-hero-Image.png';
import { Col, Image, Row } from 'react-bootstrap';
import styled from "styled-components";
import styles from "./style.module.css";

function Hero() {
  return (
    <Row className="justify-content-center">
    <Col md={10} className={styles.flexMdColumn}>
      <Row className="min-height-100 align-items-center">
        <Col className="hero-text">
          <Image className={styles.heroSmImage} src={HeroImg} alt="hero" />
          <HeroText >
            Beyond Reality....
            <br />
            Another one!
          </HeroText>
          <DiscoverBtn >Discover</DiscoverBtn>
        </Col>
      </Row>
    </Col>
  </Row>
  );
}

const HeroText = styled.span`
font-weight: 700;
font-size: 90px;
letter-spacing: 0.015em;
margin-bottom: 31px;

@media (max-width: 1050px) {
    font-size: 70px;
  }

  @meida (max-width: 900px) {
    font-size: 60px;
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
    font-size: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
  }
`;

const DiscoverBtn = styled.a`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 24px;
gap: 12px;
width: 131px;
border: 2px solid #464255;
border-radius: 100px;
color: #fcfcfd;
font-weight: 500;
text-decoration: none;

@media (max-width: 768px) {
  width: 100%;
  border: none;
  background-color: #9f44f7;
  color: #fcfcfd;
  width: 100%;
  max-width: 400px;
}
&:hover {
  color: aliceblue;
  cursor: pointer;
  background-color: #9f44f7;
}
`;


export default Hero;
