import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import VerificationInput from "react-verification-input";
import { endpoints } from "../../../../../utils/networks";
import { request } from "../../../../plugins/axios";
import { useAuth } from "../../../../scripts/App/auth";
/**
 * this Second Step in Google Authentication if the 2FA is Activated
 * inActivated Google authenticator.
 * author Khaled Mofeed
 */
function DisableGoogleAuthenticatorStep(props) {
  const { setShouldUpdate } = useAuth();
  const [code, setCode] = useState("");
  function handlePrevStep() {
    props.setIsStep5(false);
    props.setIsStep1(true);
  }

  const handleChange = (value) => {
    const code = value.replace(/[^0-9]/g, "");
    setCode(code);
    if (code.length === 6) {
      request(endpoints._2FA.Disable.method, endpoints._2FA.Disable.url, {
        one_time_password: code,
      })
        .then((res) => {
          setShouldUpdate(true);
          props.onHide();
          toast.success(res.data.message);
        })
        .catch((error) => {
          setCode("");
          toast.error(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    // document.getElementById("liStep5").classList.add("done");
    props.liStep5.current.classList.add("done");
  }, []);
  return (
    <>
      <div className="content clearfix">
        <div>
          <h3
            className="text-center title-section"
            id="id_Disable_Google_authenticator"
          >
            Disable Google authenticator
          </h3>
          <p className="mb-3" id="id_Google_authenticator_code_lable">
            Google Authenticator Code
          </p>
        </div>
        <div className="form-group row row-sm justify-content-md-center">
          <div className="col-lg-9 col-xl-7 VerificationInput">
            <VerificationInput
              id="id_VerificationInput2"
              length={6}
              onChange={handleChange}
              autoFocus
              pattern="[0-9]*"
              value={code}
              inputProps={{
                autoFocus: true,
              }}
            />
          </div>
        </div>
      </div>
      <div className="actions clearfix">
        <ul role="menu" aria-label="Pagination">
          <li>
            <Link onClick={handlePrevStep} id="id_Previous_stepPrevious">
              Previous
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default DisableGoogleAuthenticatorStep;
