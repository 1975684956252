import { useEffect } from "react";
import { Link } from "react-router-dom";
/**
 * this Second Step in Google Authentication
 * Scan the QR code in the Google Authenticator app.
 * author Khaled Mofeed
 */
function ScanQRCodeStep(props) {
  function handleNextStep() {
    props.setIsStep2(false);
    props.setIsStep3(true);
  }
  function handlePrevStep() {
    props.setIsStep2(false);
    props.setIsStep1(true);
  }
  useEffect(() => {
    props.liStep2.current.classList.add("done");
  }, []);
  return (
    <>
      <div className="content clearfix">
        <div>
          <h3 className="text-center title-section" id="id_ScanQr_lable">
            Scan QR Code
          </h3>
          <p className="mb-3" id="id_ScanQr_sublable">
            Scan this QR code in the Google Authenticator app.
          </p>
        </div>
        <div className="row row-sm">
          <div className="css-weqpav">
            <div className="css-1xfmg0p">
              <img
                id="id_ScanQr_img"
                src={props.authenticationInfo.google2fa_qrcode}
                className="img-code"
                alt="img-code"
              />
            </div>
            <div className="css-wiulhc"></div>
            <div className="css-1t5xnih">
              <div className="css-vurnku" id="id_ScanQr_text">
                If you are unable to scan the QR code, please enter this code
                manually into the app.
              </div>
              <div className="css-1gv6rnn">
                <div className="css-ryy4eq" id="id_google2fa_secret_key">
                  {props.authenticationInfo.google2fa_code}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="actions clearfix">
        <ul role="menu" aria-label="Pagination">
          <li>
            <Link onClick={handlePrevStep} id="id_Previous_step2">
              Previous
            </Link>
          </li>
          <li>
            <Link onClick={handleNextStep} id="id_Next_step2">
              Next
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default ScanQRCodeStep;
