import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { calculateTimeLeft } from "../../../Helper/Helper";
import { useTimer } from "../../../hooks/useTimer";
import { routes } from "../../../configs/configNavLink";


function JoinTELV(props) {
  /**
   * use the useTimer custom hook to handle the Timer
   * author Khaled Mofeed
   */

  const navigate = useNavigate();
  const {
    dateDifference,
    timeToCheck,
    getTimeToBeLive,
    setTimeLeft,
    timerComponents,
  } = useTimer();

  useEffect(() => {
    getTimeToBeLive();
  }, [getTimeToBeLive]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(dateDifference));
    }, timeToCheck);
    return () => clearTimeout(timer);
  });
  return (
    <Row className="justify-content-center JoinTELV">
      <Col md={10} xl={9}>
        <Row className="justify-content-center align-items-center min-height-100per">
          <Col md={10} xl={9}>
            <div className="title-div">
              <h1 id="id_Join_h1">Join TELV Private Sale</h1>
              <h2 id="id_Join_h2">IS LIVE </h2>
            </div>
            <Row className="noneSecond">
              {timerComponents.length ? (
                timerComponents
              ) : (
                <span id="id_TimeUp">Time's up!</span>
              )}
            </Row>
            <Row className="">
              <Col>
                <Button
                  variant="primary"
                  className="custom-primary"
                  id="id_JoinBtn"
                  onClick={() => {
                    navigate(routes.sale);
                  }}
                >
                  Join
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default JoinTELV;
