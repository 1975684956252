/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { AppContext } from "../../../scripts/context";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { Nav } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { copyToClipboard } from "../../../Helper/Helper";

/*  This Component for Connect Wallet button | khaled Mofeed | 5/12/2022 */
export default function NavbarConnect() {
  /*  imported from context AppContext | khaled Mofeed | 5/12/2022 */
  const { userData, isToken } = useContext(AppContext) || {};
  /* This function for copy Adress To Clipboard | khaled Mofeed | 5/12/2022 */
  function copyAdressToClipboard() {
    copyToClipboard(userData?.wallet_address);
  }
  return (
    <>
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openAccountModal,
          openChainModal,
          openConnectModal,
          authenticationStatus,
          mounted,
        }) => {
          const ready = mounted && authenticationStatus !== "loading";
          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === "authenticated");
          return (
            <div
              {...(!ready && {
                "aria-hidden": true,
                style: {
                  opacity: 0,
                  pointerEvents: "none",
                  userSelect: "none",
                },
              })}
            >
              {(() => {
                if (!connected) {
                  return (
                    <Button
                      onClick={openConnectModal}
                      id="connect-wallet"
                      variant="outline-secondary"
                      type="button"
                      className="custom-secondary ms-3 btn px-5"
                    >
                      Connect Wallet
                    </Button>
                  );
                }
                if (chain.unsupported) {
                  return (
                    <Button
                      onClick={openChainModal}
                      id="connect-wallet"
                      variant="outline-secondary"
                      type="button"
                      className="custom-secondary ms-3 btn px-5 WrongNetwork "
                    >
                      Wrong network
                    </Button>
                  );
                }
                if (connected && !isToken) {
                  return (
                    <>
                      <Button
                        onClick={openAccountModal}
                        id="connect-wallet"
                        variant="outline-secondary"
                        type="button"
                        className="custom-secondary ms-3 btn px-5"
                      >
                        <img
                          src="/img/disconnectWhite.svg"
                          alt="disconnect"
                          className="disconnectImg"
                        />{" "}
                        Disconnect
                      </Button>
                    </>
                  );
                }

                return (
                  <>
                    <div className="addres-info">
                      <div className="wallet-address-button-container">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="outline-secondary"
                            className="custom-secondary nav-btn"
                          >
                            <img
                              src={userData?.avatar}
                              className="avatar"
                              alt="avatar"
                            />
                            <span className="d-none d-lg-block">
                              {account.displayName}
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Nav key="ProfileData">
                              <div className="ProfileData">
                                <h5 className="nickName">{userData?.name}</h5>
                                <p className="addressLable">
                                  {userData?.wallet_address &&
                                    userData?.wallet_address.substring(0, 10) +
                                      "..." +
                                      userData?.wallet_address.substring(
                                        userData?.wallet_address.length - 4
                                      )}
                                  <Link className="copyBtn">
                                    <img
                                      src="/img/copy.svg"
                                      onClick={copyAdressToClipboard}
                                      alt="copy"
                                    />
                                  </Link>
                                </p>
                              </div>
                            </Nav>
                            <Nav
                              key="ProfileData"
                              onClick={openAccountModal}
                              className="pointer"
                            >
                              <div className="ProfileDataBalance">
                                <img
                                  src="/img/networkIcon.png"
                                  alt="copy"
                                  className="networkIcon"
                                />
                                <div className="ProfileDataBalanceText">
                                  <p className="LableName">Balance</p>
                                  <p className="LableBalance">
                                    {account.displayBalance}
                                  </p>
                                </div>
                              </div>
                            </Nav>
                            <Nav
                              key="Transactions"
                              onClick={openChainModal}
                              className="pointer"
                            >
                              <div className="ProfileDataBalance border-custome">
                                {chain.iconUrl && (
                                  <img
                                    alt={chain.name ?? "Chain icon"}
                                    src={chain.iconUrl}
                                    className="networkIcon"
                                  />
                                )}
                                <div className="ProfileDataBalanceText">
                                  <p className="LableName">Network</p>
                                  <p className="LableBalance">{chain.name}</p>
                                </div>
                              </div>
                            </Nav>
                            <Nav key="Profile">
                              <NavLink
                                to="/profile"
                                className="nav-link menuBottom"
                              >
                                <img
                                  src="/img/profileSvg.svg"
                                  alt="profileSvg"
                                />{" "}
                                Profile
                              </NavLink>
                            </Nav>
                            <Nav key="Profile" onClick={openAccountModal}>
                              <NavLink className="nav-link menuBottom">
                                <img
                                  src="/img/disconnect.svg"
                                  alt="disconnect"
                                />{" "}
                                Disconnect
                              </NavLink>
                            </Nav>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </>
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
    </>
  );
}
