import Form from "react-bootstrap/Form";
export default function SelectMenu({ value, handleChange, options, sx }) {
  /**
   * Select Menue Component
   * author Khaled Mofeed
   */
  return (
    <>
      <div className="input-icon" id="ID_input-icon">
        <span className="reset-select" id="ID_x">
          x
        </span>
        <Form.Select
          name="status"
          value={value}
          onChange={handleChange}
          id="ID_Select"
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Select>
        <span>
          <i className="fas fa-search"></i>
        </span>
      </div>
    </>
  );
}
