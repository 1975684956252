export const advisorsDataConfig = [
  {
    id: "no1",
    name: "Lorem Ipsum",
    job: "CEO Designer",
    firstcontent:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ",
    Image: "img/avatar1.svg",
  },
  {
    id: "no2",
    name: "Lorem Ipsum",
    job: "CEO Designer",
    firstcontent:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ",
    Image: "img/avatar2.svg",
  },
  {
    id: "no3",
    name: "Lorem Ipsum",
    job: "CEO Designer",
    firstcontent:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ",
    Image: "img/avatar3.svg",
  },
];
