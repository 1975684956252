import React, { useState } from 'react';
import { Button } from '@mui/material';
import './style.css';
import { useUpdateProfileAvatar } from '../../../../hooks/useMarketplace';
import { toast } from 'react-hot-toast';

const ImageUploader = ({ src = '', id }) => {
  const { mutateAsync: updateAvatarAsync } = useUpdateProfileAvatar(id);

  const [file, setFile] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files?.[0];

    // 2 MB Size Limit
    const sizeLimit = 2 * 1024 * 1024;

    if (file?.size > sizeLimit) {
      toast.error('File size exceeds 2 MB');
      return;
    }
    if (file) {
      updateAvatarAsync(file)
        .then((res) => {
          if (res) {
            toast.success('Avatar updated successfully');
            setFile(res.data.data.image);
          }
        })
        .catch((err) => {
          toast.error('Something went wrong while updating land image');
        });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: 'fit-content',
        alignItems: 'center',
        gap: '1rem',
      }}
      className='mx-auto w-100 lg-px-4'>
      <div class='image-container'>
        <img src={file || src} alt='An example ' />
      </div>
      <Button
        variant="contained"
        size="small"
        component="label"
        style={{
          width: "fit-content",
        }}
      >
        edit
        <input hidden accept='image/*' multiple type='file' onChange={handleImageChange} />
      </Button>
    </div>
  );
};

export default ImageUploader;
