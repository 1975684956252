import * as am4charts from "@amcharts/amcharts4/charts";

const isRequired = () => {
  throw new Error("param is required");
};
export function DatafieldChart(
  chart = isRequired(),
  value = isRequired(),
  category = isRequired(),
  Legend = false,
  colors = isRequired()
) {
  let pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = value;
  pieSeries.dataFields.category = category;
  if (Legend) {
    chart.legend = new am4charts.Legend();
  }
  pieSeries.slices.template.propertyFields.fill = "color";
  pieSeries.colors.list = colors;
}
