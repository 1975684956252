import React from "react";
import useNftProposal from "../../../hooks/useNftProposal";
/**
 * (Supply Component for view supply value)
 * author Khaled Mofeed
 **/
function Supply(props) {
  const { nftTotal } = useNftProposal();
  return (
    <>
      <div className="supply-img text-center">
        <img src="/img/NFTCard.svg" className="w-100" alt="NFTCard" />
        <h2 className="supply">{nftTotal}</h2>
      </div>
    </>
  );
}

export default Supply;
