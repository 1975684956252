
import React from 'react';
import './style.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CardTeam from './cardTeam';
import Testimonial from './Testimonial';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SettingsSlider, TeamMemberData } from '../../../configs/configTeam';
import classNames from 'classnames';
/*  Render Team Data Component
*  Defining the data through the config Team Member Data file
*  Defining the settings for slider through the config Settings Slider file
 | khaled Mofeed | 29/10/2022 */
const teamData = TeamMemberData;
const settings = SettingsSlider;
function Team({negativeMargin}) {
  return (

      <Row className={classNames('justify-content-center align-items-center min-height-100per Team', { 'custom-margining': negativeMargin })}>
      <Col md={10} xl={9}>
        <div className="title-div">
          <h1 id="id_titleTeam">Our Team</h1>
        </div>
        {/* displaying Team data in up of XL screen | khaled Mofeed | 29/10/2022 */}
        <Row className="d-none d-lg-flex">
          {teamData.map((cardTeam) => (
            <CardTeam key={cardTeam.id} cardTeam={cardTeam} />
          ))}
        </Row>
        {/* displaying Team data in Down of XL screen | khaled Mofeed | 29/10/2022 */}
        <Row className="slider-theme owl-theme owl-stage-outer-pb-5 pb-0 d-block d-lg-none">
          <Slider {...settings} className="owl-carousel-team ">
            {teamData.map((cardTeamSlider) => (
              <Testimonial
                cardTeamSlider={cardTeamSlider}
                key={cardTeamSlider.id}
              />
            ))}
          </Slider>
        </Row>
      </Col>
    </Row>
  );
}

export default Team;
