import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { addProposal } from "../proposalContractFunctions"
import { useState } from "react";
import { toast } from "react-hot-toast";

/**
 * Create New Proposal Modal Component
 * author Mahmoud Skaik
 */
export default function ProposalModal({
    handleClose,
    getProposalsList,
    ...props
}) {


    /**
     * create init data for proposal
     * author Mahmoud Skaik
     */
    const initProposalState = {
        title: '',
        url: '',
        endDate: '',
        rate: ''
    }
    const [proposalState, setProposalState] = useState(initProposalState);
    const [isLoading, setIsLoading] = useState(false);

    /**
    * handle create proposal function
    * author Mahmoud Skaik
     */
    const handleCreateProposal = async () => {
        setIsLoading(true)
        try {
            const tx = await addProposal(proposalState)

            const response = await tx.wait();
            if (response.status == 1) {
                setProposalState({})
                getProposalsList();
                handleClose();
                toast.success('Created Successfully')
            } else {
                toast.error('Created Failed')
            }


        } catch (error) {
            toast.error(JSON.parse(JSON.stringify(error)).reason)
        } finally {
            setIsLoading(false);
        }

    }


    return (
        <Modal
            {...props}
            onHide={() => {
                setProposalState({});
                setIsLoading(false);
                handleClose();
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h3 className="card-label mb-0" id="ID_name_modal">
                        Create New Proposal
                    </h3>
                </Modal.Title>
            </Modal.Header>
            <ModalBody className="px-5">
                <form className="row">
                    <div className="col col-md-12 col-lg-12 mb-2">
                        <div className="input-icon">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Title"
                                id="title"
                                name="title"
                                value={proposalState.title}
                                onChange={(e) => setProposalState({
                                    ...proposalState,
                                    title: e.target.value
                                })} />
                            <span>
                            </span>
                        </div>
                    </div>
                    <div className="col col-md-12 col-lg-12 mb-2">
                        <div className="input-icon">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="URL"
                                id="url"
                                name="url"
                                value={proposalState.url}
                                onChange={(e) => setProposalState({
                                    ...proposalState,
                                    url: e.target.value
                                })} />
                            <span>
                            </span>
                        </div>
                    </div>
                    <div className="col col-md-12 col-lg-12 mb-2">
                        <div className="input-icon">
                            <input
                                type="datetime-local"
                                className="form-control"
                                placeholder="End Date"
                                id="endDate"
                                name="endDate"
                                value={proposalState.endDate}
                                onChange={(e) => setProposalState({
                                    ...proposalState,
                                    endDate: e.target.value
                                })} />
                            <span>
                            </span>
                        </div>
                    </div>
                    <div className="col col-md-12 col-lg-12 mb-2">
                        <div className="input-icon">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Rate"
                                id="rate"
                                name="rate"
                                value={proposalState.rate}
                                onChange={(e) => setProposalState({
                                    ...proposalState,
                                    rate: e.target.value
                                })}
                            />
                            <span>
                            </span>
                        </div>
                    </div>
                </form>
            </ModalBody>
            <Modal.Footer>
                <Button
                    className="btn btn-light-primary"
                    onClick={() => {
                        setProposalState({});
                        setIsLoading(false);
                        handleClose()
                    }}

                    disabled={isLoading}
                    id="ID_Close"
                >
                    Close
                </Button>
                <Button
                    onClick={() => handleCreateProposal()}

                    className="btn btn-success"
                    disabled={isLoading}
                    id="ID_CREATE_PROPOSAL"
                >
                    Create Proposal
                    {isLoading && (
                        <Spinner
                            style={{ marginLeft: ".5rem" }}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            id="ID_Spinner1"
                        />
                    )}
                </Button>


            </Modal.Footer>
        </Modal>
    );
}