import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import { Button } from '@mui/material';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { CLOSE_ICON } from '../../../../constants';
import StyledNumberInput from './inputs/Number';
import DateTimePicker from 'react-datetime-picker';
import { pxToRem } from '../theme';
import './style.css';
import { useBalanceOf } from '../../../../hooks/useMarketplace';
import { useDebounce } from '../../../../Helper/Helper';
import { toast } from 'react-hot-toast';

function OfferModal({ data, offer, ...props }) {
  const [selectedDate, setSelectedDate] = useState();
  const [price, setPrice] = useState(0);

  // Create a new Date object for tomorrow
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  // Remove time part for comparison
  tomorrow.setHours(0, 0, 0, 0);

  let { data: balance } = useBalanceOf();
  balance = balance ? Number(balance?._hex) / 10 ** 16 : 0;

  const handleChange = (value) => {
    setPrice(value);
  };
  const handleOffer = () => {
    const priceNumber = Number(price);
    if (!priceNumber) {
      return toast.error('Please enter price');
    }
    if (priceNumber > balance) {
      return toast.error('Insufficient balance');
    }
    if (priceNumber > data.price) {
      return toast.error('Price should be less than the current price');
    }

    if (!selectedDate) {
      return toast.error('Please select date');
    }

    // Ensure selectedDate is also without time for a fair comparison
    const selectedDateWithoutTime = new Date(selectedDate);
    selectedDateWithoutTime.setHours(0, 0, 0, 0);

    if (selectedDateWithoutTime < tomorrow) {
      return toast.error('Selected date should be a day after today');
    }

    offer(data.token_id, priceNumber, selectedDate);
  };

  const debouncedMakingOffer = useDebounce(handleOffer, 1000);

  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='nftOfferModal'>
      <Modal.Header className='border-0 d-flex align-items-center justify-content-between p-0 mb-2'>
        <Modal.Title id='contained-modal-title-vcenter'>
          <h4>Place an Offer</h4>
        </Modal.Title>
        <ButtonBootstrap onClick={props.onHide} variant='outline-secondary closeModal'>
          <img src={CLOSE_ICON} alt='close' className='closeModal' />
        </ButtonBootstrap>
      </Modal.Header>
      <Modal.Body className='d-flex flex-column gap-4'>
        <div>
          <p className='mb-0'>You are about to place a bit for place an offer NFT {data?.location}.</p>
        </div>
        <Row>
          <div className='d-flex justify-content-between align-items-center'>
            <span>Your offer</span>

            <StyledNumberInput
              name='price'
              value={price}
              onChange={(e) => {
                const value = e.target.value;
                // Explicitly allow an empty string or a string ending with a dot
                if (value === '' || value.endsWith('.')) {
                  return handleChange(value);
                }
                // Then try to parse the value as a number
                const parsed = Number(value);
                if (!Number.isNaN(parsed)) {
                  return handleChange(value);
                }
              }}
            />
          </div>
        </Row>
        <div>
          <DateTimePicker
            value={selectedDate}
            onChange={setSelectedDate}
            className='w-100'
            calendarClassName='Selectdatetime'
            minDate={tomorrow}
            // locale='English'
            format='yyyy-MM-dd'
          />
        </div>
        <div>
          <ul>
            <li className='d-flex justify-content-between align-items-center'>
              <p>Offering</p>
              <span>{price} TELV</span>
            </li>
            <li className='d-flex justify-content-between align-items-center'>
              <p>Your balance</p>
              <span>{balance} TELV</span>
            </li>
            <li>
              <hr />
            </li>
          </ul>
        </div>
        <div>
          <Button
            onClick={debouncedMakingOffer}
            size='large'
            sx={{
              borderRadius: pxToRem(85),
              marginBottom: pxToRem(12),
            }}
            fullWidth
            variant='contained'
            color='primary'>
            Place an Offer
          </Button>
          <Button
            onClick={props.onHide}
            size='large'
            sx={{
              color: 'white',
              border: '1.5px solid #777E90',
              borderRadius: pxToRem(85),
            }}
            fullWidth
          >
            close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default OfferModal;
