import React, { useEffect } from "react";
import { Link } from "react-router-dom";
/**
 * this Third Step in Google Authentication
 * Backup Key.
 * author Khaled Mofeed
 */
function BackupKeyStep(props) {
  function handleNextStep() {
    props.setIsStep3(false);
    props.setIsStep4(true);
  }
  function handlePrevStep() {
    props.setIsStep3(false);
    props.setIsStep2(true);
  }
  useEffect(() => {
    // document.getElementById("liStep3").classList.add("done");
    props.liStep3.current.classList.add("done");
  }, []);
  return (
    <>
      <div className="content clearfix">
        <div>
          <h3 className="text-center title-section" id="id_Backup_lable">
            Backup Key
          </h3>
          <p className="mb-3" id="id_Backup_sublable">
            Please save this Key on paper. This Key will allow you to recover
            your Google Authenticator in case of phone loss.
          </p>
        </div>
        <div className="row row-sm">
          <div className="css-weqpav">
            <div className="css-1xfmg0p">
              <img
                id="id_img_code"
                className="img-code"
                src="/img/download-apple.svg"
                alt="img-code"
              />
            </div>
            <div className="css-wiulhc"></div>
            <div className="css-1t5xnih">
              <div className="css-vurnku" id="id_Authentication_text">
                Resetting your Google Authentication requires opening a support
                ticket and takes at least 7 days to process.
              </div>
              <div className="css-1gv6rnn">
                <div className="css-ryy4eq" id="id_google2fa_secret_key_text">
                  {props.authenticationInfo.google2fa_code}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="actions clearfix">
        <ul role="menu" aria-label="Pagination">
          <li>
            <Link onClick={handlePrevStep} id="id_Previous_step3">
              Previous
            </Link>
          </li>
          <li>
            <Link onClick={handleNextStep} id="id_Next_step3">
              Next
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default BackupKeyStep;
