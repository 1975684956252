import React, { useState } from "react";
import CustomTable from "../../../../components/CustomTable";
import Pagination from "./Pagenation";
import { getUserPurchasedTransactionsList } from "../../presaleContractFunctions";
import "./style.css";

function Purchased() {
  const [currentPage, setCurrentPage] = useState(1);
  const [tableTotalList, setTableTotalList] = useState(0);
  const [pages, setPages] = useState(0);

  const transactionList = async (limit) => {
    const { transactions, totalList } = await getUserPurchasedTransactionsList(
      currentPage - 1,
      limit
    );
    setTableTotalList(totalList);
    setPages(Math.ceil(totalList / limit) || 1);
    return transactions;
  };

  return (
    <div className="shared-space">
      <h2 className="table-label">TELV Purchased</h2>
      <CustomTable cb={transactionList} currentPage={currentPage} no={3} />
      <Pagination
        totalList={tableTotalList}
        pages={pages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

export default Purchased;
