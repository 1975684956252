import { Link } from "react-router-dom";
import { useAuth } from "../../../scripts/App/auth";
/**
 * (Header)
 * this Header of Users admin Page, have title and action for (add new admin)
 * author Khaled Mofeed
 */
function Header(props) {
  const auth = useAuth();
  return (
    <div className="card-header border-0">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label text-dark" id="ID_Admins_Management">
          Admins Management
        </span>
        <span
          className="text-muted mt-1 font-size-sm"
          id="ID_Admins_Management_lable"
        >
          Manage your admins
        </span>
      </h3>
      <div className="card-toolbar">
        {auth?.user?.permissions?.includes("add_admin_user") && (
          <Link
            className="d-none d-sm-inline-block btn btn-md btn-primary px-4 shadow-sm"
            onClick={props.action}
            id="ID_Link_Header"
          >
            <i className="fas fa-user-plus fa-sm text-white-50 mr-2"></i>
            Add new admin
          </Link>
        )}
      </div>
    </div>
  );
}

export default Header;
