import React from "react";
function Header(props) {
  /**
   * Header of profile page component
   * author Khaled Mofeed
   */
  return (
    <div className="card-header border-0">
      <h3 className="card-title align-items-start flex-column" id="ID_Profile2">
        <span className="card-label text-dark" id="ID_Profile3">
          Profile
        </span>
        <span className="text-muted mt-1 font-size-sm" id="ID_Manage">
          Manage your profile and settings
        </span>
      </h3>
    </div>
  );
}

export default Header;
