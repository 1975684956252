import SelectMenu from "../../../conmponents/SelectMenu";
/**
 * (Filter Form)
 * this Form used for Filter the Data to search the Role
 * author Khaled Mofeed
 */
const initFilterData = {
  status: true,
  data: {
    name: "",
    status: "",
  },
};

export default function FilterForm({ filter, setFilter, handleSearch }) {
  const handleChanges = (event) => {
    setFilter({
      ...filter,
      data: {
        ...filter.data,
        [event.target.name]: event.target.value,
      },
    });
  };
  const handleClearSearch = () => {
    setFilter(initFilterData);
    handleSearch(initFilterData);
  };

  return (
    <div className="col-md-12 col-lg-12">
      <form id="packageSearchForm" className="row">
        <div className="col col-md-4 col-lg-4 mb-2  ">
          <div className="input-icon">
            <input
              type="search"
              className="form-control"
              placeholder="Name"
              id="name"
              name="name"
              value={filter.data.name}
              onChange={handleChanges}
            />
            <span>
              <i className="fas fa-search" id="ID_search_icon"></i>
            </span>
          </div>
        </div>
        <div className="col col-md-4 col-lg-4 mb-2">
          <SelectMenu
            label="Status"
            handleChange={({ target }) => {
              setFilter({
                ...filter,
                data: {
                  ...filter.data,
                  status: target.value,
                },
              });
            }}
            options={[
              { value: "", label: "Status" },
              { value: "active", label: "Active" },
              { value: "inActive", label: "InActive" },
            ]}
            value={filter.data.status}
            sx={{ flex: "1 1 40%" }}
            id="ID_SelectMenu1"
          />
        </div>
        <div className="col-md-4 col-lg-4 mb-2">
          <div className="d-flex align-items-center">
            <button
              className="btn btn-primary py-2 px-4 w-50 px-lg-6  "
              type="button"
              onClick={handleSearch}
              id="ID_Search"
            >
              Search
            </button>
            <button
              type="reset"
              className="btn btn-light-primary py-2 px-4 px-lg-6 w-50 ms-2"
              onClick={handleClearSearch}
              id="ID_Reset"
            >
              Reset
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
