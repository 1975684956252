import React, { useEffect, useRef } from "react";
import { FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { request } from "../../../plugins/axios";
import { endpoints } from "../../../../utils/networks";
/**
 * Multiple Select Menue Component use to view and select the active roles
 * author Khaled Mofeed
 */
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function MultiSelectCardAddAdmin({
  value,
  options,
  label,
  errorMessage,
  sx,
  renderValue,
  handleChange,
  ...props
}) {
  const optionsHolder = useRef(null);
  const hashedOption = useRef({});

  function getDataRole() {
    request(endpoints.Roles.GetRoleList.method, endpoints.Roles.GetRoleList.url)
      .then((response) => {
        let result = response?.data?.data?.data;
        let Ops = result?.filter((role) => role.status);
        Ops = Ops.map((role) => {
          hashedOption.current[role.id] = role.permissions;
          return {
            value: role.id,
            label: role.name,
            permissions: role.permissions,
          };
        });
        optionsHolder.current = Ops;
        props.setOptions(Ops);
      })
      .catch((erorr) => {});
  }

  useEffect(() => {
    getDataRole();
  }, []);

  function getStyles(name, values) {
    return {
      fontWeight: values.map(({ label }) => label).includes(name),
    };
  }
  return (
    <FormControl
      variant="standard"
      sx={sx}
      error={Boolean(errorMessage)}
      className="formControlSelect"
    >
      <InputLabel id={`${label}-multiple-name-label`} className="labelinput">
        {label}
      </InputLabel>
      <Select
        // displayEmpty
        labelId={`${label}-multiple-name-label`}
        id={`multiple-select-${label}`}
        value={value}
        multiple={true}
        className="basic-multi-select"
        onChange={handleChange}
        MenuProps={MenuProps}
        renderValue={renderValue}
      >
        {options.map((name) => (
          <MenuItem
            key={name.label}
            value={name.value}
            style={getStyles(name.label, options)}
          >
            {name.label}
          </MenuItem>
        ))}
      </Select>

      {Boolean(errorMessage) && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}

export default MultiSelectCardAddAdmin;
