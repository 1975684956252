import ShareLink from "../assets/presale/social-media/share-link.svg";
import Facebook from "../assets/presale/social-media/Facebook.svg";
import Twitter from "../assets/presale/social-media/Twitter.svg";
import Telegram from "../assets/presale/social-media/Telegram.svg";
import WhatsApp from "../assets/presale/social-media/WhatsApp.svg";
import Discord from "../assets/presale/social-media/Discord.svg";
import { copyToClipboard } from "../Helper/Helper.js";

const arrOfShare = (url, title) => {
  return [
    {
      name: "Clipboard",
      icon: ShareLink,
      onClick: () => {
        copyToClipboard(url);
      },
    },
    {
      name: "Facebook",
      icon: Facebook,
      onClick: () => {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${title}`,
          "facebook-share-dialog",
          "width=800,height=600"
        );
      },
    },
    {
      name: "Twitter",
      icon: Twitter,
      onClick: () => {
        window.open(
          `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
          "twitter-share-dialog",
          "width=800,height=600"
        );
      },
    },
    {
      name: "Telegram",
      icon: Telegram,
      onClick: () => {
        window.open(
          `https://t.me/share/url?url=${url}&text=${title}`,
          "telegram-share-dialog",
          "width=800,height=600"
        );
      },
    },
    {
      name: "WhatsApp",
      icon: WhatsApp,
      onClick: () => {
        window.open(
          `https://api.whatsapp.com/send?text=${title} ${url}`,
          "whatsapp-share-dialog",
          "width=800,height=600"
        );
      },
    },
    // open a new tab to share the link on discord
    {
      name: "Discord",
      icon: Discord,
      onClick: () => {
        window.open(
          `https://discord.com/api/qrcode?text=${url}`, // TODO : add webhooks to discord
          "discord-share-dialog",
          "width=800,height=600"
        );
      },
    },
  ];
};

export default arrOfShare;
