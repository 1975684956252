import Button from "react-bootstrap/Button";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Spinner } from "react-bootstrap";
import { ModalBody } from "react-bootstrap";
import MultiSelectCardAddAdmin from "./multiSelectCardAddAdmin";
import { Box } from "@mui/system";
import { IconButton, FormGroup, Switch, Chip } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { isEqual } from "lodash";
import { useAdminModal } from "../Hooks/useAdminModal";
/**
 * this the value of input when its error form
 * author Khaled Mofeed
 */

function AddAdminModal({
  data,
  setData,
  updateUser,
  addNewUser,
  handleClose,
  onHide,
  ...props
}) {
  /**
   * use the useAdminModal custom hook to handle the operations
   * author Khaled Mofeed
   */

  const {
    roles_permissions,
    errors,
    INPUT_FIELDS_LABLE,
    loading,
    allPermissions,
    ALL_PERMISSIONS_OF_ROLES,
    submitHandler,
    submitEditAdmin,
  } = useAdminModal(data, setData, updateUser, addNewUser, handleClose, onHide);

  useEffect(() => {
    if (
      isEqual(
        allPermissions.map((permission) => permission.name),
        data?.data?.permissions.map((permission) => permission.permissions)
      )
    ) {
      return setData({});
    }

    if (data?.data?.roles.includes("administrator")) return;

    if (!data?.data?.permissions.length) {
      setData({ roles: [] });
    }
  }, [data?.data?.permissions]);

  return (
    <Modal
      {...props}
      show={data.status}
      onHide={handleClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3 className="card-label">
            {data.edit ? `Edit “${data.data.name}” Admin` : "Add New Admin"}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <ModalBody className="p-17">
        <div className="row align-items-center mt-3 mb-4">
          <div className="col-md-12 ">
            <div className="card-title" id="ID_General">
              <h6 className="card-label">General Information</h6>
            </div>
          </div>
          <div className="col-md-12 col-lg-12">
            <div id="addAdmin" className="row  admin-settings">
              {INPUT_FIELDS_LABLE}
              <div className="col col-md-4 col-lg-4 mb-2 d-flex ">
                <MultiSelectCardAddAdmin
                  errorMessage={errors.roles?.[0]}
                  label="Roles"
                  id="ID_Roles_lable"
                  handleChange={({ target }) => {
                    const newRoles =
                      typeof target.value === "string"
                        ? target.value.split(",")
                        : target.value;

                    setData({
                      roles: newRoles,
                      permissions: newRoles.reduce((acc, role) => {
                        if (role === "administrator") {
                          return acc.concat(
                            allPermissions.map((permission) => ({
                              ...permission,
                              is_custom: false,
                            }))
                          );
                        }

                        const find_roles_permissions = roles_permissions?.find(
                          (r) => r.name === role
                        );
                        if (find_roles_permissions) {
                          return [
                            ...data.data.permissions,
                            ...acc,
                            ...(find_roles_permissions?.permissions.map(
                              (permission) => ({
                                ...permission,
                                is_custom: false,
                              })
                            ) || []),
                          ];
                        }
                        return acc;
                      }, []),
                    });
                  }}
                  value={data.data.roles.map((role) =>
                    typeof role === "object" ? role.name : role
                  )}
                  options={[
                    ...roles_permissions
                      .filter((role) => role.status === "Active")
                      .map((role) => ({
                        value: role.name,
                        label: role.name,
                      })),
                  ]}
                  renderValue={(selected) => (
                    <Box
                      id="ID_Box_lable"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.length > 0 &&
                          selected.map((value, index) => (
                            <Chip
                              id="ID_Chip"
                              variant="outlined"
                              key={index}
                              label={value}
                              onDelete={() => {
                                const filteredRoles = data.data.roles.filter(
                                  (role) =>
                                    typeof role === "string"
                                      ? role !== value
                                      : role.name !== value
                                );
                                const filteredPermissions =
                                  roles_permissions?.find(
                                    (role) => role.name === value
                                  )?.permissions || [];

                                setData({
                                  roles: filteredRoles,
                                  permissions: data.data.permissions.filter(
                                    (permission) => {
                                      return !filteredPermissions
                                        .map((s) => s.name)
                                        .includes(permission.name);
                                    }
                                  ),
                                });
                              }}
                              clickable
                              deleteIcon={
                                <i
                                  className="fas fa-times"
                                  onMouseDown={(event) =>
                                    event.stopPropagation()
                                  }
                                ></i>
                              }
                            />
                          ))}
                      </Box>
                      {selected.length > 0 && (
                        <IconButton
                          id="ID_IconButton"
                          className="IconButton-custom"
                          onMouseDown={(event) => event.stopPropagation()}
                          onClick={() => {
                            setData({
                              roles: [],
                              permissions: [],
                            });
                          }}
                        >
                          <i className="fas fa-times" color="error"></i>
                        </IconButton>
                      )}
                    </Box>
                  )}
                  sx={{ flex: "1 1 400px" }}
                />
              </div>
              <div className="col col-md-4 col-lg-4 mb-2  ">
                <FormGroup>
                  <FormControlLabel
                    id="ID_FormControlLabel1"
                    control={
                      <Switch
                        id="ID_Switch"
                        checked={
                          typeof data.data.status === "string"
                            ? data.data.status === "Active"
                              ? true
                              : false
                            : data.data.status === true
                        }
                      />
                    }
                    label={
                      typeof data.data.status === "string"
                        ? data.data.status === "Active"
                          ? "Active"
                          : "Inactive"
                        : data.data.status
                        ? "Active"
                        : "Inactive"
                    }
                    onChange={(e) =>
                      setData({
                        ...data.data,
                        status: e.target?.checked ? true : false,
                      })
                    }
                  />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 ">
            <div className="card-title">
              <h6 className="card-label mb-2 permission" id="ID_Permissions2">
                Permissions
              </h6>
            </div>
          </div>
          {ALL_PERMISSIONS_OF_ROLES}
        </div>
      </ModalBody>
      <Modal.Footer>
        <Button
          onClick={handleClose}
          className="btn btn-light-primary"
          disabled={loading}
        >
          Close
        </Button>
        <Button
          className="btn btn-primary"
          type="button"
          disabled={loading}
          onClick={data.edit ? submitEditAdmin : submitHandler}
        >
          {data.edit ? "Save Edited changes" : "Save changes"}
          {loading && (
            <Spinner
              style={{ marginLeft: ".5rem" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddAdminModal;
