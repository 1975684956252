import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import Sidebar from "../../conmponents/SideBar";
import Topbar from "../../conmponents/Topbar";
import EnhancedTable from "../../layouts/table";
import FilterForm from "./conmponents/FilterForm";

import {
  TableRow,
  Typography,
} from "@mui/material";
import LoadingTableRows from "../../conmponents/LoadingTableRows";
import DeleteEventModal from "./conmponents/DeleteEventModal";
import EventModal from "./conmponents/EventModal";
import { useEvent } from "./Hooks/useEvent";
import { Link } from "react-router-dom";
import { copyToClipboard } from "../../../Helper/Helper";
import Header from "./conmponents/header";

function EventManagement(props) {
  /**
   * use the useEvent custom hook to handle the operations
   * author Mahmoud Skaik
   */
  const {
    initEventState,
    user,
    loading,
    tableData,
    setTableData,
    filter,
    setFilter,
    event,
    setEvent,
    confirmDelete,
    setConfirmDelete,
    handleClose,
    order,
    setOrder,
    handleChangeRowsPerPage,
    handleChangePage,
    getEvents,
    deleteEvent,
    updateEvent,
    eventDetailsModal
  } = useEvent();
  /**
   * use the useRef to return body
   * author Mahmoud Skaik
   */
  const body = useRef(document.body);

  useEffect(() => {
    body.current.classList.add("admin");
    if (!user.permissions.includes("view_event")) return;
    getEvents();
  }, []);

  /**
 * this function is used to handle the Event Status class that shown in table
 * author Mahmoud Skaik
 */
  function eventStatusBadge(status) {
    switch (status) {
      case 'Approved':
        return 'success';
      case 'Rejected':
        return 'danger';
      case 'Pending':
      default:
        return 'warning';

    }
  }

  function copyAdressToClipboard(address) {
    copyToClipboard(address);
  }

  return (
    <div id="wrapper" className="admin">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar />

          <div className="container-fluid">
            <div className="card card-custom gutter-b">
              <Header />
              <div className="card-body py-0">
                <EnhancedTable
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleChangePage={handleChangePage}
                  handleRequestSortHeader={(order, orderBy) => {
                    if (!orderBy) return;
                    getEvents({
                      creator_wallet_address: filter.data.creator_wallet_address,
                      sortBy: orderBy,
                      sortOrder: order,
                      page: 1,
                      perPage: tableData.rowsPerPage,
                    });

                    setOrder({ order, orderBy });
                  }}
                  TableRows={
                    loading ? (
                      <LoadingTableRows
                        elementsCount={tableData.tableHead.length}
                      />
                    ) : tableData.tableItems.length > 0 ? (
                      tableData.tableItems.map((row, index) => (
                        <tr key={index} id="ID_tr">
                          <td id="ID_td1">
                            <Link
                              onClick={() => eventDetailsModal(row)}
                              id="ID_Link_Header"
                            >
                              {row?.name.slice(0, 25) + " ..."}
                            </Link>
                          </td>
                          <td id="ID_td2">{row?.start_date + ' ' + row?.start_time}</td>
                          <td id="ID_TD3">{row?.location}</td>
                          <td id="ID_TD4">{row?.created_by}</td>
                          <td id="ID_TD4">
                            {row?.creator_wallet_address && (row?.creator_wallet_address?.slice(0, 9) + "..." + row?.creator_wallet_address?.slice(-4))}
                            {/* <img onClick={copyAdressToClipboard} src={Copy} alt="copy" /> */}
                            {" "}
                            <button
                              onClick={() => {
                                copyAdressToClipboard(row?.creator_wallet_address && row?.creator_wallet_address);
                              }}
                              type="button"
                              className="btn btn btn-icon"
                              title="Copy"
                            >
                              <i className="fas fa-copy"></i>
                            </button>
                          </td>
                          <td id="ID_TD5">
                            <span className={`badge bg-${eventStatusBadge(row?.status)}`}>
                              {row?.status}
                            </span>
                          </td>
                          <td id="ID_TD6">
                            <span className={`badge bg-${row?.schedule_status === "Coming" ? "success" : "danger"}`}>
                              {row?.schedule_status}
                            </span>
                          </td>
                          <td id="ID_TD5">
                            {user?.permissions?.includes(
                              "delete_event"
                            ) && (
                                <button
                                  onClick={() => {
                                    setConfirmDelete({
                                      status: true,
                                      id: row.uuid,
                                    });
                                  }}
                                  type="button"
                                  className="btn btn-light-primary btn-icon"
                                >
                                  <i className="fas fa-trash"></i>
                                </button>
                              )}
                          </td>
                        </tr>

                      ))
                    ) : (
                      <TableRow>
                        <td
                          colSpan={tableData.tableHead.length}
                          id="ID_tdNoData"
                        >
                          <Typography
                            sx={{ textAlign: "center" }}
                            variant="body2"
                            color="textPrimary"
                          >
                            No data found
                          </Typography>
                        </td>
                      </TableRow>
                    )
                  }
                  {...tableData}
                >
                  <div className="card-header flex-md-wrap border-0 ">
                    <div className="row align-items-center w-md-100">
                      <div className="col-md-12 ">
                        <div
                          className="card-title filter-title"
                          id="ID_setFilter"
                          onClick={() =>
                            setFilter({
                              ...filter,
                              status: !filter.status,
                            })
                          }
                        >
                          <h6 className="card-label">
                            <i className="fas fa-sort-amount-down-alt mr-2"></i>
                            Filter by <i className="fas fa-sort list"></i>
                          </h6>
                        </div>
                      </div>

                      {filter.status && (
                        <FilterForm
                          filter={filter}
                          setFilter={setFilter}
                          handleSearch={(data) => {
                            if (data?.data) {
                              getEvents({
                                ...data.data,
                                sortBy: order.orderBy,
                                sortOrder: order.order,
                              });
                            } else {
                              getEvents({
                                ...filter.data,
                                sortBy: order.orderBy,
                                sortOrder: order.order,
                              });
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                </EnhancedTable>

                <DeleteEventModal
                  id="ID_DeleteAdminModal"
                  show={confirmDelete.status}
                  onHide={() => setConfirmDelete({ ...confirmDelete, status: false })}
                  onConfirm={() => deleteEvent(confirmDelete.id)}
                />

                <EventModal
                  id="ID_EventModal"
                  onClick={props.action}
                  updateEvent={updateEvent}
                  onHide={() => handleClose()}
                  data={event}
                  setData={(object) =>
                    setEvent({
                      ...event,
                      data: { ...event.data, ...object },
                    })
                  }
                  handleClose={() => {
                    setEvent(initEventState);
                  }}

                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventManagement;
