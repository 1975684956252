export const TableData = {
  page: 1,
  pagesCount: 10,
  rowsPerPage: 5,
  rowsPerPageOptions: [5, 10, 25, 50, 100],
  tableHead: [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "users_count",
      numeric: false,
      disablePadding: false,
      label: "Assigned User",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "Actions",
    },
  ],
  tableItems: [],
};
