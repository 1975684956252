export const config_A_Section = [
  //******************** A Section  ***************
  {
    nameMap: "pointDataHexa",
    locationMap: "100, 600, 300",
    stroke: "rgb(34 33 43)",
    fill: "rgb(28 27 36)",
    unClick: true,
    section: "A",
  },
  // 1 top parent 1
  {
    nameMap: "pointData1",
    locationMap: "33, 600, 242",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "pointData1Child1",
    locationMap: "10.7, 600, 222.8",
  },
  {
    nameMap: "pointData1Child2",
    locationMap: "10.7, 583.5, 232.2",
  },
  {
    nameMap: "pointData1Child3",
    locationMap: "10.7, 583.4, 251.3",
  },
  {
    nameMap: "pointData1Child4",
    locationMap: "10.7, 600, 261",
  },
  {
    nameMap: "pointData1Child5",
    locationMap: "10.7, 616.5, 232.2",
  },
  {
    nameMap: "pointData1Child6",
    locationMap: "10.7, 616.4, 251.3",
  },
  {
    nameMap: "pointData1Child7",
    locationMap: "10.7, 600, 242",
  },
  // 2 top left parent 2
  {
    nameMap: "Parent2Container",
    locationMap: "33, 550, 271",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Parent2",
    locationMap: "10.7, 550, 251.8",
  },
  {
    nameMap: "Child2_Parent2",
    locationMap: "10.7, 533.5, 261.2",
  },
  {
    nameMap: "Child3_Parent2",
    locationMap: "10.7, 533.5, 280.3",
  },
  {
    nameMap: "Child4_Parent2",
    locationMap: "10.7, 550, 290",
  },
  {
    nameMap: "Child5_Parent2",
    locationMap: "10.7, 566.4, 280.3",
  },
  {
    nameMap: "Child6_Parent2",
    locationMap: "10.7, 566.4, 261.2",
  },
  {
    nameMap: "Child7_Parent2",
    locationMap: "10.7, 550, 271",
  },
  // 3 bottom left parent 3
  {
    nameMap: "pointData3",
    locationMap: "33, 550, 329",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Parent3",
    locationMap: "10.7, 550, 309.8",
  },
  {
    nameMap: "Child2_Parent3",
    locationMap: "10.7, 533.5, 319.2",
  },
  {
    nameMap: "Child3_Parent3",
    locationMap: "10.7, 533.5, 338.3",
  },
  {
    nameMap: "Child4_Parent3",
    locationMap: "10.7, 550, 348",
  },
  {
    nameMap: "Child5_Parent3",
    locationMap: "10.7, 566.4, 319.2",
  },
  {
    nameMap: "Child6_Parent3",
    locationMap: "10.7, 566.4, 338.3",
  },
  {
    nameMap: "Child7_Parent3",
    locationMap: "10.7, 550, 329",
  },
  // 4 bottom parent 4
  {
    nameMap: "pointData4",
    locationMap: "33, 650, 271",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Parent4",
    locationMap: "10.7, 650, 251.8",
  },
  {
    nameMap: "Child2_Parent4",
    locationMap: "10.7, 633.5, 261.2",
  },
  {
    nameMap: "Child3_Parent4",
    locationMap: "10.7, 633.5, 280.3",
  },
  {
    nameMap: "Child4_Parent4",
    locationMap: "10.7, 650, 290",
  },
  {
    nameMap: "Child5_Parent4",
    locationMap: "10.7, 666.4, 261.2",
  },
  {
    nameMap: "Child6_Parent4",
    locationMap: "10.7, 666.4, 280.3",
  },
  {
    nameMap: "Child7_Parent4",
    locationMap: "10.7, 650, 270.9",
  },
  //  5 bottom right parent 5
  {
    nameMap: "pointData5",
    locationMap: "33, 650, 329",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Parent5",
    locationMap: "10.7, 650, 309.8",
  },
  {
    nameMap: "Child2_Parent5",
    locationMap: "10.7, 633.5, 319.2",
  },
  {
    nameMap: "Child3_Parent5",
    locationMap: "10.7, 633.5, 338.3",
  },
  {
    nameMap: "Child4_Parent5",
    locationMap: "10.7, 650, 348",
  },
  {
    nameMap: "Child5_Parent5",
    locationMap: "10.7, 666.4, 319.2",
  },
  {
    nameMap: "Child6_Parent5",
    locationMap: "10.7, 666.4, 338.3",
  },
  {
    nameMap: "Child7_Parent5",
    locationMap: "10.7, 650, 328.9",
  },
  //  6 top right parent 6
  {
    nameMap: "pointData6",
    locationMap: "33, 600, 358",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Parent6",
    locationMap: "10.7, 600, 338.8",
  },
  {
    nameMap: "Child2_Parent6",
    locationMap: "10.7, 583.5, 348.2",
  },
  {
    nameMap: "Child3_Parent6",
    locationMap: "10.7, 583.5, 367.3",
  },
  {
    nameMap: "Child4_Parent6",
    locationMap: "10.7, 600, 377",
  },
  {
    nameMap: "Child5_Parent6",
    locationMap: "10.7, 616.4, 348.2",
  },
  {
    nameMap: "Child6_Parent6",
    locationMap: "10.7, 616.4, 367.3",
  },
  {
    nameMap: "Child7_Parent6",
    locationMap: "10.7, 600, 358",
  },
];
// export const config_A_Section_Parent_SubParent = [
//   //******************** A Section  ***************
//   {
//     nameMap: "pointDataHexa",
//     locationMap: "100, 600, 300",
//     stroke: "rgb(34 33 43)",
//     fill: "rgb(28 27 36)",
//     unClick: true,
//   },
//   // 1 top parent 1
//   {
//     nameMap: "pointData1",
//     locationMap: "33, 600, 242",
//     fill: "#482C68",
//     unClick: true,
//   },
//   // 2 top left parent 2
//   {
//     nameMap: "Parent2Container",
//     locationMap: "33, 550, 271",
//     fill: "#482C68",
//     unClick: true,
//   },
//   // 3 bottom left parent 3
//   {
//     nameMap: "pointData3",
//     locationMap: "33, 550, 329",
//     fill: "#482C68",
//     unClick: true,
//   },
//   // 4 bottom parent 4
//   {
//     nameMap: "pointData4",
//     locationMap: "33, 650, 271",
//     fill: "#482C68",
//     unClick: true,
//   },
//   //  5 bottom right parent 5
//   {
//     nameMap: "pointData5",
//     locationMap: "33, 650, 329",
//     fill: "#482C68",
//     unClick: true,
//   },
//   //  6 top right parent 6
//   {
//     nameMap: "pointData6",
//     locationMap: "33, 600, 358",
//     fill: "#482C68",
//     unClick: true,
//   },
// ];
