import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
function Filter({ toggle, sortByDate, checked, setChecked, sortAsc }) {
  /**
   * fillter component to sort data by activity and date
   * author Khaled Mofeed
   */
  return (
    <div className="row">
      <div className="col-12">
        <div className="filter_items mt-4 mb-2">
          <Form.Group className="Active-check" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Active"
              checked={checked}
              onChange={() => setChecked(toggle)}
            />
          </Form.Group>
          <p
            className="Active-check"
            onClick={sortAsc ? () => sortByDate(true) : () => sortByDate(false)}
          >
            <div className="icons">
              <i
                className={`fas fa-caret-up ${
                  sortAsc ? "acitveAsc" : "inActiveAsc"
                }`}
              ></i>
              <i
                className={`fas fa-caret-down ${
                  !sortAsc ? "acitveAsc" : "inActiveAsc"
                }`}
              ></i>
            </div>
            Date
          </p>
        </div>
      </div>
    </div>
  );
}

export default Filter;
