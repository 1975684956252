import React from "react";
import { Link } from "react-router-dom";
function Header(props) {
  /**
   * Header of proposal page component
   * author Mahmoud Skaik
   */

  return (
    <div className="card-header border-0">
      <h3 className="card-title align-items-start flex-column" id="ID_Proposal2">
        <span className="card-label text-dark" id="ID_Proposal3">
          Proposal
        </span>
        <span className="text-muted mt-1 font-size-sm" id="ID_Manage">
          Manage your contract proposals
        </span>
      </h3>
      <div className="d-flex align-items-center">
        <div className="card-toolbar mr-2">
          <Link
            className="d-none d-sm-inline-block btn btn-md btn-primary px-4 shadow-sm"
            onClick={() => {
              props.setModalCreateProposal(true);
            }}
            id="ID_Add_PROPOSAL"
          >
            Create New Proposal
          </Link>
        </div>
      
      </div>
    </div>
  );
}

export default Header;
