import React from "react";
import "./style.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
function Herosection(props) {
  /*  this is hero section component used in token page  khaled Mofeed | 29/10/2022  */
  return (
    <Row className="justify-content-center">
      <Col md={10}>
        <Row className="align-items-center min-height-100">
          <Col lg={6}>
            <img
              src="/img/telv.svg"
              className="telvimg"
              alt="telv"
              id="id_telv"
            />
          </Col>
          <Col lg={6} className="hero-content">
            <h1 id="id_h1Telv">TELV</h1>

            <p id="id_telv_p">
              TELV is the project token, which will be the only payment currency
              for paid services within the Televers world, and it is the only
              currency used for purchases and trading within Televers
              Marketplace.
            </p>
            <p id="id_telv_p2">
              TELV was created on the Near protocol, which gave it many
              advantages, and the foundations of its distribution were laid, and
              its eco-system was built to ensure that it provides it with a
              sense of strength and durability.
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Herosection;
