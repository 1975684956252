import { useState } from "react";
import toast from "react-hot-toast";
import { EventTableData } from "../../../../configs/configEventTableData";
import { endpoints } from "../../../../utils/networks";
import { request } from "../../../plugins/axios";
import { useAuth } from "../../../scripts/App/auth";

/**
 * (Custom Hook)
 * this custom hook handles all operations of the Event page
 * author Mahmoud Skaik
 */
const initFilterData = {
  status: true,
  data: {
    creator_wallet_address: "",
  },
};
const initEventState = {
  status: false,
  edit: false,
  data: {
    uuid: "",
    name: "",
    dateTime: "",
    location: "",
    creator: "",
    status: false,
    schedule_status: "Ended"
  },
};
export const useEvent = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableDatas] = useState(EventTableData);
  const setTableData = (data) => setTableDatas({ ...tableData, ...data });
  const [filter, setFilter] = useState(initFilterData);
  const [event, setEvent] = useState(initEventState);
  const [confirmDelete, setConfirmDelete] = useState({
    status: false,
    id: "",
  });

  const [order, setOrder] = useState({
    order: "asc",
    orderBy: "status",
  });
  /**
   * this function is used to handle the Change of Rows the table Per Page
   * author Mahmoud Skaik
   */
  const handleChangeRowsPerPage = (event) => {
    getEvents({
      ...filter.data,
      sortBy: order.orderBy,
      sortOrder: order.order,
      page: 1,
      perPage: event.target.value,
    });
    setTableData({
      rowsPerPage: Number(event.target.value),
      page: 1,
    });
  };
  /**
   * this function is used to handle the Change of page of table
   * author Mahmoud Skaik
   */
  const handleChangePage = (_, newPage) => {
    getEvents({
      ...filter.data,
      sortBy: order.orderBy,
      sortOrder: order.order,
      page: newPage,
      perPage: tableData.rowsPerPage,
    })
    setTableData({ page: newPage });
  };
  /**
   * this function is used to Get Events from API
   * author Mahmoud Skaik
   */
  const getEvents = async (params) => {
    try {
      const {
        data: { data },
      } = await request(
        endpoints.Events.list.method,
        endpoints.Events.list.url,
        { params }
      );

      setLoading(false);
      setTableData({
        tableItems: data.data,
        pagesCount: data.total,
        rowsPerPage: data.per_page,
        page: data.current_page,
      });
    } catch (error) {
      setLoading(false);

      toast.error(
        error.response.data.message ||
        "Something went wrong. Please try again later or contact support."
      );
      console.log(error);
    }
  };
  /**
   * this function is used to Delete Event
   * author Mahmoud Skaik
   */
  const deleteEvent = async (event_uuid) => {
    if (!user.permissions.includes("delete_event"))
      return toast.error("You dont have permissions to do this action");
    await request(
      endpoints.Events.DeleteRole.method,
      endpoints.Events.DeleteRole.url + `?event_uuid=${event_uuid}`
    ).then(({ data }) => {
      toast.success(data?.message);
      setTableData({
        tableItems: tableData.tableItems.filter(
          (item) => item.uuid !== event_uuid
        ),
        pagesCount: tableData.pagesCount - 1
      });
      setConfirmDelete({ status: false, id: "" });

    }).catch((err) => {
      toast.error(
        "Error while deleting event, please try again later or contact support"
      );
      console.log(err);
      setConfirmDelete({ status: false, id: "" });
    });

  };

  /**
 * this function is used to update event Change Status
 * author Mahmoud Skaik
 */
  const updateEvent = (uuid, status) => {
    setTableData({
      tableItems: tableData.tableItems.map(item => {
        if (item.uuid === uuid) {
          return { ...item, status: status }
        }
        return item
      }
      ),
    });

    setEvent(initEventState);
  };

  const eventDetailsModal = (event_data) => {
    setEvent({ status: true, edit: true, data: event_data });

  }


  return {
    initEventState,
    user,
    loading,
    tableData,
    setTableData,
    filter,
    setFilter,
    event,
    setEvent,
    order,
    setOrder,
    handleChangeRowsPerPage,
    handleChangePage,
    getEvents,
    confirmDelete,
    setConfirmDelete,
    deleteEvent,
    updateEvent,
    eventDetailsModal
  };
};
