import React, { useState } from "react";
import "./style.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import LoginForm from "./Login/LoginForm";
import ForgetPasswordForm from "./ForgetPassword/ForgetPasswordForm";
/**
 * Login and Forget Password screens Component
 * author Khaled Mofeed
 */
function LoginAdmin(props) {
  const [isLoginPage, setIsloginPage] = useState(true);
  return (
    <section className="background-radial-gradient overflow-hidden">
      <Container fluid className="px-4 py-5 px-md-5 text-center text-lg-start">
        <Row className="justify-content-center">
          <Col md={10} className="position-relative">
            <div
              id="radius-shape-1"
              className="position-absolute rounded-circle shadow-5-strong"
            ></div>
            <div
              id="radius-shape-2"
              className="position-absolute shadow-5-strong"
            ></div>
            <Row className="align-items-center justify-content-center min-height-100 align-items-center bg-border-gradiant contact-form-bg ">
              <Col md={10} lg={7} xl={5}>
                <div className="logo-admin">
                  <img
                    src="/img/logo-admin.svg"
                    alt="logo admin"
                    className="logo-admin-img"
                    id="ID_logo_admin_img"
                  />
                  <img
                    src="/img/text.svg"
                    alt="text logo admin"
                    className="logo-admin-text"
                    id="ID_logo_admin_img2"
                  />
                </div>
                <div className="card bg-glass">
                  {isLoginPage ? (
                    <LoginForm setIsloginPage={setIsloginPage} />
                  ) : (
                    <ForgetPasswordForm setIsloginPage={setIsloginPage} />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default LoginAdmin;
