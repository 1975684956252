import React from 'react';
import './style.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { BrandsImageConfig } from '../../../configs/configWriteAboutUs';
const BrandsImage = BrandsImageConfig;
function WriteAboutUs(props) {
  return (
    <Row className='justify-content-center align-items-center'>
      <Col md={10} xl={9}>
        <div className='WriteAboutUs'>
          <h1 id='id_titleWriteAboutUs'>How write about us </h1>

          <Row className='brands justify-content-md-center m-all-auto'>
            {BrandsImage.map((cardBrandsImage) => (
              <Col xs={6} md='auto' key={cardBrandsImage.id}>
                <img src={cardBrandsImage.image} alt='logo' id={cardBrandsImage.id} />
              </Col>
            ))}
          </Row>
        </div>
      </Col>
    </Row>
  );
}

export default WriteAboutUs;
