import { useState, useRef } from "react";
import { endpoints } from "../../../../utils/networks";
import { request } from "../../../plugins/axios";
import { useAuth } from "../../../scripts/App/auth";
/**
 * (Custom Hook)
 * this custom hook handles Modal 2FA oparations
 * param args
 * returns {{
 * user,
 * isStep1,
 * setIsStep1,
 * isStep2,
 * setIsStep2,
 * isStep3,
 * setIsStep3,
 * isStep4,
 * setIsStep4,
 * isStep5,
 * setIsStep5,
 * liStep2,
 * liStep3,
 * liStep4,
 * liStep5,
 * authenticationInfo,
 * getData2fa}}
 * author Khaled Mofeed
 */
export const useModalTwofa = () => {
  const { user } = useAuth();
  const [isStep1, setIsStep1] = useState(true);
  const [isStep2, setIsStep2] = useState(false);
  const [isStep3, setIsStep3] = useState(false);
  const [isStep4, setIsStep4] = useState(false);
  const [isStep5, setIsStep5] = useState(false);

  const liStep2 = useRef();
  const liStep3 = useRef();
  const liStep4 = useRef();
  const liStep5 = useRef();

  const [authenticationInfo, setAuthenticationInfo] = useState("");
  function getData2fa() {
    request(endpoints._2FA.GetData.method, endpoints._2FA.GetData.url)
      .then((response) => {
        setAuthenticationInfo(response?.data?.data);
      })
      .catch((erorr) => {});
  }

  return {
    user,
    isStep1,
    setIsStep1,
    isStep2,
    setIsStep2,
    isStep3,
    setIsStep3,
    isStep4,
    setIsStep4,
    isStep5,
    setIsStep5,
    liStep2,
    liStep3,
    liStep4,
    liStep5,
    authenticationInfo,
    getData2fa,
  };
};
