import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthAdminProvider } from "../../admin/scripts/App/auth";
import AppRouters from "../../routes";
import { AppContextProvider } from "../../scripts/context";
import { DARK_THEME } from "../../scripts/theme";

const AppRoute = () => {
  return (
    <BrowserRouter>
      <AppContextProvider theme={DARK_THEME}>
        <AuthAdminProvider>
          <Routes>
            {AppRouters?.map((layout) => {
              return (
                <Route
                  element={layout.component}
                  {...(layout.attributes ?? {})}
                  key={"layout-" + layout.path}
                >
                  {layout?.children?.map((page) => (
                    <Route
                      element={page.component}
                      {...(page.attributes ?? {})}
                      key={"page" + page.path}
                    />
                  ))}
                </Route>
              );
            })}
          </Routes>
        </AuthAdminProvider>
      </AppContextProvider>
    </BrowserRouter>
  );
};

export default AppRoute;
