import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
/**
 * (Confirm Delete Modal)
 * this Modal use for Confirm the Delete operation of the user admin
 * author Khaled Mofeed
 */
function DeleteAdminModal(props) {
  function handleDelete() {
    props.onConfirm();
  }
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3 className="card-label" id="ID_DeleteAdmin">
            Delete Admin
            <span
              className="d-block text-muted pt-2 font-size-sm"
              id="ID_lable1"
            >
              This action cannot be undone, please confirm to continue
            </span>
          </h3>
        </Modal.Title>
      </Modal.Header>

      <Modal.Footer>
        <Button
          onClick={props.onHide}
          className="btn btn-light-primary"
          id="ID_Close_Btn"
        >
          Close
        </Button>
        <Button
          onClick={handleDelete}
          className="btn btn-primary"
          id="ID_Delete_Btn"
        >
          Delete It
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteAdminModal;
