import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./lib/font-awesome/css/all.min.css";
import React from "react";
import ToasterCard from "./plugins/Toaster";
import AppRoute from "./plugins/router";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./configs/queryClient";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ToasterCard />
      <AppRoute />
    </QueryClientProvider>
  );
}

export default App;
