import React from "react";
import usePagination from "../../../../hooks/usePagination";

function Pagenation({ setCurrentPage, currentPage, data}) {
  const { currentThree, next, prev, jump } = usePagination({
    setCurrentPage,
    currentPage,
    maxPage: data?.lastPage,
  });

  return (
    <div className="d-flex justify-content-between pagination">
      <div>
        <span className="page-number">Showing 4 of 256 data</span>
      </div>
      {data?.lastPage > 1 ? (
        <div className="d-flex ">
          <span onClick={prev}>Previous</span>
          <ul>
            {currentThree.map((page) => (
              <li
                key={page}
                className={currentPage === page ? "active-page" : "page"}
                onClick={() => jump(page)}
              >
                {page}
              </li>
            ))}
          </ul>
          <span onClick={next}>Next</span>
        </div>
      ) : (
       null
      )}
    </div>
  );
}

export default Pagenation;
