import { Table } from "react-bootstrap";
import { searchOnChannelInRevenues } from "../revenueContractFunctions"

export function RevenueTable(props) {
    /**
   * Revenue list table component
   * author Mahmoud Skaik
   */
    const { revenuesList, channelsList } = props;


    const handleSearchOnChannelInRevenues = ( revenueIndex, channelId) => {
        const searchResult = searchOnChannelInRevenues(revenuesList, revenueIndex, channelId);
        return searchResult;
    }
    return (
        <>
            <span>Revenues List</span>
            <Table >
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>date</th>
                        <th>Total Income</th>
                        <th>Total Claimed</th>
                        {

                            channelsList && channelsList?.map(({ name }, index) => {
                                return (
                                    <th key={'channelCol' + index}>{name}</th>
                                )
                            })

                        }


                    </tr>
                </thead>
                <tbody>
                    {revenuesList?.length === 0 ? (
                        <tr>
                            <td className="table-no-data" colSpan={12}>
                                No Data Found
                            </td>
                        </tr>
                    ) : (
                        revenuesList?.map(({ id, dateAt, totalIncome, totalClaimed, channels }, key) => {
                            return (
                                <tr key={key}>
                                    <td>{id}</td>
                                    <td>{dateAt}</td>
                                    <td>{totalIncome}</td>
                                    <td>{totalClaimed}</td>
                                    {channelsList.map(({ id }, channelIndex) => {
                                        return (
                                            <td key={'channelVal' + channelIndex}>{handleSearchOnChannelInRevenues(key, id)}</td>
                                        );
                                    })}
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>
        </>
    )

}