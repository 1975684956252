import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { CLOSE_ICON } from "../../../constants";

function EventModal({ eventModal, TimeEvent, Attend, loading, ...props }) {
  /**
   * EventModal, Modal used for view the informations of event selected
   * author Khaled Mofeed
   */

  const [isAttend, setIsAttend] = useState(eventModal?.is_attended);
  const [countAttend, setCountAttend] = useState(eventModal?.attendances_count);
  const date = new Date(eventModal?.start_date);
  const formattedDate = date?.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });
  /**
   * handleAttendModal, function used for handle the Attendance of events through modal
   * author Khaled Mofeed
   */
  const handleAttendModal = (id) => {
    setIsAttend(true);
    setCountAttend(countAttend + 1);
    Attend(id);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="EventModal"
    >
      <Modal.Header className="border-0">
        <Modal.Title id="contained-modal-title-vcenter">
          <Col md={12} xl={12} className=""></Col>
        </Modal.Title>
        <Button onClick={props.onHide} variant="outline-secondary closeModal">
          <img src={CLOSE_ICON} alt="close" className="closeModal" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg={12} className="px-0 mb-4 pb-3">
            <img
              src={eventModal?.cover}
              alt="events"
              className="w-100 h-100 eventsImg"
            />
          </Col>
          <Col lg={12} className="px-0 pb-2 border-bottom">
            <Row>
              <Col xs={2}>
                <div className="dateInfo">
                  <h4>{formattedDate}</h4>
                </div>
              </Col>
              <Col xs={10} className="pl-0 align-items-center">
                <div className="titleAndDesc">
                  <h4>{eventModal?.name}</h4>
                  <p className="mb-0">
                    {eventModal?.categories?.map((category) => (
                      <span key={category.id} className="categoryName">
                        {category.name} ,{" "}
                      </span>
                    ))}{" "}
                    Organized by <span>{eventModal?.created_by}</span>
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={12} className="px-0 py-4 border-bottom">
            <Row>
              <Col xs={12}>
                <div className="SatetAt ModalStart">
                  <img src="/img/events/info.svg" alt="start at" />{" "}
                  <h4>Event Description</h4>
                </div>
                <div className="infoMore">
                  <Link to="">---------------------</Link>
                  <p>{eventModal?.description}</p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={12} className="px-0 py-4 border-bottom">
            <Row>
              <Col xs={4} className="align-items-center">
                <div className="SatetAt w-100">
                  <img src="/img/events/start.svg" alt="start at" />{" "}
                  {TimeEvent(
                    eventModal?.start_date,
                    eventModal?.end_date,
                    eventModal?.start_time
                  )}
                </div>
              </Col>
              <Col xs={8}>
                <div className=" text-right">
                  <button
                    disabled={isAttend}
                    onClick={() => {
                      handleAttendModal(eventModal?.id);
                    }}
                    className="btnCustome btn btn-outline-secondary px-4 mr-2 py-2"
                  >
                    {isAttend ? "You Are In Attendance" : "WANT TO GO"}
                    {loading && (
                      <Spinner
                        style={{ marginLeft: ".5rem" }}
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </button>

                  {countAttend ? (
                    <button className="custom-primary btn btn-primary btnCustome px-5 py-2">
                      + {countAttend}{" "}
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={12} className="px-0 py-4 border-bottom">
            <Row>
              <Col xs={6}>
                <div className="SatetAt">
                  <img src="/img/events/location.svg" alt="LightBtn" />{" "}
                  Conference Center
                </div>
              </Col>
              <Col xs={6}>
                <div className="actionLable">
                  <span className="lable-dark">
                    <img src="/img/events/location-light.svg" alt="location" />{" "}
                    {eventModal?.location?.substring(0, 6) + "..."}
                  </span>
                  <span className="lable-LightBtn">
                    <img src="/img/events/LightBtn.svg" alt="location" />
                  </span>
                </div>
              </Col>
            </Row>
          </Col>

          <Col lg={12} className="px-0 pt-4">
            <div className="ActionJump">
              <button className="custom-primary btn btn-primary btnCustome w-100 mr-2">
                JUMP IN
                <img
                  src="/img/events/arrowRight.svg"
                  alt="arrowRight"
                  className="arrowRight"
                />
              </button>
              <img
                src="/img/events/share.svg"
                alt="share"
                className="shareBtn"
              />
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default EventModal;
