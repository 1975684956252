import CustomTable from "../../../../components/CustomTable";
import { schedule } from "../../vestingContractFunctions";
import "./style.css";

function VestingTable() {
  return (
    <div className="shared-space">
      <h2 className="table-label mt-0">Vesting Schedule</h2>
      <CustomTable cb={schedule} currentPage={1} no={10} />
    </div>
  );
}

export default VestingTable;
