import React from "react";
import { Container } from "react-bootstrap";
import Hero from "./components/Hero";
import TeleverseWorld from "./components/TeleverseWorld";
import WhatTeleverse from "./components/WhatTeleverse";
import Portals from "./components/Powered";
import Value from "./components/Value";
import Marketplace from "./components/MarketPlace";
import Token from "./components/Token";
import Advisors from "../../components/home&token/Advisors";
import Footer from "../Layout/Footer";
import GetTouch from "../../components/home&token/GetTouch";
import JoinTELV from "../../components/home&token/JoinTELV";
import Team from "../../components/home&token/Team";
import WriteAboutUs from "../../components/home&token/WriteAboutUs";
import MainHeader from "../Layout/Header";
import styles from './style.module.css'

function index(){
  return (
      <div className={styles.homeFlex}>
      <Container fluid className={styles.heroSection}>
        <MainHeader />
        <Hero />
      </Container>
      <Container fluid>
        <TeleverseWorld />
        <WhatTeleverse />
        <JoinTELV />
      </Container>
      <Container fluid>
        <Portals />
        <Value />
        <Marketplace />
      </Container>
      <Container fluid>
        <Token />
        <Team negativeMargin={false} />
      </Container>
      <Container fluid className="advisory-bg">
        <Advisors />
      </Container>
      <Container fluid className="WriteAboutUs-bg">
        <WriteAboutUs />
      </Container>
      <Container fluid>
        <GetTouch />
      </Container>
      <Container fluid>
        <Footer />
      </Container>
    </div>
  );
};


export default index;
