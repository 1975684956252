/**
 * (Filter Form)
 * this Form used for Filter the Data to search the Event
 * author Mahmoud Skaik
 */
const initFilterData = {
  status: true,
  data: {
    creator_wallet_address: "",
  },
};

export default function FilterForm({ filter, setFilter, handleSearch }) {
  const handleChanges = (event) => {
    setFilter({
      ...filter,
      data: {
        ...filter.data,
        [event.target.name]: event.target.value,
      },
    });
  };
  const handleClearSearch = () => {
    setFilter(initFilterData);
    handleSearch(initFilterData);
  };

  return (
    <div className="col-md-12 col-lg-12">
      <form id="packageSearchForm" className="row">
        <div className="col col-md-8 col-lg-8 mb-2  ">
          <div className="input-icon">
            <input
              type="search"
              className="form-control"
              placeholder="Creator Wallet Address"
              id="namcreator_wallet_address"
              name="creator_wallet_address"
              value={filter.data.creator_wallet_address}
              onChange={handleChanges}
            />
            <span>
              <i className="fas fa-search" id="ID_search_icon"></i>
            </span>
          </div>
        </div>
  
        <div className="col-md-4 col-lg-4 mb-2">
          <div className="d-flex align-items-center">
            <button
              className="btn btn-primary py-2 px-4 w-50 px-lg-6  "
              type="button"
              onClick={handleSearch}
              id="ID_Search"
            >
              Search
            </button>
            <button
              type="reset"
              className="btn btn-light-primary py-2 px-4 px-lg-6 w-50 ms-2"
              onClick={handleClearSearch}
              id="ID_Reset"
            >
              Reset
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
