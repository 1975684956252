import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../Layout/Footer";
import MainHeader from "../../Layout/Header";
import SliderEvents from "../components/SliderEvents";
import AllEvents from "./AllEvents";
import Filter from "./Filter";
import Trending from "./Trending";
function ContainerPage(props) {
  // this is the Container of Event Page components have:
  // SliderEvents , Slide component to view the active and live Events
  // Trending, component to view the active and trend events
  // Filter, component for fillter the data tha viewed in the AllEvents component
  // AllEvents, Component for view all the events in the system by fillterd
  // | khaled Mofeed

  return (
    <>
      <Container fluid>
        <MainHeader />
        <Row className="justify-content-center DistributionVesting">
          <Col md={12} xl={10} className="mb-0">
            <Row>
              {/* the Right side or the content space of profile components| khaled Mofeed   */}
              <SliderEvents />
              <Trending />
              <Col md={12} xl={12}>
                <Row>
                  <Filter />
                  <AllEvents />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

export default ContainerPage;
