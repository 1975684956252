import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import VerificationInput from "react-verification-input";
import { useForm } from "../../layouts/forms/actions";
export default function TowFAComponent(props) {
  /**
   * use the useForm custom hook to handle the operations
   * of forms
   * author Khaled Mofeed
   */
  const { code, handelLogin_2FASubmit } = useForm();
  /**
   * function use for back to home page
   * author Khaled Mofeed
   */
  const handelCancelLogin = async () => {
    props.setTwoFA(false);
    props.setLoading(false);
  };

  return (
    <div>
      <Button
        className="times-cancel"
        onClick={handelCancelLogin}
        id="ID_Cancel"
      >
        <i className="fas fa-times"></i>
      </Button>
      <div className="text-center mb-10 mb-lg-10">
        <h2 className="font-weight-bold" id="ID_Welcome">
          Welcome
        </h2>
        <p className="text-white font-weight-bold" id="ID_Code">
          Enter Your Google Authentication Code.
        </p>
      </div>
      <p className="auth-code-p color-theme text-center mb-10" id="ID_Code2">
        Google Authenticator Code
      </p>
      <VerificationInput
        length={6}
        onChange={handelLogin_2FASubmit}
        value={code}
        placeholder="."
        autoFocus
        id="ID_VerificationInput"
        inputProps={{
          autoFocus: true,
        }}
      />
      <Box
        sx={{
          margin: "0 auto",
          padding: "20px 0",
        }}
      ></Box>
    </div>
  );
}
