export const PaginationData = {
  page: 1,
  pagesCount: -1,
  rowsPerPage: 10,
  rowsPerPageOptions: [5, 10, 25, 50, 100],
  tableHead: [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "phone",
      numeric: false,
      disablePadding: false,
      label: "Phone",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "Actions",
    },
  ],
  tableItems: [],
};
