import React, { useState } from "react";
import "./style.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CardEventSlider from "./CardEventSlider";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SettingsSlider } from "../../../../configs/configEvents";
import { Link } from "react-router-dom";
import { useTimeEvent } from "../../Hooks/useTimeEvent";
import { HTTP_IN_ACTIVE } from "../../../../constants";
import { useAttendance } from "../../Hooks/useAttendance";
import EventModal from "../EventModal";

/*  SliderEvents component for Render Active Events 
*  Defining the settings for slider through the config Settings Slider file
 | khaled Mofeed */
const settings = SettingsSlider;
function SliderEvents() {
  /**
   * use the useTimeEvent custom hook to handle the operations
   * and import TimeEvent, AllEvent, LiveState from it
   * author Khaled Mofeed
   */
  const { TimeEvent, AllEvent, LiveState } = useTimeEvent();
  const [modalShow, setModalShow] = useState(false);
  const [eventModal, setEventModal] = useState({});
  const { loading, Attend } = useAttendance();
  /**
   * handleModalClick, function used for view the data of event selected by modal
   * author Khaled Mofeed
   */
  const handleModalClick = (e) => {
    setModalShow(true);
    const EventId = AllEvent?.find(
      (AllEventDetails) => AllEventDetails?.id == e.target.id
    );
    setEventModal(EventId);
  };
  return (
    <>
      <Col md={12} xl={12} className="mobile-size">
        <Row className={`justify-content-center align-items-center Team`}>
          <Col md={12} xl={12} className="mb-3">
            <Row>
              <Col lg={7}></Col>
              <Col lg={5}>
                <Link
                  to="/submit-events"
                  className="custom-primary btn btn-primary px-4 py-2 float-right"
                  id="submit_events"
                >
                  <i className="fas fa-plus mr-2"></i> SUBMIT EVENT
                </Link>
              </Col>
            </Row>
          </Col>
          <Col md={12} xl={12}>
            {/* displaying active Events data in Down of XL screen | khaled Mofeed */}
            <Row className=" owl-theme owl-stage-outer-pb-5 pb-0 d-block">
              <Slider {...settings} className="owl-carousel-team EventSlider">
                {AllEvent?.map(
                  (AllEventDetails, index) =>
                    TimeEvent(
                      AllEventDetails.start_date,
                      AllEventDetails.end_date
                    ) !== HTTP_IN_ACTIVE &&
                    TimeEvent(
                      AllEventDetails.start_date,
                      AllEventDetails.end_date
                    ) === LiveState && (
                      <CardEventSlider
                        AllEventDetails={AllEventDetails}
                        TimeEvent={TimeEvent}
                        key={index + 1}
                        handleModalClick={handleModalClick}
                      />
                    )
                )}
              </Slider>
            </Row>
          </Col>
        </Row>
      </Col>
      <EventModal
        key={eventModal?.id}
        show={modalShow}
        onHide={() => setModalShow(false)}
        eventModal={eventModal}
        TimeEvent={TimeEvent}
        Attend={Attend}
        loading={loading}
      />
    </>
  );
}

export default SliderEvents;
