import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './style.css';


function Closed() {
  return (
    <Row className='justify-content-md-center mb-50'>
      <Col md={10} className=' ds-closed-bg '>
        <Row className='min-hight-80 justify-content-md-center align-self-center align-items-lg-center'>
          <Col md={10} className='align-lg-self-center'>
            <Row className='align-self-center'>
              <Col lg={6} className='ds-text'>
                  <div className='start-soon-text'>
                    <h1>Digital Sharing Sale</h1>
                    <h3>IS OVER</h3>
                  </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Closed;