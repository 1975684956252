import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { useEffect } from "react";
import { isEqual } from "lodash";
import { useRoleModal } from "../Hooks/useRoleModal";
/**
 * (Edit || Add New Role Modal)
 * this Modal use for Edit |OR| Add New Role
 * author Khaled Mofeed
 */
export default function RoleModal({
  data,
  setData,
  getRoles,
  handleClose,
  ...props
}) {
  /**
   * use the useRoleModal custom hook to handle the operations
   * author Khaled Mofeed
   */

  const {
    isLoading,
    ALL_PERMISSIONS,
    ALL_PERMISSIONS_OF_ROLES,
    addRole,
    editRole,
  } = useRoleModal(data, setData, getRoles, handleClose);

  useEffect(() => {
    if (
      isEqual(
        ALL_PERMISSIONS.map((permission) => permission.name),
        data.data.permissions.map((permission) => permission.name)
      )
    ) {
      return setData({
        roles: ["administrator"],
      });
    }

    if (data.data.roles?.includes("administrator")) return;

    if (!data.data.permissions.length) {
      setData({ roles: [] });
    }
  }, [data.data.permissions]);

  return (
    <Modal
      {...props}
      show={data.status}
      onHide={handleClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3 className="card-label" id="ID_name_modal">
            {data.edit ? `Edit ${data.data.name} Role` : "Add New Role"}

            <span className="d-block text-muted pt-2 font-size-sm">
              {!data.edit && (
                <FormGroup
                  className="Switch-div"
                  sx={{ maxWidth: "fit-content", justifyContent: "center" }}
                >
                  <FormControlLabel
                    control={<Switch checked={data.data.status} />}
                    label={data.data.status ? "Active" : "Inactive"}
                    onChange={(event) =>
                      setData({
                        ...data.data,
                        status: event.target.checked ? 1 : 0,
                      })
                    }
                  />
                </FormGroup>
              )}
            </span>
          </h3>
        </Modal.Title>
      </Modal.Header>
      <ModalBody className="p-17">
        <div className="row align-items-center mt-3 mb-4">
          <div className="col-md-12 ">
            <div className="card-title">
              <h6 className="card-label" id="ID_General">
                General Information
              </h6>
            </div>
          </div>
          <div className="col-md-12 col-lg-12">
            <div id="addAdmin" className="row  admin-settings">
              <div className="col col-md-3 col-lg-4 mb-2">
                <div className="input-icon">
                  <input
                    fullWidth
                    id={`filled-role-name`}
                    name="name"
                    value={data.data.name}
                    onChange={(event) => {
                      setData({ name: event.target.value });
                    }}
                    autoFocus={data.data.name !== ""}
                    placeholder="Role Name"
                    className={`form-control`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 ">
            <div className="card-title">
              <h6 className="card-label mb-2 permission" id="ID_Permissions">
                Permissions
              </h6>
            </div>
          </div>
          {ALL_PERMISSIONS_OF_ROLES}
        </div>
      </ModalBody>
      <Modal.Footer>
        <Button
          onClick={handleClose}
          className="btn btn-light-primary"
          disabled={isLoading}
          id="ID_Close"
        >
          Close
        </Button>
        <Button
          className="btn btn-primary"
          type="button"
          disabled={isLoading}
          id="ID_isLoading"
          onClick={data.edit ? editRole : addRole}
        >
          {data.edit ? "Edit" : "Create"}
          {isLoading && (
            <Spinner
              style={{ marginLeft: ".5rem" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              id="ID_Spinner1"
            />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
