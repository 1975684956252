import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './style.css';

function index() {
  return (
    <Row className='justify-content-md-center'>
      <Col md={10} className='bg-token-is-closed'>
        <Row className='min-hight-80-closed justify-content-md-center align-self-center'>
          <Col xs={10} >
            <Row className='h-100'>
              <Col lg={5} md={7} xs={8} className='align-self-center justify-content-center closed-token'>
                <h1>Token Private Sale</h1>
                <h3>IS OVER</h3>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default index;
