import React from "react";
import { useForm } from "../../../../layouts/forms/actions";
import { Spinner } from "react-bootstrap";

function ChangePasswordForm(props) {
  /**
   * use the useForm custom hook to handle the operations
   * of forms
   * author Khaled Mofeed
   */
  const { form, handelChangesPasswordSubmit, loading, setValue, errors } =
    useForm({
      old_password: "",
      password: "",
      password_confirmation: "",
    });
  /**
   * extract the attributes of form
   * author Khaled Mofeed
   */
  const { old_password, password, password_confirmation } = form;

  return (
    <>
      <div className="separator separator-dashed my-10"></div>

      <form className="form" onSubmit={handelChangesPasswordSubmit}>
        <div className="row">
          <label className="col-xl-3"></label>
          <div className="col-lg-5 col-xl-5">
            <h6 className="font-weight-bold mb-6" id="id_ChangePassword_title">
              Change Password:
            </h6>
          </div>
        </div>

        <div className="form-group row  mb-3">
          <label
            className="col-xl-3 col-lg-3 col-form-label"
            id="id_CurrentPassword_lable"
          >
            Current Password
          </label>
          <div className="col-lg-5 col-xl-5">
            <input
              className={`form-control ${errors.old_password && "is-invalid"}`}
              placeholder="Old Password"
              value={old_password}
              onChange={setValue}
              name="old_password"
              id="old_password"
              variant="standard"
              type="password"
            />
            {errors.old_password && (
              <div
                className="invalid-feedback"
                id="id_CurrentPassword_feedback"
              >
                {errors.old_password}
              </div>
            )}
          </div>
        </div>

        <div className="form-group row  mb-3">
          <label
            className="col-xl-3 col-lg-3 col-form-label"
            id="id_New_Password_lable"
          >
            New Password
          </label>
          <div className="col-lg-5 col-xl-5">
            <input
              className={`form-control ${errors.password && "is-invalid"}`}
              placeholder="New password"
              value={password}
              onChange={setValue}
              name="password"
              id="password"
              type="password"
            />
            {errors.password && (
              <div className="invalid-feedback">{errors.password}</div>
            )}
          </div>
        </div>

        <div className="form-group row  mb-3">
          <label
            className="col-xl-3 col-lg-3 col-form-label"
            id="id_Confirm_Password_lable"
          >
            Confirm Password
          </label>
          <div className="col-lg-5 col-xl-5">
            <input
              className={`form-control ${
                errors.password_confirmation && "is-invalid"
              }`}
              placeholder="Confirm Password"
              value={password_confirmation}
              onChange={setValue}
              name="password_confirmation"
              id="password_confirmation"
              type="password"
            />
            {errors.password_confirmation && (
              <div
                className="invalid-feedback"
                id="id_Confirm_Password_feedback"
              >
                {errors.password_confirmation}
              </div>
            )}
          </div>
        </div>

        <div className="card-toolbar text-right">
          <button
            id="id_Password__submit"
            className="btn btn-primary mr-2"
            type="submit"
            name="submit"
            value="Submit"
            disabled={loading}
          >
            Change Password
            {loading && (
              <Spinner
                style={{ marginLeft: ".5rem" }}
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
      </form>
    </>
  );
}

export default ChangePasswordForm;
