import React from "react";
import { Col, Row } from "react-bootstrap";
import Timer from "./timer";
import "./style.css";

function ComingSoon(props) {
  return (
    <Row className="justify-content-md-center mb-50">
      <Col md={10} className=" ds-bg ">
        <Row className="min-hight-80 justify-content-md-center align-self-center align-items-lg-center">
          <Col md={10} className="align-lg-self-center">
            <Row className="align-self-center">
              <Col className="ds-text">
                <Row>
                  <div className="start-soon-text textLeft">
                    <h1>Digital Share Sale</h1>
                    <h3>STARTS SOON</h3>
                  </div>
                </Row>
                <Timer {...props} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ComingSoon;
