import React from "react";
import { NavLink } from "react-router-dom";
function SortMapLis(props) {
  /**
   * SortMapLis, component for view the date by List or by Map
   * author Khaled Mofeed
   */
  return (
    <>
      <div className="col-12 mb-4">
        <div className="FillterBySort">
          {/* <div className="sortDiv pointer">
            <img src="/img/Sort.svg" alt="sort" className="SortImg" /> Sort{" "}
          </div> */}
          <div className="sortDiv pointer">
            <NavLink to="/map">
              <img src="/img/locate.svg" alt="sort" className="SortImg" /> Map{" "}
            </NavLink>
          </div>
          <div className="sortDiv pointer">
            <NavLink to="/marketplace">
              <img src="/img/list.svg" alt="sort" className="SortImg" /> List{" "}
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default SortMapLis;
