export const SeriesData = [
  {
    name: "Marketplace Rewards",
    data: [
      0, 52000000, 38000000, 24000000, 33000000, 26000000, 21000000, 20000000,
      6000000, 8000000, 15000000, 10000000,
    ],
  },
  {
    name: "Exchange Liquidity",
    data: [
      0, 41000000, 62000000, 42000000, 13000000, 18000000, 29000000, 37000000,
      36000000, 51000000, 32000000, 35000000,
    ],
  },
  {
    name: "Foundation",
    data: [
      0, 57000000, 74000000, 99000000, 75000000, 38000000, 62000000, 47000000,
      82000000, 56000000, 45000000, 47000000,
    ],
  },
  {
    name: "Team",
    data: [
      0, 12000000, 22000000, 32000000, 42000000, 52000000, 62000000, 72000000,
      82000000, 92000000, 72000000, 92000000,
    ],
  },
  {
    name: "Advisories",
    data: [
      0, 15000000, 25000000, 35000000, 45000000, 55000000, 65000000, 75000000,
      85000000, 95000000, 75000000, 95000000,
    ],
  },
  {
    name: "Marketing",
    data: [
      0, 19000000, 29000000, 39000000, 49000000, 59000000, 69000000, 79000000,
      89000000, 99000000, 79000000, 95000000,
    ],
  },
  {
    name: "Seed Sale",
    data: [
      0, 11000000, 22000000, 33000000, 44000000, 55000000, 66000000, 77000000,
      88000000, 99000000, 75000000, 91000000,
    ],
  },
  {
    name: "Staking Rewards",
    data: [
      0, 10000000, 20000000, 30000000, 40000000, 50000000, 60000000, 70000000,
      80000000, 90000000, 70000000, 90000000,
    ],
  },
  {
    name: "public Sale",
    data: [
      0, 18000000, 29000000, 33000000, 42000000, 59000000, 69000000, 77000000,
      81000000, 92000000, 79000000, 95000000,
    ],
  },
];
export const OptionsData = {
  chart: {
    height: 400,
    type: "line",
    color: "#ffffff",
    zoom: {
      enabled: false,
    },
  },
  //   colors: ["#008FFB", "#00E396", "#CED4DC"],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: [2, 2, 2],
    curve: "straight",
    dashArray: [0, 1, 2],
  },
  title: {
    text: " ",
    align: "left",
  },
  legend: {
    tooltipHoverFormatter: function (val, opts) {
      return val;
      // +
      // " - " +
      // opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
      // ""
    },
  },
  markers: {
    size: 0,
    hover: {
      sizeOffset: 6,
    },
  },
  xaxis: {
    type: "datetime",
    categories: [
      "01 Jan",
      "02 Jan",
      "03 Jan",
      "04 Jan",
      "05 Jan",
      "06 Jan",
      "07 Jan",
      "08 Jan",
      "09 Jan",
      "10 Jan",
      "11 Jan",
      "12 Jan",
    ],
  },
  tooltip: {
    y: [
      {
        title: {
          formatter: function (val) {
            return val + " (mins)";
          },
        },
      },
      {
        title: {
          formatter: function (val) {
            return val + " per session";
          },
        },
      },
      {
        title: {
          formatter: function (val) {
            return val;
          },
        },
      },
    ],
  },
  grid: {
    borderColor: "#3b3741",
    borderStyle: "dashed",
    color: "#f00",
  },
};
export const PolarChartData = [
  {
    country: "Staking Rewards",
    value: 10,
  },
  {
    country: "Maretplace Rew...",
    value: 10,
  },
  {
    country: "Marketing",
    value: 5,
  },
  {
    country: "Seed Sale",
    value: 5,
  },
  {
    country: "Public Sale",
    value: 5,
  },
  {
    country: "Advisorios",
    value: 5,
  },
  {
    country: "Team",
    value: 15,
  },
  {
    country: "Exchange Liquidity",
    value: 20,
  },
  {
    country: "Foundation",
    value: 20,
  },
];
