import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./style.css";
/*  This Component for displaying The Road Map | khaled Mofeed | 29/10/2022 */
function RoadMap(props) {
  return (
    <Row className="justify-content-center DistributionVesting min-height-100per">
      <Col md={10} xl={9}>
        <div className="Release roadmap-release">
          <h2 id="id_titleRoadMap">Road Map</h2>
          <img
            src="/img/infographic.svg"
            alt="chart"
            className="chart3 d-none d-md-block"
            id="id_imgRoadMap"
          />
          <img
            src="/img/roadmap-mobile.svg"
            alt="chart"
            className="chart3 d-block d-md-none"
            id="id_imgMobileRoadMap"
          />
        </div>
      </Col>
    </Row>
  );
}

export default RoadMap;
