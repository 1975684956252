import Lottie from 'react-lottie-player';
import lottieJson from './lottie-loader.json';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './style.css';
function AppLoader() {
  return (
    <Row className='justify-content-md-center'>
      <Col sm={10} className=' my-40'>
        <div className='w-50 mx-auto'>
          <Lottie className='lottiePlayer' loop animationData={lottieJson} play />
          {/* <h1 className='mx- mt-5'>
            Loading Your Page
          </h1> */}
        </div>
      </Col>
    </Row>
  );
}

export default AppLoader;
