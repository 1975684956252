import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { createChannel } from "../revenueContractFunctions"
import { useState } from "react";
import { toast } from "react-hot-toast";

export default function ChannelModal({
  handleClose,
  getChannelsList,
  revenueStatisticsData,
  ...props
}) {

  /**
* Create channel modal component
* author Mahmoud Skaik
*/

  const [channelNameState, setChannelNameState] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateChannel = async () => {
    setIsLoading(true)
    try {
      const tx = await createChannel(channelNameState)

      const response = await tx.wait();
      if (response.status == 1) {
        setChannelNameState('')
        getChannelsList();
        revenueStatisticsData();
        handleClose();
        toast.success('Created Successfully')
      } else {
        toast.error('Created Failed')
      }


    } catch (error) {
      toast.error(JSON.parse(JSON.stringify(error)).reason)
    } finally {
      setIsLoading(false);
    }

  }


  return (
    <Modal
      {...props}
      onHide={() => {
        setChannelNameState('');
        setIsLoading(false);
        handleClose();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3 className="card-label mb-0" id="ID_name_modal">
            Create New Channel
          </h3>
        </Modal.Title>
      </Modal.Header>
      <ModalBody className="px-5">
        <form className="row">
          <div className="col col-md-12 col-lg-12 mb-2  ">
            <div className="input-icon">
              <input
                type="text"
                className="form-control"
                placeholder="Channel Name"
                id="channel_name"
                name="channel_name"
                value={channelNameState}
                onChange={(e) => setChannelNameState(e.target.value)}
              />
              <span>
              </span>
            </div>
          </div>
        </form>
      </ModalBody>
      <Modal.Footer>
        <Button
          className="btn btn-light-primary"
          onClick={() => {
            setChannelNameState('');
            setIsLoading(false);
            handleClose()
          }}

          disabled={isLoading}
          id="ID_Close"
        >
          Close
        </Button>
        <Button
          onClick={() => handleCreateChannel()}

          className="btn btn-success"
          disabled={isLoading}
          id="ID_CREATE_CHANNEL"
        >
          Create Channel
          {isLoading && (
            <Spinner
              style={{ marginLeft: ".5rem" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              id="ID_Spinner1"
            />
          )}
        </Button>


      </Modal.Footer>
    </Modal>
  );
}