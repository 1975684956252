import React, { useContext } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { request } from "../../../admin/plugins/axios";
import VerificationIcon from "../../../assets/home/marketplace/Vector.svg";
import { copyToClipboard } from "../../../Helper/Helper";
import { AppContext } from "../../../scripts/context";
import { endpoints } from "../../../utils/networks";
import { Spinner } from "react-bootstrap";

function FollowModal({ following, ...props }) {
  const { userData, followData, setFollowData, setLoading, loading } =
    useContext(AppContext);
  const followUpdate = async (address) => {
    setLoading(true);
    request(
      endpoints.Following.Follow_UnFollow.method,
      endpoints.Following.Follow_UnFollow.url,
      { wallet_address: address }
    )
      .then((response) => {
        setLoading(false);
        console.log("response", response);
        toast.success(response?.data.message);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  function copyAdressToClipboard() {
    copyToClipboard(userData?.wallet_address);
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="FollowModal"
    >
      <Modal.Header className="border-0">
        <Modal.Title id="contained-modal-title-vcenter">
          <Col md={12} xl={12} className="">
            <h5 className="nickName">{userData.name}</h5>
            <p className="addressLable mb-0">
              {userData?.wallet_address &&
                userData?.wallet_address.substring(0, 10) +
                  "..." +
                  userData?.wallet_address.substring(
                    userData?.wallet_address.length - 4
                  )}
              <Link className="copyBtn">
                <img
                  src="/img/Copy-3.svg"
                  onClick={copyAdressToClipboard}
                  alt="copy"
                />
              </Link>
            </p>
          </Col>
        </Modal.Title>
        <Button onClick={props.onHide} variant="outline-secondary closeModal">
          <img src="/img/close.svg" alt="close" className="closeModal" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          defaultActiveKey={following ? "Following" : "Followers"}
          id="justify-tab-example"
          justify
        >
          <Tab eventKey="Following" title="Following">
            <Row>
              {followData &&
                followData?.followings?.map((followingsData) => {
                  return (
                    <Col md={12} xl={12} key={followingsData?.wallet_address}>
                      <div className="follow-person">
                        <div className="d-flex align-items-center ">
                          <div className="user-image">
                            <Image
                              src={followingsData?.avatar}
                              onError={(even) => {
                                even.target.src = `/img/user.png`;
                              }}
                              alt="profile picture"
                              className="profile_picture"
                            />
                            <Image
                              className="verification-badge w-14"
                              src={VerificationIcon}
                              alt="verified"
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <span className="card-owner">
                              {followingsData?.name}
                            </span>
                            <span className="card-owner-name">
                              {followingsData?.wallet_address &&
                                followingsData?.wallet_address.substring(
                                  0,
                                  10
                                ) +
                                  "..." +
                                  followingsData?.wallet_address.substring(
                                    followingsData?.wallet_address.length - 4
                                  )}
                            </span>
                          </div>
                        </div>
                        {followingsData?.is_followed ? (
                          <Button
                            variant="outline-secondary"
                            className="btnCustome"
                            disabled={loading}
                            onClick={() =>
                              followUpdate(followingsData?.wallet_address)
                            }
                          >
                            Un Follow{" "}
                            {loading && (
                              <Spinner
                                style={{ marginLeft: ".5rem" }}
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            className="custom-primary btnFollow btnCustome"
                            id="id_Follow"
                            disabled={loading}
                            onClick={() =>
                              followUpdate(followingsData?.wallet_address)
                            }
                          >
                            Follow{" "}
                            {loading && (
                              <Spinner
                                style={{ marginLeft: ".5rem" }}
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </Button>
                        )}
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </Tab>
          <Tab eventKey="Followers" title="Followers">
            <Row>
              {followData &&
                followData?.followers?.map((followersData) => {
                  return (
                    <Col md={12} xl={12} key={followersData?.wallet_address}>
                      <div className="follow-person">
                        <div className="d-flex align-items-center ">
                          <div className="user-image">
                            <Image
                              src={followersData?.avatar}
                              onError={(even) => {
                                even.target.src = `/img/user.png`;
                              }}
                              alt="profile picture"
                              className="profile_picture"
                            />
                            <Image
                              className="verification-badge w-14"
                              src={VerificationIcon}
                              alt="verified"
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <span className="card-owner">
                              {followersData?.name}
                            </span>
                            <span className="card-owner-name">
                              {followersData?.wallet_address &&
                                followersData?.wallet_address.substring(0, 10) +
                                  "..." +
                                  followersData?.wallet_address.substring(
                                    followersData?.wallet_address.length - 4
                                  )}
                            </span>
                          </div>
                        </div>
                        {followersData?.is_followed ? (
                          <Button
                            variant="outline-secondary"
                            className="btnCustome"
                            disabled={loading}
                            onClick={() =>
                              followUpdate(followersData?.wallet_address)
                            }
                          >
                            Un Follow
                            {loading && (
                              <Spinner
                                style={{ marginLeft: ".5rem" }}
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            className="custom-primary btnFollow btnCustome"
                            id="id_Follow"
                            disabled={loading}
                            onClick={() =>
                              followUpdate(followersData?.wallet_address)
                            }
                          >
                            Follow
                            {loading && (
                              <Spinner
                                style={{ marginLeft: ".5rem" }}
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </Button>
                        )}
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}

export default FollowModal;
