import React, { useEffect } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Price from "./Price";
import {
  StyledDataCell,
  StyledHeadCell,
  StyledTablePaper,
  StyledTableRow,
} from "./styled";
import { useQuery } from "react-query";
import { usePurchaseHistory } from "../../../../../hooks/useMarketplace";
const mockData = [
  {
    from: "x0asd2edd",
    price: 200,
    date: "10/10/2022",
    timeLeft: "10:00:00",
  },
];

const PurchaseHistory = ({ tokenId, needToRefetch }) => {
  const { data, isLoading, error, refetch } = usePurchaseHistory(tokenId, 1);
  const purchaseHistory = data?.data?.data.transactions;

  useEffect(() => {
    if (needToRefetch) {
      refetch();
    }
  }, [needToRefetch]);
  return (
    <TableContainer component={StyledTablePaper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledHeadCell>From</StyledHeadCell>
            <StyledHeadCell>Price</StyledHeadCell>
            <StyledHeadCell>Purchase Date</StyledHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {console.log("purchaseHistory", purchaseHistory)}
          {purchaseHistory?.length !== 0 ? (
            purchaseHistory?.map((item, index) => (
              <StyledTableRow
                key={index + item.created_at}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledDataCell>
                  <div className="userInfo">
                    <img
                      src={item.from.avatar}
                      className="avatarImgOffer"
                      alt={item.from.name}
                    />
                    {item.from.name}
                  </div>
                </StyledDataCell>
                <StyledDataCell>
                  <Price
                    icon="/img/telv_filled.svg"
                    price={item.price}
                    symbol={""}
                  />
                </StyledDataCell>
                <StyledDataCell>{item.created_at.split(" ")[0]}</StyledDataCell>
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                  padding: "40px",
                },
              }}
            >
              <StyledDataCell className="mx-auto text-center " colSpan={3}>
                No purchase history
              </StyledDataCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PurchaseHistory;
