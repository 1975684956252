import { useAuth } from "../../../scripts/App/auth";
import Form from "react-bootstrap/Form";
import { Box, Chip, IconButton } from "@mui/material";
import MultipleSelect from "../../../conmponents/MultipleSelect";
/**
 * (Filter Form)
 * this Form used for Filter the Data to search the users admin
 * author Khaled Mofeed
 */
const initFilterData = {
  status: true,
  data: {
    permissions: "",
    full_name: "",
    email: "",
    roles: [],
    status: "",
  },
};

export default function FilterForm({ filter, setFilter, handleSearch }) {
  const { roles_permissions } = useAuth();
  const handleChanges = (event) => {
    setFilter({
      ...filter,
      data: {
        ...filter.data,
        [event.target.name]: event.target.value,
      },
    });
  };
  const handleClearSearch = () => {
    setFilter(initFilterData);
    handleSearch(initFilterData);
  };

  return (
    <div className="col-md-12 col-lg-12">
      <form id="packageSearchForm" className="row">
        <div className="col col-md-3 col-lg-4 mb-2  ">
          <div className="input-icon">
            <input
              type="search"
              className="form-control"
              placeholder="Full Name"
              id="name"
              name="full_name"
              value={filter.data.name}
              onChange={handleChanges}
            />
            <span>
              <i className="fas fa-search"></i>
            </span>
          </div>
        </div>
        <div className="col col-md-4 col-lg-4 mb-2  ">
          <div className="input-icon">
            <input
              type="search"
              className="form-control"
              placeholder="Email"
              id="email"
              name="email"
              value={filter.data.email}
              onChange={handleChanges}
            />
            <span>
              <i className="fas fa-search  "></i>
            </span>
          </div>
        </div>

        <div className="col col-md-4 col-lg-4 mb-2  ">
          <div className="input-icon">
            <Form.Select
              name="permission"
              id="ID_Select"
              onChange={({ target }) => {
                setFilter({
                  ...filter,
                  data: {
                    ...filter.data,
                    permissions: target.value,
                  },
                });
              }}
            >
              <option value=" ">Custom Permissions</option>
              <option value="withPermission">Has Permissions </option>
              <option value="withoutPermission">Has Not Permissions</option>
            </Form.Select>

            <span>
              <i className="fas fa-search  "></i>
            </span>
          </div>
        </div>
        <div className="col col-md-4 col-lg-4 mb-2">
          <div className="input-icon">
            <Form.Select
              name="status"
              id="ID_Select_status"
              onChange={({ target }) => {
                setFilter({
                  ...filter,
                  data: {
                    ...filter.data,
                    status: target.value,
                  },
                });
              }}
            >
              <option value="">Status</option>
              <option value="Active">Active</option>
              <option value="Inactive">In Active</option>
            </Form.Select>
            <span id="ID_Select_search">
              <i className="fas fa-search  "></i>
            </span>
          </div>
        </div>
        <div className="col col-md-4 col-lg-4 mb-2  d-flex">
          <MultipleSelect
            className="search-div"
            label="Roles"
            id="ID_Roles_search"
            handleChange={({ target }) => {
              const newRoles =
                typeof target.value === "string"
                  ? target.value.split(",")
                  : target.value;

              setFilter({
                ...filter,
                data: {
                  ...filter.data,
                  roles: newRoles,
                },
              });
            }}
            value={filter.data.roles}
            options={roles_permissions
              .filter((role) => role.status === "Active")
              .map((role) => ({
                value: role.name,
                label: role.name,
              }))}
            renderValue={(selected) => (
              <Box
                id="ID_Roles_Box_search"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      id="ID_Roles_Chip_search"
                      variant="outlined"
                      key={value}
                      label={value}
                      onDelete={() => {
                        const newRoles = filter.data.roles.filter(
                          (role) => role !== value
                        );
                        setFilter({
                          ...filter,
                          data: {
                            ...filter.data,
                            roles: newRoles,
                          },
                        });
                      }}
                      clickable
                      deleteIcon={
                        <i
                          className="fas fa-times"
                          onMouseDown={(event) => event.stopPropagation()}
                        ></i>
                      }
                    />
                  ))}
                </Box>
                {selected.length > 0 && (
                  <IconButton
                    id="ID_Roles_IconButton_search"
                    onMouseDown={(event) => event.stopPropagation()}
                    onClick={() => {
                      setFilter({
                        ...filter,
                        data: {
                          ...filter.data,
                          roles: [],
                        },
                      });
                    }}
                  >
                    <span color="error"></span>
                  </IconButton>
                )}
              </Box>
            )}
            sx={{ flex: "1 1 40%" }}
          />
        </div>

        <div className="col-md-4 col-lg-4 mb-2  ">
          <div className="d-flex align-items-center">
            <button
              className="btn btn-primary py-2 px-4 w-50 px-lg-6  "
              type="button"
              onClick={handleSearch}
              id="ID_Search_search"
            >
              Search
            </button>
            <button
              type="reset"
              className="btn btn-light-primary py-2 px-4 px-lg-6 w-50 ms-2"
              onClick={handleClearSearch}
              id="ID_Reset_search"
            >
              Reset
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
