import React from "react";
import "./style.css";
import Card from "./Card";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { settings } from "../../../../configs/marketplaceSlider";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NftMarketPlace from "./NftMarketPlace";

function marketplace() {
  return (
    <Row className="justify-content-center align-items-center">
      <Col className="marketplace-container  justify-content-center" md={10}>
        <Row className="marketplace-title-row">
          <Col>
            <span className="marketplace-title">Marketplace</span>
          </Col>
        </Row>
        <Row className="marketplace-description-row">
          <Col>
            <span className="marketplace-description">
              Televerse marketplace is the only way for users to purchase lands
              by bidding, and they will be able to know all the information
              about any bot such as all exchanges that took place for this bot's
              NFT and it is definitely fully registered on the blockchain.
            </span>
          </Col>
        </Row>
        <Row className="marketplace-btns d-flex justify-content-between">
          <span className="trending-collection">Trending Collection</span>
          <NavLink className="see-all" to="/marketplace">
            See All &nbsp; <FontAwesomeIcon icon={faAngleRight} />
          </NavLink>
        </Row>

        {/* in web view we display it like this: */}
        <Row className="marketplace-list ">
          <Col className="d-flex justify-content-between">
            <NftMarketPlace />
          </Col>
        </Row>

        {/* while in mobile view we display it like this: */}
        {/* <Row className="marketplace-list d-lg-none d-block">
          <Slider {...settings}>
            <NftMarketPlace />
          </Slider>
        </Row> */}
      </Col>
    </Row>
  );
}

export default marketplace;
