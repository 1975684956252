import { Table } from "react-bootstrap";

export function ChannelTable(props) {
    /**
* Channels list table component
* author Mahmoud Skaik
*/
    const { channelsList } = props;
    return (
        <>
            <span>Channels List</span>
            <Table >
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Total Income</th>
                    </tr>
                </thead>
                <tbody>
                    {channelsList?.length === 0 ? (
                        <tr>
                            <td className="table-no-data" colSpan={12}>
                                No Data Found
                            </td>
                        </tr>
                    ) : (
                        channelsList?.map(({ id, name, totalIncome }, key) => (
                            <tr key={key}>
                                <td>{id}</td>
                                <td>{name}</td>
                                <td>{totalIncome}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>
        </>
    )

}