import { useEffect, useRef } from "react";
import Sidebar from "../../conmponents/SideBar";
import Topbar from "../../conmponents/Topbar";
import ChangeAvatar from "./conmponents/UpdateInformation/ChangeAvatar";
import Header from "./conmponents/header";
import ChangePasswordForm from "./conmponents/UpdateInformation/ChangePasswordForm";
import Security from "./conmponents/UpdateInformation/Security";
import UpdateProfileInfoForm from "./conmponents/UpdateInformation/UpdateProfileInfoForm";
import "./style.css";
/**
 * Profile Page component
 * author Khaled Mofeed
 */
function Profile(props) {
  /**
   * use the useRef to return body
   * author Khaled Mofeed
   */
  const body = useRef(document.body);
  useEffect(() => {
    body.current.classList.add("admin");
  }, []);
  return (
    <div id="wrapper" className="admin">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar />

          <div className="container-fluid">
            <div className="card card-custom gutter-b">
              <Header />
              <div className="card-body p-4">
                <ChangeAvatar />
                <UpdateProfileInfoForm />
                <ChangePasswordForm />
                <Security />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
