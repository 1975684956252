
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNftPresale } from '../../../scripts/nftPresale';
import './style.css';


function Timer({ refresh, setRefresh }) {
  const { startingDate } = useNftPresale();

  const calculateTimeLeft = () => {
    let difference = +new Date(startingDate * 1000) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      setRefresh(!refresh);
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(0);
  const timerComponents = [];
  Object.keys(timeLeft).forEach((interval) => {
    timerComponents.push(
      <Col className="live-timers" xs={3} lg={2}>
        <div className="count">
          <h1>
            {timeLeft[interval] < 10
              ? `0${timeLeft[interval]}`
              : timeLeft[interval]}
          </h1>
          <p>{interval}</p>
        </div>
      </Col>
    );
  });
  const asyncTimer = () => {
    const calculatedTimeLeft = calculateTimeLeft();
    setTimeLeft(calculatedTimeLeft);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      asyncTimer();
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startingDate]);

  return (
    <Row>
      {timerComponents.length ? timerComponents : <span>Loading!</span>}
    </Row>
  );
}

export default Timer;
