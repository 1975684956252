import { useEffect, useRef } from "react";
import Sidebar from "../../conmponents/SideBar";
import Topbar from "../../conmponents/Topbar";
import toast from "react-hot-toast";
import "./style.css";
import Header from "./conmponents/header";
import EnhancedTable from "../../layouts/table";
import LoadingTableRows from "../../conmponents/LoadingTableRows";
import DeleteAdminModal from "./conmponents/deleteAdminModal";
import AddAdminModal from "./conmponents/addAdminModal";
import { TableRow, Typography } from "@mui/material";
import FilterForm from "./conmponents/FilterForm";
import Form from "react-bootstrap/Form";
import { useUser } from "./Hooks/useUser";

function UsersManagementFix(props) {
  /**
   * use the useUser custom hook to handle the operations
   * author Khaled Mofeed
   */
  const {
    initAdminState,
    user,
    modalShowEdit,
    paginationData,
    admin,
    setAdmin,
    filter,
    setFilter,
    order,
    setOrder,
    loading,
    confirmDelete,
    setConfirmDelete,
    idLoading,
    handleEdit,
    handleAdd,
    handleClose,
    handleChangeRowsPerPage,
    handleChangePage,
    getUsers,
    getInitData,
    updateStatus,
    addNewUser,
    updateUser,
    deleteUser,
  } = useUser();
  /**
   * use the useRef to return body
   * author Khaled Mofeed
   */
  const body = useRef(document.body);

  useEffect(() => {
    body.current.classList.add("admin");
    getInitData();
  }, []);

  return (
    <>
      <div id="wrapper" className="admin">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />

            <div className="container-fluid">
              <div className="card card-custom gutter-b">
                <Header
                  action={() => {
                    if (!user.permissions.includes("add_admin_user"))
                      return toast.error(
                        "You dont have permissions to do this action"
                      );

                    handleAdd();
                  }}
                />

                <div className="card-body py-0">
                  <EnhancedTable
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleChangePage={handleChangePage}
                    TableRows={
                      loading ? (
                        <LoadingTableRows
                          id="ID_LoadingTableRows"
                          elementsCount={paginationData.tableHead.length}
                        />
                      ) : paginationData.tableItems.length > 0 ? (
                        paginationData.tableItems.map((row, index) => (
                          <tr key={index}>
                            <td id="ID_TD1">{`${row?.first_name} ${row?.last_name}`}</td>
                            <td id="ID_TD2">{row?.email}</td>
                            <td id="ID_TD3">{row?.mobile}</td>
                            <td id="ID_TD4">
                              {row?.status === "Active" ? (
                                <span className="badge bg-success">
                                  {row?.status}
                                </span>
                              ) : loading && row?.id === idLoading ? (
                                <div className="load-spinner">
                                  <i className="fas fa-spinner fa-spin"></i>
                                </div>
                              ) : (
                                <>
                                  <Form.Check
                                    type="switch"
                                    id={row?.id + Math.random() + 1}
                                    onChange={() => updateStatus(row.id)}
                                  />
                                </>
                              )}
                            </td>
                            <td id="ID_TD5">
                              {user?.permissions?.includes(
                                "delete_admin_user"
                              ) && (
                                <button
                                  onClick={() => {
                                    setConfirmDelete({
                                      status: true,
                                      id: row.id,
                                    });
                                  }}
                                  type="button"
                                  className="btn btn-light-primary btn-icon"
                                >
                                  <i className="fas fa-trash"></i>
                                </button>
                              )}
                              {user?.permissions?.includes(
                                "edit_admin_user"
                              ) && (
                                <button
                                  id="buttonbutton2"
                                  type="button"
                                  className="btn btn-light-primary ms-2 btn-icon"
                                  onClick={() => handleEdit(row)}
                                >
                                  <i className="fas fa-user-edit"></i>
                                </button>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableRow>
                          <td
                            id="ID_Nodata"
                            colSpan={paginationData.tableHead.length}
                          >
                            <Typography
                              sx={{ textAlign: "center" }}
                              variant="body2"
                              color="textPrimary"
                            >
                              No data found
                            </Typography>
                          </td>
                        </TableRow>
                      )
                    }
                    handleRequestSortHeader={(orderFunc, orderByFunc) => {
                      if (!orderByFunc) return;

                      getUsers({
                        name: filter.data.full_name,
                        email: filter.data.email,
                        roles: filter.data.roles,
                        status: filter.data.status,
                        permission: filter.data.permissions,
                        sortBy: orderByFunc,
                        sortOrder: orderFunc,
                        page: 1,
                        perPage: paginationData.rowsPerPage,
                      });

                      setOrder({
                        order: orderFunc,
                        orderBy: orderByFunc,
                      });
                    }}
                    {...paginationData}
                  >
                    <div className="card-header flex-md-wrap border-0 ">
                      <div className="row align-items-center w-md-100">
                        <div className="col-md-12 ">
                          <div
                            id="ID_card-title"
                            className="card-title filter-title"
                            onClick={() =>
                              setFilter({
                                ...filter,
                                status: !filter.status,
                              })
                            }
                          >
                            <h6 className="card-label">
                              <i className="fas fa-sort-amount-down-alt mr-2"></i>
                              Filter by <i className="fas fa-sort list"></i>
                            </h6>
                          </div>
                        </div>

                        {filter.status && (
                          <FilterForm
                            id="ID_card-FilterForm"
                            filter={filter}
                            setFilter={setFilter}
                            handleSearch={(data) => {
                              if (data?.data) {
                                getUsers({
                                  name: data.data.full_name,
                                  email: data.data.email,
                                  roles: data.data.roles,
                                  status: data.data.status,
                                  permission: data.data.permissions,
                                  ...(order.orderBy
                                    ? {
                                        sortBy: order.orderBy,
                                        sortOrder: order.order,
                                      }
                                    : {}),
                                  page: 1,
                                  perPage: paginationData.rowsPerPage,
                                });
                              } else {
                                getUsers({
                                  name: filter.data.full_name,
                                  email: filter.data.email,
                                  roles: filter.data.roles,
                                  status: filter.data.status,
                                  permission: filter.data.permissions,
                                  ...(order.orderBy
                                    ? {
                                        sortBy: order.orderBy,
                                        sortOrder: order.order,
                                      }
                                    : {}),
                                  page: 1,
                                  perPage: paginationData.rowsPerPage,
                                });
                              }
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </EnhancedTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteAdminModal
        id="ID_DeleteAdminModal"
        show={confirmDelete.status}
        onHide={() => setConfirmDelete({ ...confirmDelete, status: false })}
        onConfirm={() => deleteUser(confirmDelete.id)}
      />
      <AddAdminModal
        id="ID_AddAdminModal"
        addNewUser={addNewUser}
        updateUser={updateUser}
        show={modalShowEdit}
        onHide={() => handleClose()}
        data={admin}
        setData={(object) =>
          setAdmin({
            ...admin,
            data: { ...admin.data, ...object },
          })
        }
        handleClose={() => {
          setAdmin(initAdminState);
        }}
      />
    </>
  );
}

export default UsersManagementFix;
