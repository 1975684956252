import ReCAPTCHA from "react-google-recaptcha";
import TowFAComponent from "./TowFAComponent";
import { Spinner } from "react-bootstrap";
import { useForm } from "../../layouts/forms/actions";
import { useAuth } from "../../scripts/App/auth";
import { useNavigate } from "react-router-dom";

function LoginForm(props) {
  /**
   * use the useForm custom hook to handle the operations
   * of forms
   * author Khaled Mofeed
   */
  const {
    form,
    twoFA,
    setTwoFA,
    handleLoginSubmit,
    onChangeRecaptcha,
    recaptchaRef,
    loading,
    setLoading,
    setValue,
    errors,
  } = useForm({
    email: "",
    password: "",
    "g-recaptcha-response": "",
    one_time_password: "",
  });
  const navigate = useNavigate();
  /**
   * if no TOKEN redirect to the page come from
   * author Khaled Mofeed
   */
  const { TOKEN } = useAuth();
  if (TOKEN) {
    navigate("/back-office/profile");
  }

  /**
   * extract the attributes of form
   * author Khaled Mofeed
   */
  const { email, password } = form;

  const grecaptchaObject = window.grecaptcha; // You must provide access to the google grecaptcha object.

  return (
    <>
      {twoFA ? (
        <TowFAComponent setTwoFA={setTwoFA} setLoading={setLoading} />
      ) : (
        <>
          <h2 id="ID_Adminstration_Portal">Adminstration Portal</h2>
          <form
            className="form row position-relative"
            onSubmit={handleLoginSubmit}
          >
            <div className="form-group col-12 col-md-12">
              <input
                type="email"
                className={`form-control form-control-solid border-0 bg-dark-2 ${
                  errors.email && "is-invalid"
                }`}
                name="email"
                placeholder="Email address"
                value={email}
                onChange={setValue}
                id="ID_email_input"
              />

              {errors.email && (
                <div className="invalid-feedback" id="ID_feedback">
                  {errors.email}
                </div>
              )}
            </div>
            <div className="form-group col-12 col-md-12">
              <input
                type="password"
                className={`form-control form-control-solid border-0 bg-dark-2 ${
                  errors.password && "is-invalid"
                }`}
                name="password"
                placeholder="password"
                value={password}
                onChange={setValue}
                id="ID_password_input"
              />
              {errors.password && (
                <div className="invalid-feedback" id="ID_feedback2">
                  {errors.password}
                </div>
              )}
            </div>
            <div className="form-group col-12 form-recaptcha">
              <ReCAPTCHA
                id="recapacha-style"
                size="normal"
                badge="inline"
                ref={recaptchaRef}
                sitekey="6LeNZogiAAAAADutWX8J0EFRp65Owb-HXERwnmJD"
                onChange={onChangeRecaptcha}
                grecaptcha={grecaptchaObject}
                className={`${errors["g-recaptcha-response"] && "is-invalid"}`}
              />
              {errors["g-recaptcha-response"] && (
                <div className="invalid-feedback" id="ID_feedback3">
                  {errors["g-recaptcha-response"]}
                </div>
              )}
            </div>
            <div className="col-12 col-md-12 text-center">
              <button
                type="submit"
                name="submit"
                value="Submit"
                disabled={loading}
                className="btn btn-lg btn-primary px-10"
                id="ID_submit"
              >
                login
                {loading && (
                  <Spinner
                    style={{ marginLeft: ".5rem" }}
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    id="ID_Spinner"
                  />
                )}
              </button>
            </div>
            <div className="col-12 col-md-12 text-center Reset-password">
              <span onClick={() => props.setIsloginPage(false)} id="ID_Reset">
                Reset Account Password
              </span>
            </div>
          </form>
        </>
      )}
    </>
  );
}

export default LoginForm;
