/**
 * (Header)
 * this Header of Event Page, have title
 * author Khaled Mofeed
 */
function Header(props) {
  return (
    <div className="card-header border-0">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label text-dark" id="ID_Roles1">
          Events Management
        </span>
        <span className="text-muted mt-1 font-size-sm" id="ID_ManageRoles">
          Manage system events
        </span>
      </h3>
   
    </div>
  );
}

export default Header;
