import { Skeleton, TableCell, TableRow } from "@mui/material";
/**
 * Loading Table Rows Component
 * author Khaled Mofeed
 */
export default function LoadingTableRows({ elementsCount }) {
  return (
    <>
      <TableRow
        id="ID_TableRow"
        children={Array.from(Array(elementsCount).keys()).map((_, index) => (
          <TableCell key={index}>
            <Skeleton className="my-2" />
          </TableCell>
        ))}
      />
      <TableRow
        id="ID_TableRow2"
        children={Array.from(Array(elementsCount).keys()).map((_, index) => (
          <TableCell key={index}>
            <Skeleton className="my-2" />
          </TableCell>
        ))}
      />
      <TableRow
        id="ID_TableRow3"
        children={Array.from(Array(elementsCount).keys()).map((_, index) => (
          <TableCell key={index}>
            <Skeleton className="my-2" />
          </TableCell>
        ))}
      />
      <TableRow
        id="ID_TableRow4"
        children={Array.from(Array(elementsCount).keys()).map((_, index) => (
          <TableCell key={index}>
            <Skeleton className="my-2" />
          </TableCell>
        ))}
      />
      <TableRow
        id="ID_TableRow5"
        children={Array.from(Array(elementsCount).keys()).map((_, index) => (
          <TableCell key={index}>
            <Skeleton className="my-2" />
          </TableCell>
        ))}
      />
    </>
  );
}
