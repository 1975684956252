import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../../../Layout/Footer";
import MainHeader from "../../../Layout/Header";
import {
  useClaimRevenue,
  useRevenueClaimed,
  useRevenueDetails,
  useRevenueIncome,
} from "../../../../hooks/useRevenue";
import Chart from "./Chart";
import Claim from "./Claim";
import "./style.css";
import Transactions from "./Transactions";
import { AppContext } from "../../../../scripts/context";
import Supply from "../Supply";
import DisconnectedWallet from "../../../../components/DisconnectedWallet";
import { toast } from "react-hot-toast";

function Revenues() {
  const { state: { user } = {} } = useContext(AppContext) || {};

  const chartsLabels = {
    INCOME: "Income",
    CLAIMED: "Claimed",
  };

  let { data: income, refetch: refetchIncome } = useRevenueIncome();
  let { data: claimed, refetch: refetchClaimed } = useRevenueClaimed();
  let { data: details, refetch: refetchDetails } = useRevenueDetails();

  income = income?.data?.data;
  claimed = claimed?.data?.data;
  details = details?.data?.data;

  const claim = useClaimRevenue();

  useEffect(() => {
    if (claim?.claimTxStatus === "success") {
      toast.success("Claimed successfully");
      refetchIncome();
      refetchClaimed();
      refetchDetails();
    }
  }, [claim?.claimTxStatus]);

  useEffect(() => {
    if (claim?.claimTxError) {
      toast.error(claim?.claimTxError);
    }
  }, [claim?.claimTxError]);

  useEffect(() => {
    if (claim?.isClaimLoading) {
      toast.loading("Claiming...");
    }
    return () => {
      toast.dismiss();
    };
  }, [claim.isClaimLoading]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <p className="my-4">
            One of the most important advantages that holders of Televerse
            Digital Share enjoy is getting their share of the project’s monthly
            profits that are gained from Televerse revenue channels, the most
            important of which are:
          </p>

          <ul className="d-flex revenue-list">
            <li>Sales</li>
            <li>Data & advertising </li>
            <li>Deals</li>
            <li>Commissions</li>
          </ul>

          <p>
            Each user will get his share of the profits proportionate to the
            number of Televerse NFTs he has in his account from the total number
            of Televerse Digital Share.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={6} className="my-24-mx-auto">
          <Chart label={chartsLabels.INCOME} data={income} isIncome />
        </Col>
        <Col sm={12} lg={6} className="my-24-mx-auto">
          <Chart label={chartsLabels.CLAIMED} data={claimed} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Transactions data={details} />
        </Col>
      </Row>
      <Row className="d-block d-xl-none">
        <Col sm={12}>
          <Claim
            income={income?.total}
            claimed={claimed?.total}
            claim={claim}
          />
        </Col>
      </Row>
    </>
  );
}

export default Revenues;
