import { useEffect, useRef, useState } from "react";
import Sidebar from "../../conmponents/SideBar";
import Topbar from "../../conmponents/Topbar";
import Header from "./components/header";
import { getProposals } from "./proposalContractFunctions";
import { ProposalTable } from "./components/ProposalTable";
import ProposalModal from "./components/ProposalModal";
import EditUrlModal from "./components/EditUrlModal";

/**
 * Proposal Page component
 * author Mahmoud Skaik
 */
function Proposal(props) {
  const [modalCreateProposal, setModalCreateProposal] = useState(false);
  const [proposalsList, setProposalsList] = useState([]);

  function handleProposalModalClose() {
    setModalCreateProposal(false)
  }

  const getProposalsList = async () => {
    const proposals = await getProposals();
    setProposalsList(proposals)
  }

  const body = useRef(document.body);
  useEffect(() => {
    getProposalsList();
    body.current.classList.add("admin");
  }, []);
  return (
    <div id="wrapper" className="admin">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar />

          <div className="container-fluid">
            <div className="card card-custom gutter-b">
              <Header
                setModalCreateProposal={setModalCreateProposal}
              />
              <div className="card-body p-4">
                <div className="container-fluid">


                  <div className="table-container">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12">
                        <ProposalTable 
                        proposalsList={proposalsList} 
                        getProposalsList={() => getProposalsList()}
                        />
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProposalModal
        id="ID_CreateProposalModal"

        show={modalCreateProposal}
        onHide={() => handleProposalModalClose()}
        handleClose={() => handleProposalModalClose()}
        getProposalsList={() => getProposalsList()}
      />
    </div>
  );
}

export default Proposal;
