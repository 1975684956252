import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ResetPasswordForm from "./ResetPasswordForm";
function ResetPassword(props) {
  /**
   * Reset Password screen Component
   * author Khaled Mofeed
   */
  return (
    <section className="background-radial-gradient overflow-hidden">
      <Container fluid className="px-4 py-5 px-md-5 text-center text-lg-start">
        <Row className="justify-content-center">
          <Col md={10} className="position-relative">
            <div
              id="radius-shape-1"
              className="position-absolute rounded-circle shadow-5-strong"
            ></div>
            <div
              id="radius-shape-2"
              className="position-absolute shadow-5-strong"
            ></div>
            <Row className="align-items-center justify-content-center min-height-100 align-items-center bg-border-gradiant contact-form-bg ">
              <Col lg={5}>
                <div className="logo-admin">
                  <img
                    src="/img/logo-admin.svg"
                    alt="logo admin"
                    className="logo-admin-img"
                    id="ID_logo_admin"
                  />
                  <img
                    src="/img/text.svg"
                    alt="text logo admin"
                    className="logo-admin-text"
                    id="ID_logo_admin2"
                  />
                </div>
                <div className="card bg-glass">
                  <ResetPasswordForm />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ResetPassword;
