import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { calculateTimeLeft } from "../../../../Helper/Helper";
import { useTimer } from "../../../../hooks/useTimer";
import "./style.css";

function Rocket() {
  /**
   * use the useTimer custom hook to handle the Timer
   * author Khaled Mofeed
   */
  const {
    dateDifference,
    timeToCheck,
    getDeadlineDateHandler,
    setTimeLeft,
    timerComponents,
  } = useTimer();
  useEffect(() => {
    getDeadlineDateHandler();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(dateDifference));
    }, timeToCheck);
    return () => clearTimeout(timer);
  });
  return (
    <Row className="live-now-section shared-margin shared-space">
      <Col xs={{ offset: 1, span: 10 }} className="live-presale-now">
        <div className="live-presale-now-text">
          <h3>
            Private Sale <span>is</span>
          </h3>
          <h1>LIVE NOW</h1>
        </div>
        <Row>
          <Col lg={9}>
            <Row>
              {timerComponents.length ? (
                timerComponents
              ) : (
                <span>Loading..</span>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Rocket;
