import { Spinner } from "react-bootstrap";
import { useForm } from "../../layouts/forms/actions";

function ForgetPasswordForm(props) {
  /**
   * use the useForm custom hook to handle the operations
   * of forms
   * author Khaled Mofeed
   */
  const { form, handleForgetPasswordSubmit, loading, setValue, errors } =
    useForm({
      email: "",
    });
  /**
   * extract the attributes of form
   * author Khaled Mofeed
   */
  const { email } = form;

  return (
    <>
      <h2 className="mb-2">Forgotten Password ?</h2>
      <div
        className="text-center mb-4"
        id="ID_password_lable"
        style={{ color: "#ccc" }}
      >
        Enter your email to reset your password
      </div>
      <form
        className="form row position-relative"
        onSubmit={handleForgetPasswordSubmit}
      >
        <div className="form-group col-12 col-md-12">
          <input
            type="text"
            name="email"
            id="email"
            className={`form-control form-control-solid border-0 bg-dark-2 ${
              errors.email && "is-invalid"
            }`}
            placeholder="Email address"
            value={email}
            onChange={setValue}
          />
          {errors.email && (
            <div className="invalid-feedback" id="ID_feedback">
              {errors.email}
            </div>
          )}
        </div>

        <div className="col-12 col-md-12 text-center">
          <button
            type="submit"
            className="btn btn-lg btn-primary px-lg-10"
            disabled={loading}
            id="id_button_submitt"
          >
            Request
            {loading && (
              <Spinner
                style={{ marginLeft: ".5rem" }}
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                id="id_Spinner"
              />
            )}
          </button>
          <button
            type="button"
            name="Cancel"
            id="id_button_Cancel"
            className="btn btn-lg btn-secondary px-lg-10"
            onClick={() => props.setIsloginPage(true)}
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  );
}

export default ForgetPasswordForm;
