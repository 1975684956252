import { useState } from "react";
import { Table } from "react-bootstrap";
import EditUrlModal from "./EditUrlModal";

/**
 * Proposal Data Table Component
 * author Mahmoud Skaik
 */
export function ProposalTable(props) {
    const { proposalsList, getProposalsList } = props;
    const [modalEditProposal, setModalEditProposal] = useState(false);

    const [proposal, setProposal] = useState({});
    function handleProposalEditModalClose() {
        setModalEditProposal(false)
    }

    return (
        <>
            <span>Proposals List</span>
            <Table >
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>URL</th>
                        <th>Status</th>
                        <th>Closed In</th>
                        <th>Soft Cap</th>
                        <th>Number Of Like</th>
                        <th>Number Of Dislike</th>
                        <th>Created At</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {proposalsList?.length === 0 ? (
                        <tr>
                            <td className="table-no-data" colSpan={12}>
                                No Data Found
                            </td>
                        </tr>
                    ) : (
                        proposalsList?.map((item, key) => (
                            <tr key={key}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td>{item.url}</td>
                                <td>
                                    <span className={`badge bg-${item.status == 'Active' ? 'success' : 'danger'}`}>
                                        {item.status}
                                    </span>
                                </td>
                                <td>{item.closeAt}</td>
                                <td>{item.softCap}</td>
                                <td>
                                    <span className="badge bg-success">
                                        {item.numberOfLike}
                                    </span>
                                </td>
                                <td>
                                    <span className="badge bg-danger">
                                        {item.numberOfDislike}
                                    </span>
                                </td>
                                <td>{item.createdAt}</td>
                                <td>
                                    <button
                                        onClick={() => {
                                            setProposal(item)
                                            setModalEditProposal(true);
                                        }}

                                        type="button"
                                        className="btn btn btn-icon"
                                        title="Copy"
                                    >
                                        <i className="fas fa-edit"></i>
                                    </button>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>
            <EditUrlModal
                id="ID_EditProposalModal"
                show={modalEditProposal}
                onHide={() => handleProposalEditModalClose()}
                data={proposal}
                handleClose={() => handleProposalEditModalClose()}
                getProposalsList={() => getProposalsList()}

            />
        </>
    )

}