import React, { useState } from 'react';
import CustomTable from '../../../../components/CustomTable';
import Pagination from './Pagenation';
import { getUserCommissionsList } from '../../presaleContractFunctions';
import './style.css';

function Purchased() {
  // current page to the affiliate table
  const [currentPage, setCurrentPage] = useState(1);
  const [tableTotalList, setTableTotalList] = useState(0);

  let pages = 0;

  // take the total list and return the list of the current page to the table
  const affiliateList = async (limit) => {
    if (limit <= 0) limit = 1;
    const { transactions, totalList } = await getUserCommissionsList(currentPage - 1, limit);
    pages = Math.ceil(totalList / limit) || 1;
    setTableTotalList(totalList);

    return transactions;
  };

  return (
    <div className='shared-space'>
      <h2 className='table-label'>Affiliate Commessions</h2>
      <CustomTable cb={affiliateList} currentPage={currentPage} no={3} />
      <Pagination totalList={tableTotalList} pages={pages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </div>
  );
}

export default Purchased;
