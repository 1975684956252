import React from "react";
import { useForm } from "../../../../layouts/forms/actions";
import { useAuth } from "../../../../scripts/App/auth";
import { Spinner } from "react-bootstrap";

function UpdateProfileInfoForm(props) {
  const { user } = useAuth();
  /**
   * use the useForm custom hook to handle the operations
   * of forms
   * author Khaled Mofeed
   */
  const { form, handelUpdateInfoProfileSubmit, loading, setValue, errors } =
    useForm({
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      mobile: user?.mobile || "",
    });
  /**
   * extract the attributes of form
   * author Khaled Mofeed
   */
  const { first_name, last_name, mobile } = form;
  return (
    <>
      <form onSubmit={handelUpdateInfoProfileSubmit}>
        <div className="form-group row">
          <label className="col-xl-3 col-lg-3 col-form-label" id="first_name">
            First Name
          </label>
          <div className="col-lg-5 col-xl-5">
            <input
              className={`form-control ${errors.first_name && "is-invalid"}`}
              type="text"
              name="first_name"
              id="first_name"
              value={first_name}
              onChange={setValue}
            />
            {errors.first_name && (
              <div className="invalid-feedback" id="first_name">
                {errors.first_name}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row mb-3">
          <label
            className="col-xl-3 col-lg-3 col-form-label"
            id="id_first_name_lable"
          >
            Last Name
          </label>
          <div className="col-lg-5 col-xl-5">
            <input
              className={`form-control ${errors.last_name && "is-invalid"}`}
              type="text"
              name="last_name"
              id="last_name"
              value={last_name}
              onChange={setValue}
            />
            {errors.last_name && (
              <div className="invalid-feedback" id="last_name">
                {errors.last_name}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row mb-3">
          <label
            className="col-xl-3 col-lg-3 col-form-label"
            id="id_first_name_lable"
          >
            Mobile
          </label>
          <div className="col-lg-5 col-xl-5">
            <input
              className={`form-control ${errors.mobile && "is-invalid"}`}
              type="tel"
              name="mobile"
              id="mobile"
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
              value={mobile}
              onChange={setValue}
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
            />
            {errors.mobile && (
              <div className="invalid-feedback" id="mobile">
                {errors.mobile}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row mb-3">
          <label
            className="col-xl-3 col-lg-3 col-form-label"
            id="id_email_address_lable"
          >
            Email Address
          </label>
          <div className="col-lg-5 col-xl-5">
            <div className="input-group input-group-lg input-group-solid">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-at" id="id_email_address_icon"></i>
                </span>
              </div>
              <input
                id="id_email_address_input"
                type="text"
                className="form-control"
                disabled
                value={user.email}
                placeholder="Email"
              />
            </div>
          </div>
        </div>
        <div className="card-toolbar text-right">
          <button
            id="info__submit"
            className="btn btn-primary mr-2"
            type="submit"
            name="submit"
            value="Submit"
            disabled={loading}
          >
            Save Changes
            {loading && (
              <Spinner
                style={{ marginLeft: ".5rem" }}
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
      </form>
    </>
  );
}

export default UpdateProfileInfoForm;
