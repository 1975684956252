export const config_B_Section = [
  //******************** A Section  ***************
  {
    nameMap: "pointDataHexa2",
    locationMap: "100, 752, 212",
    stroke: "rgb(34 33 43)",
    fill: "rgb(28 27 36)",
    unClick: true,
    section: "B",
  },
  // 1 top parent 1
  {
    nameMap: "subParentRightCenter",
    locationMap: "33, 752, 212",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1RightCenter",
    locationMap: "10.7, 752, 192.8",
  },
  {
    nameMap: "Child2_SubParent1RightCenter",
    locationMap: "10.7, 735.5, 202.2",
  },
  {
    nameMap: "Child3_SubParent1RightCenter",
    locationMap: "10.7, 735.5, 221.3",
  },
  {
    nameMap: "Child4_SubParent1RightCenter",
    locationMap: "10.7, 752, 231",
  },
  {
    nameMap: "Child5_SubParent1RightCenter",
    locationMap: "10.7, 768.4, 221.3",
  },
  {
    nameMap: "Child6_SubParent1RightCenter",
    locationMap: "10.7, 768.4, 202.2",
  },
  {
    nameMap: "Child7_SubParent1RightCenter",
    locationMap: "10.7, 752, 212",
  },
  // 2 top left parent 2
  {
    nameMap: "subParentTop_RightCenter",
    locationMap: "33, 752, 154",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1Top_RightCenter",
    locationMap: "10.7, 752, 134.8",
  },
  {
    nameMap: "Child2_SubParent1Top_RightCenter",
    locationMap: "10.7, 735.5 ,144.2",
  },
  {
    nameMap: "Child3_SubParent1Top_RightCenter",
    locationMap: "10.7,735.5,163.3",
  },
  {
    nameMap: "Child4_SubParent1Top_RightCenter",
    locationMap: "10.7, 752, 173",
  },
  {
    nameMap: "Child5_SubParent1Top_RightCenter",
    locationMap: "10.7, 768.4,163.3",
  },
  {
    nameMap: "Child6_SubParent1Top_RightCenter",
    locationMap: "10.7, 768.4,144.2",
  },
  {
    nameMap: "Child7_SubParent1Top_RightCenter",
    locationMap: "10.7, 752, 154",
  },
  // 3 bottom left parent 3
  {
    nameMap: "subParentLeft_Top_RightCenter",
    locationMap: "33, 702, 183",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1Left_Top_RightCenter",
    locationMap: "10.7, 702,163.8",
  },
  {
    nameMap: "Child2_SubParent1Left_Top_RightCenter",
    locationMap: "10.7, 685.5,173.2",
  },
  {
    nameMap: "Child3_SubParent1Left_Top_RightCenter",
    locationMap: "10.7, 685.5,192.3",
  },
  {
    nameMap: "Child4_SubParent1Left_Top_RightCenter",
    locationMap: "10.7, 702,202",
  },
  {
    nameMap: "Child5_SubParent1Left_Top_RightCenter",
    locationMap: "10.7,  718.4,192.3",
  },
  {
    nameMap: "Child6_SubParent1Left_Top_RightCenter",
    locationMap: "10.7, 718.4,173.2",
  },
  {
    nameMap: "Child7_SubParent1Left_Top_RightCenter",
    locationMap: "10.7, 702,183",
  },
  // 4 bottom parent 4
  {
    nameMap: "subParentLeft_Bottom_RightCenter",
    locationMap: "33, 702, 241",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1Left_Bottom_RightCenter",
    locationMap: "10.7, 702,221.8",
  },
  {
    nameMap: "Child2_SubParent1Left_Bottom_RightCenter",
    locationMap: "10.7, 685.5,231.2",
  },
  {
    nameMap: "Child3_SubParent1Left_Bottom_RightCenter",
    locationMap: "10.7, 685.5,250.3",
  },
  {
    nameMap: "Child4_SubParent1Left_Bottom_RightCenter",
    locationMap: "10.7, 702,260",
  },
  {
    nameMap: "Child5_SubParent1Left_Bottom_RightCenter",
    locationMap: "10.7, 718.4, 250.3",
  },
  {
    nameMap: "Child6_SubParent1Left_Bottom_RightCenter",
    locationMap: "10.7, 718.4,231.2",
  },
  {
    nameMap: "Child7_SubParent1Left_Bottom_RightCenter",
    locationMap: "10.7, 702,241",
  },
  //  5 bottom right parent 5
  {
    nameMap: "subParentBottom_RightCenter",
    locationMap: "33, 752, 270",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1Bottom_RightCenter",
    locationMap: "10.7, 752,250.8",
  },
  {
    nameMap: "Child2_SubParent1Bottom_RightCenter",
    locationMap: "10.7, 735.5, 260.2",
  },
  {
    nameMap: "Child3_SubParent1Bottom_RightCenter",
    locationMap: "10.7, 735.5, 279.3",
  },
  {
    nameMap: "Child4_SubParent1Bottom_RightCenter",
    locationMap: "10.7, 752, 289",
  },
  {
    nameMap: "Child5_SubParent1Bottom_RightCenter",
    locationMap: "10.7, 768.4,279.3",
  },
  {
    nameMap: "Child6_SubParent1Bottom_RightCenter",
    locationMap: "10.7,  768.4, 260.2",
  },
  {
    nameMap: "Child7_SubParent1Bottom_RightCenter",
    locationMap: "10.7, 752, 270",
  },
  //  6 top right parent 6
  {
    nameMap: "subParentBottom_Right_RightCenter",
    locationMap: "33, 802, 241",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1Bottom_Right_RightCenter",
    locationMap: "10.7, 802,221.8",
  },
  {
    nameMap: "Child2_SubParent1Bottom_Right_RightCenter",
    locationMap: "10.7, 785.5, 231.2",
  },
  {
    nameMap: "Child3_SubParent1Bottom_Right_RightCenter",
    locationMap: "10.7, 785.5,250.3",
  },
  {
    nameMap: "Child4_SubParent1Bottom_Right_RightCenter",
    locationMap: "10.7, 802,260",
  },
  {
    nameMap: "Child5_SubParent1Bottom_Right_RightCenter",
    locationMap: "10.7, 818.4,250.3",
  },
  {
    nameMap: "Child6_SubParent1Bottom_Right_RightCenter",
    locationMap: "10.7, 818.4, 231.2",
  },
  {
    nameMap: "Child7_SubParent1Bottom_Right_RightCenter",
    locationMap: "10.7, 802, 241",
  },
  //  7 top right parent 7
  {
    nameMap: "subParentTop_Right_RightCenter",
    locationMap: "33, 802, 183",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1Top_Right_RightCenter",
    locationMap: "10.7, 802,163.8",
  },
  {
    nameMap: "Child2_SubParent1Top_Right_RightCenter",
    locationMap: "10.7, 785.5, 173.2",
  },
  {
    nameMap: "Child3_SubParent1Top_Right_RightCenter",
    locationMap: "10.7, 785.5,192.3",
  },
  {
    nameMap: "Child4_SubParent1Top_Right_RightCenter",
    locationMap: "10.7, 802,202",
  },
  {
    nameMap: "Child5_SubParent1Top_Right_RightCenter",
    locationMap: "10.7, 818.4,192.3",
  },
  {
    nameMap: "Child6_SubParent1Top_Right_RightCenter",
    locationMap: "10.7, 818.4, 173.2",
  },
  {
    nameMap: "Child7_SubParent1Top_Right_RightCenter",
    locationMap: "10.7, 802, 183",
  },
];
