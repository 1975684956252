import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import "./style.css";

function Rocket() {
  return (
    <Row className="live-now-section shared-margin shared-space custom-live-section mh-100">
      <Col
        // xs={{ offset: 1, span: 10 }}
        className="live-presale-now h-lg-100 justify-content-start "
      >
        <Row className="live-presale-now-text h-lg-100">
          <Col
            lg={6}
            className="live-presale-now-text d-flex flex-column justify-content-center text-gap h-lg-100 res-ds-live"
          >
            <h3>
              DIGITAL
              <br /> SHARING SALE
            </h3>
            <h1 className="lg-45">
              LIVE
              <br /> NOW
            </h1>
          </Col>
          <Col
            lg={6}
            className="live-presale-now-text d-flex flex-column justify-content-center text-gap h-lg-100 res-ds-live"
          >
            <img
              src="/img/rocketRight.png"
              alt="rocketRight"
              className="rocketRight"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Rocket;
