export const tableHeadLatesOffers = [
  {
    id: "From",
    label: "From",
  },
  {
    id: "Price",
    label: "Price",
  },
  {
    id: "EndOffer",
    label: "End Offer",
  },
];
