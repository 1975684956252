import { HTTP_LIVE } from "../constants";
export const LiveState = (
  <span className="LiveSpan">
    {HTTP_LIVE} <div className="circleLive"></div>
  </span>
);
export const EventsData = [
  {
    id: "Kirk Miller",
    facebook: "https://www.facebook.com/",
    twitter: "https://twitter.com/",
    instagram: "https://www.instagram.com/",
    name: "Kirk Miller",
    job: "CEO & 3D Designer",
    firstcontent:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ",
    secondcontent:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    authorImage: "img/Kirk.svg",
  },
  {
    id: "Kirk2",
    facebook: "https://www.facebook.com/",
    twitter: "https://twitter.com/",
    instagram: "https://www.instagram.com/",
    name: "Kirk Miller",
    job: "CEO & 3D Designer",
    firstcontent:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
    secondcontent:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    authorImage: "img/Kirk2.svg",
  },
  {
    id: "Kirk3",
    facebook: "https://www.facebook.com/",
    twitter: "https://twitter.com/",
    instagram: "https://www.instagram.com/",
    name: "Kirk Miller",
    job: "CEO & 3D Designer",
    firstcontent:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
    secondcontent:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    authorImage: "img/Kirk3.svg",
  },
  {
    id: "Kirk4",
    facebook: "https://www.facebook.com/",
    twitter: "https://twitter.com/",
    instagram: "https://www.instagram.com/",
    name: "Kirk Miller",
    job: "CEO & 3D Designer",
    firstcontent:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
    secondcontent:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    authorImage: "img/Kirk4.svg",
  },
  {
    id: "Kirk5",
    facebook: "https://www.facebook.com/",
    twitter: "https://twitter.com/",
    instagram: "https://www.instagram.com/",
    name: "Kirk Miller",
    job: "CEO & 3D Designer",
    firstcontent:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
    secondcontent:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    authorImage: "img/Kirk5.svg",
  },
  {
    id: "Kirk6",
    facebook: "https://www.facebook.com/",
    twitter: "https://twitter.com/",
    instagram: "https://www.instagram.com/",
    name: "Kirk Miller",
    job: "CEO & 3D Designer",
    firstcontent:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
    secondcontent:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    authorImage: "img/Kirk6.svg",
  },
];
export const SettingsSlider = {
  dots: true,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  // margin: 5,

  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        // infinite: true,
        // dots: false,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        // infinite: true,
        // dots: true,
        // margin: 20,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
