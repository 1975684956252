import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { shortenDate } from "../../../../Helper/Helper";
import "./style.css";

function CustomTable ({ data }) {

  const [dataToShow, setDataToShow] = useState([]);
  const [uniqueChannels, setUniqueChannels] = useState([]);
  useEffect(() => {
    if(!data?.length) return;
    console.log(data)
  const channelsNames = data.map(({ channels }) => {
    return channels.map(({ name }) => name);
  });
  console.log(channelsNames)
  const uniqueChannels = new Set (channelsNames.flatMap((channel) => channel));
  setUniqueChannels([...uniqueChannels]);

}, [data]);

  return (
    <div className="revenue-bg table-container revenue-table">
      <span className="table-label">Revenues Details</span>
      <Table className="purchasing-table list-data-revenue">
        <thead>
          <tr>
            <th>Date</th>
           {uniqueChannels.map((channel) => (
             <th key={channel}>{channel}</th>
            ))}
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {data?.length === 0 ? (
            <tr>
              <td className="table-no-data" colSpan={12}>
                No Data Found
              </td>
            </tr>
          ) : (
            data?.map(({ date_at, total, channels }) => (
              <tr key={date_at}>
                <td>{shortenDate(date_at)}</td>
                {uniqueChannels.map((channel) => {
                  const channelData = channels.find((channelData) => channelData.name === channel);
                  return <td key={channel}>{channelData?.total || '---'}</td>;
                }
                )}
                <td>{total}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default CustomTable;
