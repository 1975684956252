import React from "react";
import { Row, Col } from "react-bootstrap";
import BalanceDetails from "./BalanceDetails";
import VestingTable from "./VestingTable";
import Notes from "./Notes";

function index(props) {
  return (
    <Col className="live-presale-max-width mt-5">
      <Row className="justify-content-center ">
        <Col
          xs={12}
          sm={10}
          lg={6}
          className="d-flex flex-column justify-content-between"
        >
          <VestingTable />
          <Notes />
        </Col>
        <Col xs={12} sm={10} lg={4}>
          <BalanceDetails />
        </Col>
      </Row>
    </Col>
  );
}

export default index;
