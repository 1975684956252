import { useEffect, useRef, useState } from "react";
import Sidebar from "../../conmponents/SideBar";
import Topbar from "../../conmponents/Topbar";
import ChannelModal from "./components/ChannelModal";
import RevenueModal from "./components/RevenueModal";
import Header from "./components/header";
import Statistics from "./components/statistics";
import { RevenueTable } from "./components/RevenueTable";
import { ChannelTable } from "./components/ChannelTable";
import { getChannels, getRevenues, getRevenueStatisticsData } from "./revenueContractFunctions"

/**
 * Revenue Page component
 * author Mahmoud Skaik
 */
function Revenue(props) {
  const [modalCreateChannel, setModalCreateChannel] = useState(false);
  const [modalAddRevenue, setModalAddRevenue] = useState(false);
  const [channelsList, setChannelsList] = useState([]);
  const [revenuesList, setRevenuesList] = useState([]);
  const [statisticsData, setStatisticsData] = useState({});

  const revenueStatisticsData = async () => {
    const statisticsData = await getRevenueStatisticsData();
    setStatisticsData(statisticsData)
  }

  function handleChannelModalClose() {
    setModalCreateChannel(false)
  }

  function handleRevenueModalClose() {
    setModalAddRevenue(false)
  }


  const getChannelsList = async () => {
    const channels = await getChannels();
    setChannelsList(channels)
  }


  const getRevenuesList = async () => {
    const revenues = await getRevenues();
    setRevenuesList(revenues)
  }


  const body = useRef(document.body);
  useEffect(() => {
    getChannelsList();
    getRevenuesList();
    revenueStatisticsData();
    body.current.classList.add("admin");
  }, []);
  return (
    <div id="wrapper" className="admin">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar />

          <div className="container-fluid">
            <div className="card card-custom gutter-b">
              <Header
                setModalCreateChannel={setModalCreateChannel}
                setModalAddRevenue={setModalAddRevenue}
              />
              <div className="card-body p-4">
                <Statistics 
                statisticsData={statisticsData}
                />
                <div className="container-fluid">


                  <div className="table-container">
                    <div className="row">
                      <div className="col-xl-4 col-lg-4">
                        <ChannelTable 
                        channelsList={channelsList} 
                        />
                      </div>
                      <div className="col-xl-8 col-lg-8">
                        <RevenueTable 
                         channelsList={channelsList} 
                         revenuesList={revenuesList} 
                         />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChannelModal
        id="ID_CreateChannelModal"

        show={modalCreateChannel}
        onHide={() => handleChannelModalClose()}

        handleClose={() => handleChannelModalClose()}
        getChannelsList={() => getChannelsList()}
        getRevenuesList={() => getRevenuesList()}
        revenueStatisticsData={() => revenueStatisticsData()}

      />
      <RevenueModal
        id="ID_CreateChannelModal"

        show={modalAddRevenue}
        onHide={() => handleRevenueModalClose()}

        handleClose={() => handleRevenueModalClose()}
        channelsList={channelsList} 

        getChannelsList={() => getChannelsList()}
        getRevenuesList={() => getRevenuesList()}
        revenueStatisticsData={() => revenueStatisticsData()}
      />
    </div>
  );
}

export default Revenue;
