import React, { useContext } from "react";
import Col from "react-bootstrap/Col";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { AppContext } from "../../../scripts/context";
import { locationArr } from "../../../configs/configLocationFillter";

function Filter(props) {
  /*  Filter Component,  filters the data and returns it in the Card NFT Component
 | khaled Mofeed */
  const {
    CategoriesIdNft,
    setCategoriesIdNft,
    location,
    setLocation,
    fillterKeyword,
    setFillterKeyword,
    price_to,
    setPrice_to,
    price_from,
    setPrice_from,
    statusNft,
    setStatusNft,
    condition,
    setCondition,
    eventCategories,
  } = useContext(AppContext);
  /**
   * get All Categories from context
   * author Khaled Mofeed
   */
  const AllCategories = eventCategories.eventCategories;
  /**
   * handleStatusChange Function use to set status of nft to view
   * author Khaled Mofeed
   */
  const handleStatusChange = (event) => {
    setStatusNft(event.target.value);
  };
  /**
   * handleConditionChange Function use to set condition of nft to view
   * author Khaled Mofeed
   */
  const handleConditionChange = (event) => {
    setCondition(event.target.value);
  };

  return (
    <Col md={4} xl={3} className="TrendingSection CategoriesFillter ">
      <div className=" marketplaceFillter">
        <div className=" mb-4 col-12 col-md-12">
          <div className="row">
            <h6 className="nickName mt-2 mb-0 col-4">Fillters</h6>
          </div>
        </div>

        <div className=" mb-4 col-12 col-md-12">
          <div className=" SearchCustomInput input-group bg-border-gradiant contact-form-bg">
            <span className="input-group-text" id="basic-addon1">
              <img
                src="/img/search.svg"
                alt="searchIcon"
                className="searchIcon"
              />
            </span>
            <input
              value={fillterKeyword}
              onChange={(e) => setFillterKeyword(e.target.value)}
              placeholder="Search By Name / Owner"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="form-control form-control-solid border-0 bg-dark-2 "
              type="search"
            />
          </div>
        </div>
        <h6 className="nickName borderTopPadding mb-4">Status</h6>
        <div className="form-group col-12 col-md-12 mb-2">
          <div className="row px-0">
            <div className="col-12 px-0">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  className="StatusRadio"
                  value={statusNft}
                  onChange={handleStatusChange}
                >
                  <FormControlLabel value="" control={<Radio />} label="All" />
                  <FormControlLabel
                    value="initial_sale"
                    control={<Radio />}
                    label="Initial Sale"
                  />
                  <FormControlLabel
                    value="secondary_sale"
                    control={<Radio />}
                    label="Secondary Sale"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <h6 className="nickName borderTopPadding mb-4">Categories</h6>
        <div className="form-group col-12 col-md-12 mb-2">
          <div className="row px-0">
            <div className="col-12 px-0">
              <FormControl
                sx={{ m: 3 }}
                component="fieldset"
                variant="standard"
                className="m-0 columns_2"
              >
                {AllCategories?.map((Categories, index) => {
                  return (
                    <FormControlLabel
                      className="CategoriesInput"
                      key={Categories.id}
                      label={Categories.name}
                      sx={{
                        width: "fit-content",
                      }}
                      control={
                        <Checkbox
                          type="checkbox"
                          className="CheckboxCategories"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setCategoriesIdNft([
                                ...CategoriesIdNft,
                                Categories.id,
                              ]);
                            } else {
                              setCategoriesIdNft(
                                CategoriesIdNft.filter(
                                  (val) => val !== Categories.id
                                )
                              );
                            }
                          }}
                        />
                      }
                    />
                  );
                })}
              </FormControl>
            </div>
          </div>
        </div>
        <h6 className="nickName borderTopPadding mb-4">Location</h6>
        <div className="form-group col-12 col-md-12 mb-2">
          <div className="row px-0">
            <div className="col-12 px-0">
              <FormControl
                sx={{ m: 3 }}
                component="fieldset"
                variant="standard"
                className="m-0 columns_2"
              >
                {locationArr?.map((Location, index) => {
                  return (
                    <FormControlLabel
                      className="CategoriesInput"
                      key={Location.value}
                      label={Location.name}
                      sx={{
                        width: "fit-content",
                      }}
                      control={
                        <Checkbox
                          type="checkbox"
                          className="CheckboxCategories"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setLocation([...location, Location.value]);
                            } else {
                              setLocation(
                                location.filter((val) => val !== Location.value)
                              );
                            }
                          }}
                        />
                      }
                    />
                  );
                })}
              </FormControl>
            </div>
          </div>
        </div>

        <h6 className="nickName borderTopPadding mb-4">Range Price</h6>
        <div className="form-group col-12 col-md-12 mb-2 PriceInput">
          <div className="row px-0 bg-border-gradiant contact-form-bg">
            <div className="form-group col-12 col-md-6 mb-2 position-relative ">
              <label className="basic-url">Min</label>
              <input
                value={price_from}
                onChange={(e) => setPrice_from(e.target.value)}
                placeholder="00.0"
                aria-label="From"
                aria-describedby="basic-addon1"
                className="form-control form-control-solid border-0 bg-dark-2 "
                type="search"
              />
            </div>
            <div className="form-group col-12 col-md-6 mb-0 position-relative">
              <label className="basic-url">Max</label>
              <input
                value={price_to}
                onChange={(e) => setPrice_to(e.target.value)}
                placeholder="00.0"
                aria-label="To"
                aria-describedby="basic-addon1"
                className="form-control form-control-solid bg-dark-2 "
                type="search"
              />
            </div>
          </div>
        </div>

        <h6 className="nickName borderTopPadding mb-4">Condition</h6>
        <div className="form-group col-12 col-md-12 mb-2">
          <div className="row px-0">
            <div className="col-12 px-0">
              <FormControl className="m-0 columns_2">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  className="StatusRadio"
                  value={condition}
                  onChange={handleConditionChange}
                >
                  <FormControlLabel value="" control={<Radio />} label="All" />
                  <FormControlLabel
                    value="for_sale"
                    control={<Radio />}
                    label="For Sale"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}

export default Filter;
