import { Navigate } from "react-router-dom";
import { useAuth } from "../scripts/App/auth";
/**
 * (Require Admin Auth component)
 * this component use check if the session has token or not ?
 * if it not have token navigate to login page
 * author Khaled Mofeed
 */
export const RequireAdminAuth = ({ children }) => {
  // const TOKEN = localStorage.getItem("token");
  const { loading, TOKEN, user } = useAuth();
  if (loading) {
    if (!TOKEN || !user) {
      return <Navigate to="/back-office/login" />;
    }
    return <></>;
  }
  if (!TOKEN || !user) {
    return <Navigate to="/back-office/login" />;
  }
  return children;

  // else if (!TOKEN) {
  // }
};
