export const config_C_Section = [
  //******************** A Section  ***************
  {
    nameMap: "pointDataHexa3",
    locationMap: "100, 600, 125",
    stroke: "rgb(34 33 43)",
    fill: "rgb(28 27 36)",
    unClick: true,
    section: "C",
  },
  // 1 top parent 1
  {
    nameMap: "subParentTop",
    locationMap: "33, 600, 125",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1",
    locationMap: "10.7, 600, 105.8",
  },
  {
    nameMap: "Child2_SubParent1",
    locationMap: "10.7, 583.5, 115.2",
  },
  {
    nameMap: "Child3_SubParent1",
    locationMap: "10.7, 583.5, 134.3",
  },
  {
    nameMap: "Child4_SubParent1",
    locationMap: "10.7, 600, 144",
  },
  {
    nameMap: "Child5_SubParent1",
    locationMap: "10.7, 616.4, 134.3",
  },
  {
    nameMap: "Child6_SubParent1",
    locationMap: "10.7, 616.4, 115.2",
  },
  {
    nameMap: "Child7_SubParent1",
    locationMap: "10.7, 600, 125",
  },
  // 2 top left parent 2
  {
    nameMap: "subParent7Container",
    locationMap: "33, 600, 67",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent7",
    locationMap: "10.7, 600, 47.8",
  },
  {
    nameMap: "Child2_SubParent7",
    locationMap: "10.7, 583.5, 57.2",
  },
  {
    nameMap: "Child3_SubParent7",
    locationMap: "10.7, 583.5, 76.3",
  },
  {
    nameMap: "Child4_SubParent7",
    locationMap: "10.7, 600, 86",
  },
  {
    nameMap: "Child5_SubParent7",
    locationMap: "10.7, 616.4, 76.3",
  },
  {
    nameMap: "Child6_SubParent7",
    locationMap: "10.7, 616.4, 57.2",
  },
  {
    nameMap: "Child7_SubParent7",
    locationMap: "10.7, 600, 67",
  },
  // 3 bottom left parent 3
  {
    nameMap: "subParent6Container",
    locationMap: "33, 650, 96",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent6",
    locationMap: "10.7, 650, 76.8",
  },
  {
    nameMap: "Child2_SubParent6",
    locationMap: "10.7, 633.5, 86.2",
  },
  {
    nameMap: "Child3_SubParent6",
    locationMap: "10.7, 633.5, 105.3",
  },
  {
    nameMap: "Child4_SubParent6",
    locationMap: "10.7, 650, 115",
  },
  {
    nameMap: "Child5_SubParent6",
    locationMap: "10.7, 666.4, 105.3",
  },
  {
    nameMap: "Child6_SubParent6",
    locationMap: "10.7, 666.4, 86.2",
  },
  {
    nameMap: "Child7_SubParent6",
    locationMap: "10.7, 650, 96",
  },
  // 4 bottom parent 4
  {
    nameMap: "subParent5Container",
    locationMap: "33, 650, 154",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent5",
    locationMap: "10.7, 650, 134.8",
  },
  {
    nameMap: "Child2_SubParent5",
    locationMap: "10.7, 633.5, 144.2",
  },
  {
    nameMap: "Child3_SubParent5",
    locationMap: "10.7, 633.5, 163.3",
  },
  {
    nameMap: "Child4_SubParent5",
    locationMap: "10.7, 650, 173",
  },
  {
    nameMap: "Child5_SubParent5",
    locationMap: "10.7, 666.4, 163.3",
  },
  {
    nameMap: "Child6_SubParent5",
    locationMap: "10.7, 666.4, 144.2",
  },
  {
    nameMap: "Child7_SubParent5",
    locationMap: "10.7, 650, 154",
  },

  //  5 bottom right parent 5
  {
    nameMap: "subParent4Container",
    locationMap: "33, 600, 183",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent4",
    locationMap: "10.7, 600, 163.8",
  },
  {
    nameMap: "Child2_SubParent4",
    locationMap: "10.7, 583.5, 173.2",
  },
  {
    nameMap: "Child3_SubParent4",
    locationMap: "10.7, 583.5, 192.3",
  },
  {
    nameMap: "Child4_SubParent4",
    locationMap: "10.7, 600, 202",
  },
  {
    nameMap: "Child5_SubParent4",
    locationMap: "10.7, 616.4, 192.3",
  },
  {
    nameMap: "Child6_SubParent4",
    locationMap: "10.7, 616.4, 173.2",
  },
  {
    nameMap: "Child7_SubParent4",
    locationMap: "10.7, 600, 183",
  },
  //  6 top right parent 6
  {
    nameMap: "subParent3Container",
    locationMap: "33, 550, 154",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent3",
    locationMap: "10.7, 550, 134.8",
  },
  {
    nameMap: "Child2_SubParent3",
    locationMap: "10.7, 533.5, 144.2",
  },
  {
    nameMap: "Child3_SubParent3",
    locationMap: "10.7, 533.5, 163.3",
  },
  {
    nameMap: "Child4_SubParent3",
    locationMap: "10.7, 550, 173",
  },
  {
    nameMap: "Child5_SubParent3",
    locationMap: "10.7, 566.4, 163.3",
  },
  {
    nameMap: "Child6_SubParent3",
    locationMap: "10.7, 566.4, 144.2",
  },
  {
    nameMap: "Child7_SubParent3",
    locationMap: "10.7, 550, 154",
  },
  //  7 top right parent 7
  {
    nameMap: "subParent2Container",
    locationMap: "33, 550, 96",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent2",
    locationMap: "10.7, 550, 96",
  },
  {
    nameMap: "Child2_SubParent2",
    locationMap: "10.7, 533.5, 86.2",
  },
  {
    nameMap: "Child3_SubParent2",
    locationMap: "10.7, 533.5, 105.3",
  },
  {
    nameMap: "Child4_SubParent2",
    locationMap: "10.7, 550, 115",
  },
  {
    nameMap: "Child5_SubParent2",
    locationMap: "10.7, 566.4, 105.3",
  },
  {
    nameMap: "Child6_SubParent2",
    locationMap: "10.7, 566.4, 86.2",
  },
  {
    nameMap: "Child7_SubParent2",
    locationMap: "10.7, 550, 76.8",
  },
];
