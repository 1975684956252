// This is the section for Team in desktop View
import React from "react";
function CardTeam({ handleModalClick, cardTeam }) {
  return (
    <div className=" col-12 col-lg-6 ">
      <div className="item d-flex align-items-center">
        <img
          id="id_imgMemberTeam"
          className="mx-auto"
          src={cardTeam.authorImage}
          alt={cardTeam.name}
        />
        <div className="text-of-slider">
          <h2 id="id_nameMemberTeam">{cardTeam.name}</h2>
          <span id="id_jobMemberTeam">{cardTeam.job}</span>

          <p id="id_discribtionMemberTeam">
            {cardTeam.firstcontent.slice(0, 85)} ...
          </p>

          <ul className="social-ul">
            <li>
              <a
                href={cardTeam.facebook}
                rel="noreferrer"
                target="_blank"
                className="linkedin-team"
                id="id_facebookMemberTeam"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a
                href={cardTeam.twitter}
                rel="noreferrer"
                target="_blank"
                className="linkedin-team"
                id="id_twitterMemberTeam"
              >
                <i className="fab fa-twitter"></i>
              </a>
            </li>
            <li>
              <a
                href={cardTeam.instagram}
                rel="noreferrer"
                target="_blank"
                className="linkedin-team"
                id="id_instagramMemberTeam"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CardTeam;
