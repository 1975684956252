import React from 'react';

function Notes() {
  return (
    <div className='notes-page'>
      <p>Notes</p>
      <ul>
        <li>You can claim the available amount anytime by clicking the “Claim” button.</li>
        <li>you won’t be able to receive your tokens without clicking the “Claim” button </li>
        <li>
          please remember that you must have BNB (BEP20) in your wallet to cover network fees each
          time you claim your TELV.
        </li>
      </ul>
    </div>
  );
}

export default Notes;
