import { useState } from "react";
import { calculateTimeLeft } from "../Helper/Helper";
import {
  getDeadlineDate,
  getStartingDate,
} from "../pages/presale/presaleContractFunctions";
import { Col } from "react-bootstrap";
/**
 * (Custom Hook)
 * this custom hook handles Timer
 * param args
 * returns {{dateDifference,
    timeToCheck,
    getStartingDateHandler,
    getDeadlineDateHandler,
    setTimeLeft,
    timerComponents,}}
 * author Khaled Mofeed
 */
export const useTimer = () => {
  const timeToCheck = 1000;
  const [date, setDate] = useState(0);
  const [isLandPage, setIsLandPage] = useState(false);
  let dateDifference = date - +new Date();

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(dateDifference));
  /**
   * this function is returned the Start date
   * author Khaled Mofeed
   */
  const getStartingDateHandler = async () => {
    const { _hex } = await getStartingDate();
    setDate(parseInt(_hex, 16) * 1000);
  };
  /**
   * this function is returned the Dead Line date
   * author Khaled Mofeed
   */
  const getDeadlineDateHandler = async () => {
    const { _hex } = await getDeadlineDate();
    setDate(parseInt(_hex, 16) * 1000);
  };
  /**
   * this function is returned the date or Time To Be Live
   * author Khaled Mofeed
   */
  const getTimeToBeLive = async () => {
    let year = new Date().getFullYear();
    setDate(+new Date(`12/31/${year}`));
    setIsLandPage(true);
  };
  /**
   * this returned the Date view
   * author Khaled Mofeed
   */
  const timerComponents = [];
  Object.keys(timeLeft).forEach((interval) => {
    timerComponents.push(
      <>
        {!isLandPage ? (
          <Col className="live-timers" xs={3} lg={3}>
            <div className="count">
              <h1>
                {timeLeft[interval] < 10
                  ? `0${timeLeft[interval]}`
                  : timeLeft[interval]}
              </h1>
              <p>{interval}</p>
            </div>
          </Col>
        ) : (
          <Col xs={4} md={4} key={interval}>
            <div className="count">
              <h1 id="id_h1_interval">
                {" "}
                {timeLeft[interval] < 10
                  ? `0${timeLeft[interval]}`
                  : timeLeft[interval]}
              </h1>
              <p id="id_interval">{interval}</p>
            </div>
          </Col>
        )}
      </>
    );
  });
  return {
    dateDifference,
    timeToCheck,
    getStartingDateHandler,
    getDeadlineDateHandler,
    getTimeToBeLive,
    setTimeLeft,
    timerComponents,
    setDate
  };
};
