import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { useEventModal } from "../Hooks/useEventModal";
import { useAuth } from "../../../scripts/App/auth";
export default function EventModal({
  data,
  setData,
  handleClose,
  updateEvent,
  ...props
}) {
  /**
   * use the useEventModal custom hook to handle the operations
   * author Mahmoud Skaik
   */

  const {
    isLoading,
    changeStatus
  } = useEventModal(data, setData, handleClose, updateEvent);

  const { user } = useAuth();

  return (
    <Modal
      {...props}
      show={data.status}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3 className="card-label mb-0" id="ID_name_modal">
            {data?.data.name + " Event Details"}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <ModalBody className="px-5">
        <div className="row align-items-center mt-3 mb-4">
          <div className="col-md-12 ">
            <div className="card-title">
              <img src={data?.data.cover} width="100%" />
            </div>
          </div>
          <div className="col-md-12 col-lg-12">
            <div className="row">
              <div className="col col-md-6 col-lg-6 mb-2">
                <span className="d-block text-muted pt-2 font-size-sm"> Event Name </span>
                <span className="d-block pt-2 font-size-sm">{data?.data.name}</span>
              </div>
              <div className="col col-md-6 col-lg-6 mb-2">
                <span className="d-block text-muted pt-2 font-size-sm"> Email </span>
                <span className="d-block pt-2 font-size-sm">{data?.data.email}</span>
              </div>
            </div>
            <div className="row">
              <div className="col col-md-12 col-lg-12 mb-2">
                <span className="d-block text-muted pt-2 font-size-sm"> Description </span>
                <span className="d-block pt-2 font-size-sm">{data?.data.description}</span>
              </div>

            </div>
            <div className="row">
              <div className="col col-md-6 col-lg-6 mb-2">
                <span className="d-block text-muted pt-2 font-size-sm"> Categories </span>
                {data?.data.categories && data?.data.categories.map((category, index) =>
                  <span key={index} className="d-block pt-2 font-size-sm">
                    * {category.name}
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col col-md-6 col-lg-6 mb-2">
                <span className="d-block text-muted pt-2 font-size-sm"> Start Date </span>
                <span className="d-block pt-2 font-size-sm">{data?.data.start_date}</span>
              </div>
              <div className="col col-md-6 col-lg-6 mb-2">
                <span className="d-block text-muted pt-2 font-size-sm"> Start Time </span>
                <span className="d-block pt-2 font-size-sm">{data?.data.start_time}</span>
              </div>
            </div>
            <div className="row">
              <div className="col col-md-6 col-lg-6 mb-2">
                <span className="d-block text-muted pt-2 font-size-sm"> End Date </span>
                <span className="d-block pt-2 font-size-sm">{data?.data.end_date}</span>
              </div>
              <div className="col col-md-6 col-lg-6 mb-2">
                <span className="d-block text-muted pt-2 font-size-sm"> End Time </span>
                <span className="d-block pt-2 font-size-sm">{data?.data.end_time}</span>
              </div>
            </div>
            <div className="row">
              <div className="col col-md-6 col-lg-6 mb-2">
                <span className="d-block text-muted pt-2 font-size-sm"> Location </span>
                <span className="d-block pt-2 font-size-sm">{data?.data.location}</span>
              </div>
            </div>
            <div className="row">
              <div className="col col-md-12 col-lg-12 mb-2">
                <span className="d-block text-muted pt-2 font-size-sm"> Additional Info </span>
                <span className="d-block pt-2 font-size-sm">{data?.data.additional_info}</span>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <Modal.Footer>
        <Button
          onClick={handleClose}
          className="btn btn-light-primary"
          disabled={isLoading}
          id="ID_Close"
        >
          Close
        </Button>
        {/* {user?.permissions?.includes(
                              "delete_event"
                            ) && ( */}
                              {/* )} */}

        {user?.permissions?.includes("edit_event") && data?.data.schedule_status !== 'Ended' &&
          (<Button
            className="btn btn-danger"
            type="button"
            disabled={isLoading || data?.data.status === "Rejected"}
            id="ID_reject"
            onClick={() => changeStatus(data?.data.uuid, "Rejected")}
          >
            {data?.data.status === "Rejected" ? "Rejected" : "Reject"}

            {isLoading && (
              <Spinner
                style={{ marginLeft: ".5rem" }}
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                id="ID_Spinner1"
              />
            )}
          </Button>)}
        {user?.permissions?.includes("edit_event") && data?.data.schedule_status !== 'Ended' &&
          (<Button
            className="btn btn-success"
            type="button"
            disabled={isLoading || data?.data.status === "Approved"}
            id="ID_approve"
            onClick={() => changeStatus(data?.data.uuid, "Approved")}
          >
            {data?.data.status === "Approved" ? "Approved" : "Approve"}
            {isLoading && (
              <Spinner
                style={{ marginLeft: ".5rem" }}
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                id="ID_Spinner2"
              />
            )}
          </Button>)}


      </Modal.Footer>
    </Modal>
  );
}
