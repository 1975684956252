import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";

/**
 * Multiple Select Menue Component
 * author Khaled Mofeed
 */
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, values, theme) {
  return {
    fontWeight: values.map(({ label }) => label).includes(name)
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect({
  value,
  options,
  label,
  errorMessage,
  sx,
  renderValue,
  handleChange,
}) {
  const theme = useTheme();

  return (
    <>
      <FormControl
        variant="standard"
        sx={sx}
        error={Boolean(errorMessage)}
        className="formControlSelect"
      >
        <InputLabel id={`${label}-multiple-name-label`} className="labelinput">
          {label}
        </InputLabel>
        <Select
          displayEmpty
          labelId={`${label}-multiple-name-label`}
          id={`multiple-select-${label}`}
          className="basic-multi-select"
          value={value}
          multiple={true}
          onChange={handleChange}
          MenuProps={MenuProps}
          renderValue={renderValue}
        >
          {options.map((name) => (
            <MenuItem
              key={name.label}
              value={name.value}
              style={getStyles(name.label, options, theme)}
            >
              {name.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
