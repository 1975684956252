import { useState } from "react";
import { useAuth } from "../../../../scripts/App/auth";
import { Link } from "react-router-dom";
import ModalTwofa from "../ModalTwofa";
/**
 * Security  Component to fix settings of Google Authentication
 * author Khaled Mofeed
 */
function Security(props) {
  const [modalShow, setModalShow] = useState(false);
  const { user } = useAuth();
  return (
    <>
      <div className="separator separator-dashed my-10"></div>
      <div className="row">
        <label className="col-xl-3"></label>
        <div className="col-lg-9 col-xl-6">
          <h6 className="font-weight-bold mb-6" id="id_Security_lable">
            Security
          </h6>
        </div>
      </div>
      <div className="row">
        <label
          className="col-xl-3 col-lg-3 col-form-label"
          id="id_Authentication__lable"
        >
          Google Authentication.
        </label>
        <div className="col-lg-9 col-xl-6">
          <span
            id="id_Authentication__Setup"
            onClick={() => setModalShow(true)}
            className="btn btn-light-primary font-weight-bold btn-sm"
          >
            Setup Google Authentication -{user.two_fa ? " ON" : " OFF"}
          </span>

          <p className="form-text text-muted pt-2" id="id_additional_info">
            After you log in, you will be asked for additional information to
            confirm<br></br> your identity and protect your account from being
            compromised. <br></br>
            {user.two_fa ? (
              <Link
                id="id_Activated_info"
                onClick={() => setModalShow(true)}
                className="add-edit-btn FA-enable-disable font-weight-bold text-success"
              >
                Activated
              </Link>
            ) : (
              <Link
                id="id_Deactivated_info"
                onClick={() => setModalShow(true)}
                className="add-edit-btn FA-enable-disable font-weight-bold text-danger"
              >
                Deactivated
              </Link>
            )}
          </p>
        </div>
      </div>
      <ModalTwofa show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default Security;
