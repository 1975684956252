import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { Table } from "react-bootstrap";
/**
 * Layout of Table Head component
 * author Khaled Mofeed
 */
function EnhancedTableHead(props) {
  const { headCells, order, orderBy, minTableHeadWidth, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <thead>
        <tr>
          {headCells.map((headCell) => (
            <th
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortdirection={orderBy === headCell.id ? order : "asc"}
              sx={{ minWidth: minTableHeadWidth }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </th>
          ))}
        </tr>
      </thead>
    </>
  );
}

export default function EnhancedTable(props) {
  const {
    children,
    TableRows,
    page,
    tableItems,
    tableHead,
    rowsPerPageOptions,
    pagesCount,
    rowsPerPage,
    minTableHeadWidth,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSortHeader,
  } = props;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    handleRequestSortHeader(order, orderBy);
  }, [order, orderBy]);

  return (
    <>
      {children}
      <form className="form">
        <div className="card-body">
          <Table responsive className="table-admins" id="ID_table-admins">
            <EnhancedTableHead
              headCells={tableHead}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tableItems.length}
              minTableHeadWidth={minTableHeadWidth}
            />
            <tbody id="ID_tabletbody">{TableRows}</tbody>
          </Table>
          <TablePagination
            showFirstButton
            showLastButton
            component="div"
            rowsPerPageOptions={rowsPerPageOptions}
            count={pagesCount}
            id="ID_TablePagination"
            rowsPerPage={Number(rowsPerPage)}
            page={page === 0 ? page : page - 1}
            onPageChange={(event, newPage) =>
              handleChangePage(event, newPage + 1)
            }
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}/${to} of ${count}`
            }
            className="TablePagination-style"
          />
        </div>
      </form>
    </>
  );
}
