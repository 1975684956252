// eslint-disable-next-line import/no-anonymous-default-export
export default {
  smartContract: {
    TOKEN_ADDRESS:
      process.env.REACT_APP_TOKEN_ADDRESS || '0x18502323aE2203BAAb8EcA21eBEf62583878ec7e',
    CONTRACT_ADDRESS:
      process.env.REACT_APP_CONTRACT_ADDRESS || '0x29693eBF21dEf46CB93dfbcd1E774c16ff019FD4',
    API_URL: process.env.REACT_APP_API_URL || 'https://api-dev.televerse.me/api/v1/',
    CHAIN_NAME: process.env.REACT_APP_CHAIN_NAME || 'Smart Chain - Testnet',
    CHAIN_ID: process.env.REACT_APP_CHAIN_ID || 97,
    CHAIN_RPC_URL:
      process.env.REACT_APP_CHAIN_RPC_URL || 'https://endpoints.omniatech.io/v1/bsc/testnet/public',
    CHAIN_CURRENCY_SYMBOL: process.env.REACT_APP_CHAIN_CURRENCY_SYMBOL || 'bnbt',
    CHAIN_BLOCK_EXPLORER_URL:
      process.env.REACT_APP_CHAIN_BLOCK_EXPLORER_URL || 'https://testnet.bscscan.com',
    VESTING_ADDRESS:
      process.env.REACT_APP_VESTING_ADDRESS || '0x803AD40351ecC953d3AC9748681687db259D5490',
    NFT_CONTRACT_PROPOSAL_ADDRESS:
      process.env.REACT_APP_NFT_CONTRACT_PROPOSAL_ADDRESS ||
      '0xf5e68bbFb0fcc9C481E168F06a0774eb7d3aFd78',
    NFT_CONTRACT_ADDRESS:
      process.env.REACT_APP_NFT_CONTRACT_ADDRESS || '0xB693832a0D66251bEcBC4A80B103e0ae52fFe856',
    PROPOSAL_ADDRESS:
      process.env.REACT_APP_PROPOSAL_ADDRESS || '0xaA8c1068E12FB4607954123b6d88A49c931F965D',
    REVENUE_ADDRESS:
      process.env.REACT_APP_REVENUE_ADDRESS || '0x4ce863aa20b5f076ec25dd8bc59ec429fb3c1cc3',
    MARKETPLACE_ADDRESS:
      process.env.REACT_APP_MARKETPLACE_ADDRESS || '0x123aB5bAB9334BeB1D3D8956E1B3dD3c533aaB14',
    NFT_MARKETPLACE_ADDRESS:
      process.env.REACT_APP_NFT_MARKETPLACE_ADDRESS || '0xf2187cED4721c9AeEAc50F98aa50B080f5D699A2',
  },
};
